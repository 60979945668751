const gr = {
    "1": "Ναι",
    "2": "Όχι",
    "3": "Εκδοχή δοκιμής 30 ημερών",
    "4": "Ένα αίτημα αναφέρθηκε μέσω της εφαρμογής",
    "5": "Αποδοχή",
    "6": "Ημερομηνία αποδοχής",
    "7": "Ενέργεια",
    "8": "Ενεργό",
    "9": "Δραστηριότητες",
    "10": "Προσθήκη",
    "11": "Προσθήκη εγκαταστάτη",
    "12": "Προσθήκη εταιρείας",
    "13": "Προσθήκη αντιπροσώπου/τοποθετητή",
    "14": "Προσθήκη κατασκευαστή",
    "15": "Προσθήκη διαχείρισης ακινήτων",
    "16": "Προσθήκη χρήστη",
    "17": "Πρόσθετο πεδίο",
    "18": "Επεξεργασία ονόματος πρόσθετου πεδίου",
    "19": "Σχόλια",
    "20": "Πρόσθετες πληροφορίες",
    "21": "Πρόσθετα προϊόντα",
    "22": "Αντικατάσταση υλικού",
    "23": "Διαχειριστής",
    "24": "Προβολή διαχειριστή",
    "25": "Έχουν παραδοθεί όλα τα στοιχεία στην τοποθεσία;",
    "26": "Όλες οι θέσεις έχουν εγκατασταθεί",
    "27": "Όλα τα δικαιώματα διατηρούνται.",
    "28": "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτόν τον κατασκευαστή;",
    "29": "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτόν τον χρήστη;",
    "30": "Εγκατεστημένο",
    "31": "Εγκαταστάτης",
    "32": "Εγκαταστάτες",
    "33": "Συναρμολόγηση",
    "34": "Η εγκατάσταση ολοκληρώθηκε",
    "35": "Ημερομηνία εγκατάστασης",
    "36": "Αναθέσεις",
    "37": "Οδηγίες φροντίδας",
    "38": "Λεπτομέρειες πιστοποιητικού",
    "39": "Πιστοποιητικό(α)",
    "40": "Αντικαταστήστε το φύλλο ",
    "41": "Καθαρίστε το",
    "42": "Κλείστε",
    "43": "Χρώμα",
    "44": "Χρώμα/φινίρισμα",
    "45": "Προμήθεια ",
    "46": "Εταιρεία",
    "47": "Στοιχεία εταιρείας",
    "48": "Πληροφορίες για την εταιρεία",
    "49": "Κατεύθυνση πυξίδας",
    "50": "Αιτήματα παραπόνων",
    "51": "Ολοκλήρωση έργου",
    "52": "Ολοκληρώθηκε",
    "53": "Συνδεδεμένο παράθυρο",
    "54": "Καθαρό εργοτάξιο",
    "55": "Ημερομηνία δημιουργίας",
    "56": "Τελικός χρήστης",
    "57": "Αριθμός πελάτη",
    "58": "Αναφορά στοιχείου",
    "59": "Ημερομηνία",
    "60": "Εύρος ημερομηνίας",
    "61": "Έμπορος/ τοποθετητής",
    "62": "Διαγραφή εταιρείας",
    "63": "Διαγραφή Κατασκευαστή",
    "64": "Διαγραφή χρήστη",
    "65": "Ημερομηνία παράδοσης",
    "66": "Παραδόθηκε χωρίς ζημία",
    "67": "Περιγραφή",
    "68": "Προβλεπόμενος τόπος εγκατάστασης",
    "69": "Εμφάνιση εγγραφών _MENU_ ανά σελίδα",
    "70": "Διανομή",
    "71": "Έγγραφα",
    "72": "Ανατολικά",
    "73": "Επεξεργασία εγκαταστάτη",
    "74": "Επεξεργασία εταιρείας",
    "75": "Επεξεργασία κατασκευαστή",
    "76": "Επεξεργασία διαχείρισης ακινήτων",
    "77": "Επεξεργασία χρήστη",
    "78": "Πλάτος x ύψος στοιχείου",
    "79": "Στοιχείο με αυτό το id δεν υπάρχει",
    "80": "Στοιχεία",
    "81": "Αποθήκευση δεδομένων ηλεκτρονικού ταχυδρομείου.",
    "82": "Εισάγετε χρώμα",
    "83": "Εισάγετε προμήθεια",
    "84": "Εισάγετε την επωνυμία της εταιρείας",
    "85": "Εισάγετε email",
    "86": "Εισαγωγή επέκτασης",
    "87": "Εισάγετε ύψος",
    "88": "Εισάγετε διεύθυνση IP",
    "89": "Εισάγετε το όνομα",
    "90": "Εισάγετε αριθμό",
    "91": "Εισάγετε αριθμό θέσεων",
    "92": "Εισάγετε κωδικό πρόσβασης",
    "93": "Εισαγωγή τηλεφώνου",
    "94": "Εισάγετε τον αριθμό τηλεφώνου",
    "95": "Εισάγετε την πόλη",
    "96": "Εισάγετε την ποσότητα",
    "97": "Εισάγετε την οδό",
    "98": "Εισάγετε το επώνυμο",
    "99": "Εισάγετε όνομα χρήστη",
    "100": "Εισάγετε το ID λογαριασμού",
    "101": "Πληκτρολογήστε τον ιστότοπο",
    "102": "Εισάγετε πλάτος",
    "103": "Σφάλμα κατά την αποθήκευση δεδομένων στο διακομιστή.",
    "104": "Σφάλμα κατά την αποθήκευση δεδομένων ηλεκτρονικού ταχυδρομείου.",
    "105": "Αξεσουάρ επισκευής",
    "106": "Έληξε στις",
    "107": "λήγει στις",
    "108": "Επέκταση",
    "109": "Κατασκευαστής",
    "110": "Εργοστάσιο",
    "111": "Φίλτρο",
    "112": "φιλτραρισμένο από _MAX_ συνολικές εγγραφές",
    "113": "Πρώτο",
    "114": "Ακύρωση",
    "115": "Πληροφορίες υλικού",
    "116": "Οθόνες fly",
    "117": "Αποτύπωμα",
    "118": "Σχόλια",
    "119": "Από την εφαρμογή",
    "120": "Από την προβολή διαχείρισης",
    "121": "Καθαριστικό γυαλιού",
    "122": "Αντικατάσταση γυαλιού",
    "123": "Δομή γυαλιού",
    "124": "Πλάτος γυαλιού x ύψος",
    "125": "Συντήρηση υλικού",
    "126": "Αντικατάσταση υλικού",
    "127": "Ύψος",
    "128": "Πώς να καθαρίσετε",
    "129": "Εικόνα",
    "130": "Πληροφορίες για το στοιχείο",
    "131": "Οι πληροφορίες είναι περιορισμένες",
    "132": "Είσοδος",
    "134": "Θέση εγκατάστασης",
    "135": "εισαγωγή γραμμωτού κώδικα (barcode)",
    "136": "Διεύθυνση IP",
    "137": "προαπαιτούμενο",
    "138": "Γλώσσα",
    "139": "Τελευταία",
    "140": "Τελευταία συντήρηση",
    "141": "Τελευταία αίτημα παραπόνων",
    "142": "Τελευταίο σέρβις",
    "143": "Σύνδεση με το άρθρο",
    "144": "Φόρτωση",
    "145": "Όλα τα στοιχεία σύμφωνα με τις προδιαγραφές;",
    "146": "Αποσύνδεση",
    "147": "Email - Πρόσβαση σε δεδομένα",
    "148": "Συντήρηση",
    "149": "Αλλαγή από",
    "150": "Κατασκευάστηκε",
    "151": "Σχεδιασμός κύριου ελεγκτή",
    "152": "Αριθμός υλικού κύριου ελεγκτή",
    "153": "Κύριος αύξων αριθμός ελεγκτή",
    "154": "Αντιστοίχιση Ημερομηνία",
    "155": "Ορατότητα μενού",
    "156": "Αυτόματη επιβεβαίωση γραμμωτού κώδικα (barcode)",
    "157": "Περισσότερες πληροφορίες για την αειφορία της REHAU",
    "158": "Νέα περίοδος δοκιμής",
    "159": "Επόμενο",
    "160": "Καμία ζημιά στο στοιχείο",
    "161": "Καμία ζημιά στο γυαλί",
    "162": "Δεν βρέθηκαν αντίστοιχες εγγραφές",
    "163": "Κανένα από τα στοιχεία δεν έχει εγκατασταθεί",
    "164": "Βόρεια",
    "165": "Δεν έχει εγκατασταθεί",
    "166": "Δεν βρέθηκε τίποτα - λυπάμαι",
    "167": "Αριθμός",
    "168": "Αριθμός στοιχείων",
    "169": "Επισκόπηση του έργου",
    "170": "Αριθμός θέσεων",
    "171": "στο",
    "172": "Ημερομηνία παραγγελίας",
    "173": "Αριθμός έργου",
    "174": "Πρόοδος του έργου",
    "175": "Έξοδος",
    "176": "Κωδικός πρόσβασης",
    "177": "Τηλέφωνο",
    "178": "Πόλη",
    "179": "Καθαριστής PVC",
    "180": "Στοιχείο Doc.",
    "181": "Λεπτομέρειες για τη θέση του στοιχείου",
    "182": "Θέση με αυτό το id δεν υπάρχει",
    "183": "Θέσεις",
    "184": "Προηγούμενο",
    "185": "Κύρια διαπιστευτήρια χρήστη",
    "186": "Εκτύπωση δεδομένων σύνδεσης",
    "187": "Επεξεργασία",
    "188": "Κατασκευαστής",
    "189": "Σύστημα προφίλ",
    "190": "Έργο",
    "191": "Αριθμός έργου",
    "192": "Διαχείριση ακινήτων",
    "193": "Ποσότητα",
    "194": "Αίτηση παραπόνων",
    "195": "Παρακαλώ επιλέξτε σταθμό",
    "196": "Ανακυκλωμένο περιεχόμενο",
    "197": "Επισκευή",
    "198": "Αξεσουάρ προς επισκευή",
    "199": "Επιφάνεια προς επισκευή",
    "200": "Αναφορά από την εφαρμογή",
    "201": "Έχει γίνει αναφορά από την πλευρά της διοίκησης",
    "202": "Επαναφορά περιόδου δοκιμής",
    "203": "Αποτέλεσμα",
    "204": "Ρόλος",
    "205": "Αποθήκευση",
    "206": "Αποθηκεύτηκε με επιτυχία.",
    "207": "Καθαρίστε την επιφάνεια",
    "208": "Αναζήτηση",
    "209": "Επίπεδο ασφαλείας",
    "210": "Αποστολή δεδομένων στη REHAU",
    "211": "Υπηρεσία",
    "212": "Εξυπηρέτηση & πωλήσεις",
    "213": "Πάροχος υπηρεσιών",
    "214": "Ρυθμίσεις",
    "215": "Θα πρέπει να γίνει μια χειροκίνητη συνολική παραλαβή ή συντήρηση",
    "216": "Εμφάνιση της _ΑΡΧΗΣ_ έως το _ΤΕΛΟΣ_ των _ΣΥΝΟΛΙΚΩΝ_ εγγραφών",
    "217": "Εμφάνιση 0 έως 0 από 0 εγγραφές",
    "218": "Παντζούρια",
    "219": "Είσοδος",
    "220": "Συνδεθείτε για να ξεκινήσετε τη συνεδρία σας",
    "221": "Πληροφορίες για το Smart Glass",
    "222": "Έξυπνο παράθυρο",
    "223": "Ορισμένα στοιχεία είναι εγκατεστημένα",
    "224": "Κάποια στοιχεία δεν έχουν αντιστοιχιστεί",
    "225": "Κάποια θέση (ες) χρειάζεται να εγκατασταθεί (ες)",
    "226": "Νότια",
    "227": "Στατιστικά στοιχεία",
    "228": "Οδός",
    "229": "Επώνυμο",
    "230": "Αειφορία & Ενέργεια",
    "231": "Τηλέφωνο",
    "232": "Η περίοδος δοκιμής επαναφέρθηκε επιτυχώς",
    "233": "περίοδος(ες) δοκιμής",
    "234": "Ολοκληρώθηκε η επισκευή",
    "235": "Υπάρχουν υφιστάμενα αιτήματα παραπόνων",
    "236": "Δεν υπάρχει εταιρεία με αυτό το αναγνωριστικό",
    "237": "Δεν υπάρχουν πληροφορίες για αυτό το στοιχείο",
    "238": "Αυτή η εταιρεία χρησιμοποιείται ήδη",
    "239": "Τίτλος",
    "240": "στο",
    "241": "Συνολική αποδοχή",
    "242": "Σύνολο",
    "243": "Άγνωστος",
    "244": "Ανέβασμα λογότυπου",
    "245": "Διαχείριση χρηστών",
    "246": "Όνομα χρήστη",
    "247": "Το όνομα χρήστη είναι ήδη κατειλημμένο.",
    "248": "Χρήστες",
    "249": "Τιμή U",
    "250": "Τιμή U (Πλαίσιο)",
    "251": "Τιμή U (γυαλί)",
    "252": "W.ID που έχει εκχωρηθεί",
    "253": "Προειδοποίηση για να μην προκληθεί ζημιά ID παραθύρου",
    "254": "Καλώς ήρθατε",
    "255": "Δύση",
    "256": "Πλάτος",
    "257": "Συνολικό μέγεθος στοιχείου (mm)",
    "258": "Εμβαδόν παραθύρου από",
    "259": "Εμβαδόν παραθύρου σε",
    "260": "Αριθμός ηλεκτρονικού υλικού παραθύρου",
    "261": "Ηλεκτρονικός αύξων αριθμός Παραθύρου",
    "262": "Θέση εγκατάστασης παραθύρου",
    "263": "Σύστημα παραθύρου",
    "264": "Τύπος παραθύρου",
    "265": "Αντικατάσταση φύλλου παραθύρου ",
    "266": "Είστε σίγουρος;",
    "267": "Καθαρίστε το φίλτρο",
    "268": "Επεξεργασία",
    "269": "Εξαγωγή CSV",
    "270": "Αναγνώστης γραμμωτού κώδικα (Barcode)",
    "271": "Αναγνώριση γυαλιού",
    "272": "Πληροφορίες",
    "273": "Σύνδεσμος",
    "274": "Συνδεδεμένοι χρήστες",
    "275": "Email",
    "276": "Ρυθμίσεις ηλεκτρονικού ταχυδρομείου",
    "277": "Email - Περίοδος δοκιμής",
    "278": "Τίτλος υπηρεσίας",
    "279": "Εταιρεία υπηρεσίας",
    "280": "Τηλέφωνο υπηρεσίας",
    "281": "email υπηρεσίας",
    "282": "Κατάσταση",
    "283": "Ρυθμίσεις σταθμού",
    "284": "Θέμα",
    "285": "Τηλ.",
    "286": "Χρήστης",
    "287": "Ιστοσελίδα",
    "288": "Αναγνωριστικό παραθύρου",
    "289": "Σύστημα παραθύρου",
    "290": "Στοιχεία τελικού χρήστη",
    "291": "Όνομα",
    "292": "Δομή γυαλιού",
    "293": "Λεπτομέρειες υλικού",
    "294": "Τίτλος εγκαταστάτη",
    "295": "Εταιρεία εγκαταστάτη",
    "296": "Τηλέφωνο εγκαταστάτη",
    "297": "email εγκαταστάτη",
    "298": "URL",
    "299": "Χώρα",
    "300": "Παρακαλώ προσθέστε χρήστη για αυτή την εταιρεία",
    "301": "Πρόσθετο μενού",
    "302": "Ταμπλό",
    "303": "Εφαρμογή",
    "304": "Σχεδιασμός σελίδας προορισμού",
    "305": "Διαχείριση χρηστών",
    "306": "Επιβεβαίωση",
    "307": "Σώμα",
    "308": "Συνημμένο",
    "309": "Id",
    "310": "Θέση",
    "311": "Προειδοποίηση",
    "312": "W.ID",
    "314": "Έμποροι",
    "315": "Ενεργοποίηση",
    "316": "Απενεργοποίηση",
    "317": "εταιρεία",
    "318": "Συγγνώμη",
    "319": "Διαχειριστής",
    "320": "χρήστης",
    "321": "Ταχυδρομικός κώδικας",
    "322": "Λογότυπο",
    "323": "Ταπετσαρία",
    "324": "Αναγνωριστικό λογαριασμού",
    "325": "Έγγραφα CE",
    "326": "Ρυθμίσεις εφαρμογής",
    "327": "Αυτόματο",
    "328": "Χειροκίνητη",
    "329": "Αναζήτηση",
    "330": "Ανέβασμα",
    "331": "Αυτόματη μεταφόρτωση εγγράφων",
    "332": "Φάκελος πηγής",
    "333": "Παρακαλούμε επιλέξτε τη διαδρομή φακέλου για την αυτόματη μεταφόρτωση εγγράφων.",
    "334": "Παρακαλούμε επιλέξτε αρχείο .zip που περιέχει το αρχείο PPS XML",
    "335": "Σταθμός",
    "336": "Χειροκίνητη σάρωση",
    "337": "Σταθμός Smart Glass",
    "338": "Μεταφορά πληροφοριών",
    "339": "Μέγιστος αριθμός ταινιών στο χρονοδιάγραμμα",
    "340": "Κώδικας QR από",
    "341": "Κώδικας QR προς",
    "342": "Πρόθεμα κώδικα QR",
    "343": "Μήκος κώδικα QR",
    "344": "Τα δεδομένα του γραμμωτού κώδικα είναι άκυρα.",
    "345": "Κλειδί Api",
    "346": "πληκτρολογήστε qrcode",
    "347": "Όνομα σταθμού",
    "348": "Δεν έχει επιλεγεί αρχείο",
    "349": "Ανεβασμένο αρχείο",
    "350": "Σφάλμα μεταφόρτωσης αρχείου",
    "351": "Η χειροκίνητη σάρωση είναι ενεργοποιημένη",
    "352": "Παρακαλώ εισάγετε τον κωδικό qrcode",
    "353": "Ο γραμμωτός κώδικας είναι άκυρος",
    "354": "Αυτός ο γραμμωτός κώδικας έχει εισαχθεί",
    "356": "Δεν υπάρχουν δεδομένα",
    "357": "Η σύνδεση απέτυχε",
    "358": "Ιστορικό",
    "359": "Ο γραμμωτός κώδικας είναι σε χρήση.",
    "360": "Σφάλμα κατά την αντιστοίχιση γραμμωτού κώδικα",
    "361": "Χειροκίνητο",
    "362": "Από",
    "363": "Μέχρι",
    "364": "Το μήκος του προθέματος QR-Code πρέπει να είναι μικρότερο από το μήκος του QR-Code",
    "365": "Το εύρος του γραμμωτού κώδικα είναι άκυρο",
    "366": "Μη έγκυροι αριθμοί",
    "367": "Χρησιμοποιήθηκε προηγουμένως",
    "368": "Χρησιμοποιείται σήμερα",
    "369": "Δωρεάν",
    "370": "Χρησιμοποιείται μέχρι σήμερα",
    "371": "Χρήση αριθμού QR-Code για την τρέχουσα σειρά σταθμών",
    "372": "Μεταφράσεις",
    "373": "Επεξεργασία μετάφρασης",
    "374": "Κλειδί",
    "375": "Αγγλικά",
    "376": "Τιμή",
    "377": "ID για εκχώρηση",
    "378": "Θέσεις των παραθύρων και των θυρών στο έργο",
    "379": "Υπηρεσία επισκευής / απόδειξη επισκευής",
    "380": "Συντήρηση & ιστορικό",
    "381": "Έκθεση εγκατάστασης",
    "382": "Τροποποίηση",
    "383": "Αντικατάσταση γυαλιού",
    "384": "Αντικατάσταση φύλλου",
    "386": "Σχόλια",
    "387": "Ολοκληρωμένη επισκευή",
    "388": "Νέα χαρακτηριστικά",
    "389": "Ημερολόγιο συντήρησης",
    "390": "Συντήρηση υλικού",
    "391": "Κανονίστε ένα ραντεβού συντήρησης",
    "392": "Πλήρης κατάλογος έργων",
    "393": "Πληροφορίες παραθύρων",
    "394": "Λεπτομέρειες εγκατάστασης",
    "395": "Χρώμα",
    "396": "Πλάτος x ύψος στοιχείου σε mm",
    "397": "Πλάτος x ύψος γυαλιού σε mm",
    "398": "Πληροφορίες τοποθέτησης",
    "399": "Όνομα εγκαταστάτη",
    "400": "Ημερομηνία/ώρα εγκατάστασης",
    "401": "Τοποθεσία εγκατάστασης (GPS)",
    "402": "Αναγνωριστικό συσκευής JustSmart",
    "403": "αποστολή",
    "404": "Θέση φίλτρου",
    "405": "Κανένα",
    "406": "Πόρτες μπαλκονιού",
    "407": "Κουζίνα",
    "408": "Μπάνιο",
    "409": "Δωμάτιο",
    "410": "Στέγη",
    "411": "Εγκατάσταση",
    "412": "Θέση",
    "413": "Επισυνάψτε εικόνα",
    "414": "Υπογραφή",
    "415": "Σχέδιο",
    "416": "Δεν έχει υποστεί ζημιά το παράθυρο",
    "417": "Δεν έχει υποστεί ζημιά το γυαλί",
    "418": "Ανοίξτε την κάμερα",
    "419": "Ανεβάστε το",
    "420": "Εγκατάσταση & ανάθεση παραθύρου",
    "421": "Έκθεση αποδοχής",
    "422": "Αποστολή",
    "423": "Ολόκληρο το κατασκευαστικό έργο",
    "424": "Τύπος τοποθέτησης",
    "425": "Αποκλειστικά για το στοιχείο",
    "426": "Ολοκληρώθηκε ολόκληρο το έργο",
    "427": "Έκθεση εγκατάστασης για μεμονωμένα στοιχεία",
    "428": "Υπάρχει πρόβλημα",
    "429": "Ζητήστε βοήθεια",
    "430": "Μήνυμα",
    "431": "Αποστολή",
    "432": "Επόμενο στοιχείο",
    "433": "Πλήρης κατάλογος στοιχείων",
    "434": "Χρονοδιάγραμμα κατασκευής",
    "435": "μέσω του αριθμού παραγγελίας",
    "436": "μέσω σαρωτή κωδικού QR",
    "437": "Εμφάνιση μεμονωμένου",
    "438": "ή",
    "439": "αριθμό barcode",
    "440": "Εμφάνιση πληροφοριών",
    "441": "Επισκευή/παράπονο",
    "442": "Εμφάνιση προσώπου επικοινωνίας",
    "443": "Ανατροφοδότηση",
    "444": "Σάρωση άλλου κωδικού QR",
    "445": "Επόμενη οφειλόμενη συντήρηση",
    "446": "Προμηθευτής παραθύρων",
    "447": "Ιδιότητες",
    "448": "Τιμή ηχομόνωσης",
    "449": "Uf",
    "450": "Λύσεις μετασκευής",
    "451": "Επικοινωνία",
    "452": "Η ταυτότητα του παραθύρου σας",
    "453": "Τι θέλετε να κάνετε;",
    "454": "Η τακτική συντήρηση των παραθύρων/πορτών παρατείνει τη διάρκεια ζωής και την άνεση",
    "455": "Ζητήστε ραντεβού συντήρησης μέσω της φόρμας επικοινωνίας",
    "456": "Ζητήστε ραντεβού συντήρησης μέσω τηλεφώνου",
    "457": "στον τεχνίτη των παραθύρων τους",
    "458": "Επιβεβαιώνω τους όρους και τις προϋποθέσεις και τον κανονισμό GDPR",
    "459": "Τι σας ανησυχεί",
    "461": "Επικοινωνία μέσω ...",
    "462": "Σπασμένο γυαλί",
    "463": "Το παράθυρο δεν κλείνει σωστά",
    "464": "Το παράθυρο έχει κολλήσει",
    "465": "Το παράθυρο έχει υποστεί ζημιά",
    "466": "Άλλα αξεσουάρ",
    "467": "Τα παράθυρα και οι πόρτες σας ήταν ",
    "468": "συναρμολογήθηκαν από αυτή την εταιρεία",
    "469": "Κατασκευαστής παραθύρων",
    "470": "Βαθμολογήστε τα παράθυρά σας και την τοποθέτηση τώρα",
    "471": "Πόσο ικανοποιημένοι είστε με τις υπηρεσίες της εταιρείας",
    "472": "Είναι τα παραδοθέντα προϊόντα εντάξει;",
    "473": "Πώς σας φαίνονται τα παράθυρά σας ;",
    "474": "Υπήρξαν προβλήματα με την επεξεργασία ;",
    "475": "Με τι μείνατε ευχαριστημένοι ;",
    "476": "Τι θα μπορούσατε να βελτιώσετε ;",
    "477": "Οδηγίες συναρμολόγησης",
    "478": "Θέσεις των παραθύρων και των θυρών",
    "479": "Ανοίξτε την έκθεση εγκατάστασης",
    "480": "Πλήρης κατάλογος στοιχείων του έργου",
    "481": "Κατάλογος στοιχείων μέσω αριθμού παραγγελίας / QR",
    "482": "Αποστολή ημερολογίου έργου",
    "483": "Λεπτομέρειες παραθύρων",
    "484": "Λεπτομέρειες",
    "485": "Αριθμός κατασκευαστικού έργου",
    "486": "Παράθυρο",
    "487": "Θέση παραθύρου",
    "488": "Υπηρεσία επισκευής",
    "489": "Λεπτομέρειες συντήρησης",
    "490": "Πελάτης",
    "491": "Φόρμα επικοινωνίας",
    "492": "Βρείτε έναν τεχνίτη της REHAU κοντά σας τώρα",
    "493": "Κατηγορία",
    "494": "Σάρωση QR",
    "495": "Επικοινωνήστε με το άτομο",
    "496": "ενημέρωση",
    "497": "φίλτρα",
    "498": "Η εγκατάσταση ολοκληρώθηκε",
    "499": "Ολοκληρώθηκε η εγκατάσταση ;",
    "500": "Παραδόθηκε χωρίς ζημιές",
    "501": "Αποθήκευση ως PDF",
    "502": "Τραβήξτε φωτογραφία",
    "503": "Δεν είναι έγκυρη η διεύθυνση ηλεκτρονικού ταχυδρομείου",
    "504": "Εγκαταστήστε την εφαρμογή",
    "505": "Τοποθετήστε το",
    "506": "Λίστα έργων",
    "507": "Φόρτωση επιλεγμένης παραγγελίας ?",
    "508": "Λανθασμένος αριθμός παραγγελίας/barcode",
    "509": "Μονό παράθυρο",
    "510": "Εισαγωγή αλλαγών",
    "511": "Αποστολή αναφοράς μέσω ηλεκτρονικού ταχυδρομείου",
    "512": "Επιλέξτε 4 επιλογές",
    "513": "Τώρα σαρώστε τον κωδικό QR για να μάθετε πού πρέπει να εγκατασταθεί το στοιχείο.",
    "514": "Αποθήκευση αρχείου",
    "515": "Εμφάνιση",
    "516": "αποτελέσματα",
    "517": "σελίδα",
    "518": "του",
    "519": "Ημερομηνία από",
    "520": "Ημερομηνία έως",
    "521": "άνοιγμα",
    "522": "Μέγεθος",
    "523": "Εγκατεστημένο σε",
    "524": "Επιτυχία",
    "525": "Πληροφορίες",
    "526": "Νέα παραγγελία φορτώθηκε",
    "527": "ID εγκαταστάτη",
    "528": "Σύνδεση",
    "529": "Επιβεβαιώνω τους γενικούς όρους και τις προϋποθέσεις και τον κανονισμό GDPR",
    "530": "Αποσύνδεση",
    "531": "σαρωτής",
    "532": "Αποστολή της αναφοράς",
    "533": "Πρέπει να αποσταλεί η έκθεση αποδοχής για όλα τα στοιχεία αυτής της παραγγελίας στο ταμπλό;",
    "534": "Ημερολόγιο επισκευής",
    "535": "επιλογή 5",
    "536": "επιλογή 6",
    "537": "επιλογή 7",
    "538": "επιλογή 8",
    "539": "επιλογή 9",
    "540": "επιλογή 10",
    "541": "Αποστολή ηλεκτρονικού ταχυδρομείου.",
    "542": "Σφάλμα κατά την αποστολή email!",
    "543": "Συντήρηση υλικού",
    "544": "Ρυθμισμένο",
    "545": "Καθαρισμένο",
    "546": "Σφραγισμένο",
    "547": "Αντικατάσταση υλικού",
    "548": "Σχόλιο",
    "549": "Όλα τα στοιχεία παραδίδονται στο εργοτάξιο",
    "550": "Τοποθεσία των στοιχείων εντός του έργου κατασκευής",
    "551": "Σφάλμα κατά την αποστολή της αναφοράς!",
    "552": "Αποθήκευση αναφοράς",
    "553": "Αποθηκευμένες πληροφορίες.",
    "554": "Απέτυχε η αποθήκευση δεδομένων.",
    "555": "Πληροφορίες για το Smart window",
    "556": "Αποδοχή έξυπνου παραθύρου",
    "557": "Ηλεκτρονικός αριθμός υλικού του παραθύρου",
    "558": "Σειριακός αριθμός υλικού του παραθύρου",
    "559": "Σαρώστε τον κωδικό QR (DMC) από τον κύριο ελεγκτή ή εισαγάγετε τον χειροκίνητα",
    "560": "Smart Window πλήρως διαμορφωμένο και λειτουργικό",
    "561": "Όλα τα ορατά στοιχεία χωρίς βλάβες/ελαττώματα",
    "562": "Έχει συνδεθεί ένα σύστημα μεταγωγής σε 0-10V (0-100%);",
    "563": "Τοποθεσία εγκατάστασης",
    "564": "Έναρξη σάρωσης",
    "565": "Διακοπή σάρωσης",
    "566": "Επιλεγμένο στοιχείο",
    "567": "Μπορούν να επιλεγούν ταυτόχρονα το πολύ 4 χαρακτηριστικά.",
    "568": "Θέση πελάτη",
    "569": "Ημερομηνία παραγωγής",
    "570": "Αλλαγή Window.ID (αναγνωριστικό παραθύρου)",
    "571": "Προηγούμενο Window.ID (αναγνωριστικό παραθύρου)",
    "572": "Νέο Window.ID (αναγνωριστικό παραθύρου)",
    "573": "Αριθμός εντολής δεν υπάρχει ή ο νέος αριθμός εντολής χρησιμοποιείται ήδη.",
    "574": "Window.ID",
    "575": "ID θέσης",
    "576": "Τύπος",
    "577": "Αναγνωριστικό στοιχείου",
    "578": "Πληροφορίες στοιχείου",
    "579": "Window.ID",
    "580": "Υπογράψτε στον προβλεπόμενο χώρο",
    "581": "Τελειωμένο",
    "582": "Υπογραφή",
    "583": "Νέα γλώσσα",
    "584": "Αρχική γλώσσα",
    "585": "Ονομασία γλώσσας",
    "586": "Δημιουργία νέας γλώσσας",
    "587": "Προσθήκη γλώσσας",
    "588": "Ενημέρωση",
    "589": "ενημέρωση σε εξέλιξη",
    "590": "Απενεργοποίηση εταιρείας",
    "591": "Ενεργοποίηση εταιρείας",
    "592": "Σίγουρα θέλετε να απενεργοποιήσετε αυτή την εταιρεία;",
    "593": "Σίγουρα θέλετε να ενεργοποιήσετε αυτή την εταιρεία;",
    "594": "Απενεργοποίηση χρήστη",
    "595": "Ενεργοποίηση χρήστη",
    "596": "Σίγουρα θέλετε να απενεργοποιήσετε αυτόν τον χρήστη;",
    "597": "Σίγουρα θέλετε να ενεργοποιήσετε αυτόν τον χρήστη;",
    "598": "Θέση ID ",
    "599": "Όλα",
    "600": "Εισαγωγή/Εξαγωγή",
    "601": "Παρακαλώ επιλέξτε αρχείο γλώσσας .csv για εισαγωγή",
    "602": "Εισαγωγή",
    "603": "Εξαγωγή",
    "604": "Αρχείο",
    "605": "Μεταφραστής",
    "606": "Αρχεία γραμμωτού κώδικα",
    "607": "Παρακαλώ επιλέξτε διαδρομή φακέλου για τα αρχεία barcode.",
    "608": "Window_ID",
    "609": "Σύστημα προφίλ",
    "610": "Θέση πελάτη",
    "611": "Χρώμα",
    "612": "Μέγεθος στοιχείου",
    "613": "Μέγεθος γυαλιού",
    "614": "Δομή γυαλιού",
    "615": "Πληροφορίες υλικού",
    "616": "Ημερομηνία αντιστοίχισης",
    "617": "Ημερομηνία/ώρα εγκατάστασης",
    "618": "Τοποθεσία εγκατάστασης δεδομένων GPS",
    "619": "Nr:",
    "620": "Εγκατάσταση:",
    "621": "Όροφος:",
    "622": "Pos:",
    "623": "Αγνοήστε τον/τους αρχικό/ους χαρακτήρα/ες",
    "624": "Αγνοήστε τον ή τους τελικούς χαρακτήρες",
    "625": "Email - Ενεργοποίηση χρήστη",
    "626": "Ενεργοποίηση εταιρειών",
    "627": "Λήξη δοκιμαστικής περιόδου",
    "628": "Επαναποστολή email ενεργοποίησης",
    "629": "Υπάρχει ήδη έμπορος/εργοστάσιο",
    "630": "Ο χρήστης έχει ενεργοποιηθεί",
    "631": "Μετάβαση στη σελίδα σύνδεσης",
    "632": "Στοιχεία χρήστη",
    "633": "Προσθήκη συνδεδεμένου χρήστη",
    "634": "Διαγραφή συνδέσμου χρήστη",
    "635": "Ο χρήστης είναι ήδη συνδεδεμένος",
    "636": "Το όνομα χρήστη ή ο κωδικός πρόσβασης είναι κενό",
    "637": "Ο χρήστης δεν βρέθηκε",
    "638": "Αποστολή πρωτοκόλλου στη διαχείριση",
    "639": "Ερώτηση για ολοκλήρωση της συναρμολόγησης",
    "640": "Επιλογή 5",
    "641": "Επιλογή 6",
    "642": "Επιλογή 7",
    "643": "Επιλογή 8",
    "644": "Επιλογή 9",
    "645": "Επιλογή 10",
    "646": "Συναρμολογητής",
    "647": "Τύπος",
    "648": "Ημερομηνία",
    "649": "Περιγραφή",
    "650": "Συντήρηση υλικού",
    "651": "Ρύθμιση",
    "652": "Καθαρισμός",
    "653": "Σφραγίδα",
    "654": "Αξεσουάρ αντικατάστασης",
    "655": "Σχόλιο",
    "656": "Σύνδεσμος",
    "657": "σύστημα παραθύρου",
    "658": "κομμάτι",
    "659": "Αριθμός έργου",
    "660": "Αναγνωριστικό παραγγελίας",
    "661": "ύψος",
    "662": "πλάτος",
    "663": "Πληροφορίες υλικού",
    "664": "Πλάτος γυαλιού x ύψος",
    "665": "Δομή γυαλιού",
    "666": "Window.ID",
    "667": "Ηλεκτρικό αναγνωριστικό παραθύρου",
    "668": "Αναγνωριστικό γυαλιού",
    "669": "Συνθήκες",
    "670": "Προσθήκη/Αφαίρεση",
    "671": "Και/ή",
    "672": "Ομάδα",
    "673": "Στήλη",
    "674": "Κατάσταση",
    "675": "Εισάγετε τον αριθμό πελάτη",
    "676": "Μεταφραστής",
    "678": "Έλεγχος έκδοσης εφαρμογής",
    "679": "Η ενημέρωση μπορεί να διαρκέσει λίγα λεπτά για να ολοκληρωθεί",
    "680": "Αναμονή νέας έκδοσης",
    "681": "Υπάρχει νέα έκδοση της εφαρμογής, είστε σίγουροι ότι θέλετε να την ενημερώσετε;",
    "682": "Η εφαρμογή είναι ενημερωμένη, θέλετε να επαναφέρετε την παλιά έκδοση;",
    "683": "Επαναφορά παλιάς έκδοσης",
    "684": "Εισάγετε το όνομα του σταθμού",
    "685": "Αριθμός παραγγελίας συμπλήρωση χαρακτήρων/ψηφίων",
    "686": "Παρακαλώ εισάγετε τον αριθμό παραγγελίας συμπλήρωση χαρακτήρων/ ψηφίων",
    "687": "Μέγιστο μήκος αριθμού παραγγελίας",
    "688": "Παρακαλώ εισάγετε το μέγιστο μήκους αριθμού παραγγελίας",
    "689": "Διαδρομή φακέλου MDB",
    "690": "Παρακαλώ εισάγετε τη διαδρομή του αρχείου .mdb (χρησιμοποιείται από τον εισαγωγέα κατά τη σάρωση για αρχεία .mdb)",
    "691": "Διαδρομή φακέλου XML",
    "692": "Παρακαλώ εισάγετε τη διαδρομή αρχείου .xml (χρησιμοποιείται από τον εισαγωγέα κατά τη σάρωση για αρχεία .xml)",
    "693": "Αγνόηση αρχικών χαρακτήρων QR-Code",
    "694": "Αγνοήστε τον ή τους τελικούς χαρακτήρες QR-Code",
    "695": "Περιγραφή ",
    "696": "Μήκος",
    "697": "Πρόσθετη θέση",
    "698": "Έξυπνο παράθυρο χωρίς βλάβη",
    "699": "Έξυπνο παράθυρο συνδεδεμένο",
    "700": "Προσανατολισμός παραθύρου",
    "701": "Αριθμός στοιχείου θέσης",
    "702": "Smart Window διαμορφωμένο",
    "703": "Στοιχείο χωρίς βλάβη",
    "704": "Αριθμός υλικού",
    "705": "Αύξων αριθμός",
    "706": "Δεν υπάρχει διαθέσιμη εικόνα",
    "707": "Καθαρό εργοτάξιο",
    "708": "Σύρετε και αποθέστε αρχεία εικόνας/pdf εδώ",
    "709": "Ανέβασμα αρχείου",
    "710": "Τύπος αρχείου",
    "711": "Παραγγελία (όλες οι θέσεις)",
    "712": "Διαγραμμένα",
    "713": "Σημειώσεις έκδοσης",
    "714": "Ύψος (mm)",
    "715": "Πλάτος (mm)",
    "716": "Δεν υπάρχει πίνακας για αυτή τη θέση",
    "717": "Στοιχείο",
    "718": "Σταθμός αποθήκευσης/διανομής",
    "719": "Εύρος αποθήκευσης/ διανομής",
    "720": "Window.ID (τελευταίο)",
    "721": "Το γυαλί αντικαταστάθηκε",
    "722": "Επισκευάστηκαν τα αξεσουάρ",
    "723": "Αντικαταστάθηκε το φύλλο",
    "724": "Επισκευάστηκε η επιφάνεια",
    "725": "Επισκευάστηκαν τα αξεσουάρ",
    "726": "Η επισκευή ολοκληρώθηκε",
    "727": "Επιβεβαίωση κωδικού πρόσβασης",
    "728": "Λάθος κωδικός πρόσβασης",
    "729": "Οι κωδικοί πρόσβασης δεν ταιριάζουν",
    "730": "Ο κωδικός πρόσβασης δεν μπορεί να είναι κενός",
    "731": "Τοποθεσία",
    "732": "Χρονολόγιο",
    "733": "Τιμή U (W/m²K)",
    "734": "Ειδικό γυαλί",
    "735": "Δεν υπάρχει θέση",
    "736": "Πρόοδος παραγωγής",
    "737": "Η παραγωγή έχει ολοκληρωθεί",
    "738": "Πρόοδος εγκατάστασης",
    "739": "Ολοκλήρωση της εγκατάστασης",
    "740": "Διαγραφή σταθμού",
    "741": "Θέλετε πραγματικά να διαγράψετε αυτόν τον σταθμό;",
    "742": "Προσθήκη νέου σταθμού",
    "743": "Διαγραφή παραγγελίας",
    "744": "Διαγραφή παραγγελίας",
    "745": "Είστε σίγουροι ότι θέλετε να διαγράψετε την παραγγελία;",
    "746": "Προσθήκη χρήστη",
    "747": "Επεξεργασία χρήστη",
    "748": "Ξεχάσατε τον κωδικό πρόσβασης;",
    "749": "Το πεδίο Κωδικός πρόσβασης είναι υποχρεωτικό",
    "750": "Το πεδίο Όνομα χρήστη είναι υποχρεωτικό",
    "751": "Αυτός ο ρόλος χρήστη δεν είναι εξουσιοδοτημένος για σύνδεση στο ταμπλό",
    "752": "Ο χρήστης δεν είναι ενεργός",
    "753": "Η εταιρεία χρήστη δεν είναι ενεργή ή η περίοδος δοκιμής έχει λήξει",
    "754": "Το όνομα χρήστη ή ο κωδικός πρόσβασης είναι λανθασμένος",
    "755": "Ταχυδρομικός κώδικας",
    "756": "Χρησιμοποιήστε τον τροχό κύλισης για μεγέθυνση και σμίκρυνση της εικόνας",
    "757": "Επαναφορά κωδικού πρόσβασης",
    "758": "Έχει αποσταλεί email για επαναφορά κωδικού πρόσβασης",
    "759": "Επαναφορά κωδικού πρόσβασης",
    "760": "Email - Επαναφορά κωδικού πρόσβασης",
    "761": "Αριθμός στοιχείου",
    "762": "Αριθμός παραγγελίας από",
    "763": "Παρακαλώ εισάγετε τον αριθμό παραγγελίας από",
    "764": "Μήκος αριθμού παραγγελίας",
    "765": "Παρακαλώ εισάγετε το μήκος του αριθμού παραγγελίας",
    "766": "Εύρος παραγγελίας από",
    "767": "Παρακαλώ εισάγετε το εύρος παραγγελίας από",
    "768": "Μήκος σειράς παραγγελίας",
    "769": "Παρακαλώ εισάγετε το μήκος του εύρους παραγγελίας",
    "770": "Please insert order piece from",
    "772": "Μήκος τεμαχίου παραγγελίας",
    "773": "Παρακαλώ εισάγετε το μήκος του τεμαχίου παραγγελίας",
    "774": "Αριθμός θέσης από",
    "775": "Παρακαλώ εισάγετε τον αριθμό θέσης από",
    "776": "Μήκος αριθμού θέσης",
    "777": "Παρακαλώ εισάγετε το μήκος του αριθμού θέσης",
    "778": "Τεμάχιο θέσης από",
    "779": "Παρακαλώ εισάγετε το τεμάχιο θέσης από",
    "780": "Μήκος τεμαχίου θέσης",
    "781": "Παρακαλώ εισάγετε το μήκος του τεμαχίου θέσης",
    "782": "Επίπεδο ασφαλείας",
    "783": "Χαρακτήρας πλήρωσης γραμμωτού κώδικα",
    "784": "Παρακαλώ εισάγετε χαρακτήρα πλήρωσης γραμμωτού κώδικα",
    "785": "Πίσω στα έργα",
    "786": "(μόνο για ανάγνωση)",
    "787": "περιορισμένη πρόσβαση",
    "788": "πρόσβαση μόνο για ανάγνωση",
    "789": "δική σας σελίδα προορισμού",
    "790": "Παρακαλώ εισάγετε τη συμβολοσειρά σύνδεσης",
    "791": "Συμβολοσειρά σύνδεσης DB",
    "792": "Συμβολοσειρά σύνδεσης DB STD",
    "793": "Συμβολοσειρά σύνδεσης DB BWG",
    "794": "Σύνδεση βάσης δεδομένων",
    "795": "Συνομιλία",
    "796": "Μηνύματα",
    "797": "Έγγραφο CE",
    "798": "Έγγραφο παραγγελίας",
    "799": "Τελευταία επισκευή",
    "800": "Ολοκληρώθηκε η παραλαβή",
    "801": "Νέα ενημέρωση διαθέσιμη, παρακαλώ επανεκκινήστε την εφαρμογή",
    "802": "Εξωτερικός σύνδεσμος",
    "803": "Πρόθεμα εξωτερικού συνδέσμου",
    "804": "Έργο αναφοράς",
    "805": "Διάστημα εργασιών",
    "806": "Χρήστες του Dashboard ",
    "807": "Χρήστες της εφαρμογής",
    "808": "Τελευταία μεταφόρτωση",
    "809": "λεπτά",
    "810": "εκδοχή Matcher",
    "811": "εκδοχή Importer ",
    "812": "Αποσύνδεση χρήστη",
    "813": "Ο χρήστης αποσυνδέθηκε επιτυχώς",
    "814": "Το Barcode άλλαξε",
    "815": "Επικαιροποιημένη πληροφορία ",
    "816": "Οδηγίες",
    "817": "Εγκατάσταση APP",
    "818": "Εγκαταστήστε την εφαρμογή Window.ID APP σκανάροντας το QR code",
    "819": "ταυτοποίηση",
    "820": "δεν υπάρχει ταυτοποίηση",
    "821": "Planner",
    "822": "Αριθμός ημερών",
    "823": "Ρυθμίσεις για υπενθύμιση συντήρησης",
    "824": "ημέρες",
    "825": "Επόμενη συντήρηση",
    "826": "Επεξεργασία εικόνας",
    "827": "Χωρίς φίλτρο",
    "828": "Προσθήκη παραγγελίας",
    "829": "New order",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera"
};

export default gr;

