const de = {
    "1": "Ja",
    "2": "Nein",
    "3": "30-Tage Testversion",
    "4": "Es wurde eine Reklamation über die APP gemeldet",
    "5": "Abnahme",
    "6": "Abnahmedatum",
    "7": "Aktion",
    "8": "Aktiv",
    "9": "Aktivitäten",
    "10": "Hinzufügen",
    "11": "Monteur hinzufügen",
    "12": "Firma hinzufügen",
    "13": "Händler hinzufügen",
    "14": "Benutzer hinzufügen",
    "15": "Hausverwaltung hinzufügen",
    "16": "Benutzer hinzufügen",
    "17": "Freifeld",
    "18": "Bearbeiten des zusätzlichen Feldnamens",
    "19": "Zusatzinfo",
    "20": "Zusatzinfo",
    "21": "Zusatzprodukte",
    "22": "Einstellung",
    "23": "Admin",
    "24": "Verwaltungsansicht",
    "25": "Anlieferung aller Elemente an Baustelle erfolgt",
    "26": "Alle Positionen sind montiert",
    "27": "Alle Rechte vorbehalten.",
    "28": "Möchten Sie diese Firma wirklich löschen?",
    "29": "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?",
    "30": "Montiert",
    "31": "Monteur",
    "32": "Monteure",
    "33": "Montage",
    "34": "Montage abgeschloßen",
    "35": "Montagedatum",
    "36": "Aufträge",
    "37": "Pflegehinweise",
    "38": "Zertifikatdetails",
    "39": "Zertifikat",
    "40": "Reinigen",
    "41": "Filter löschen",
    "42": "Schließen",
    "43": "Farbe/Dekor",
    "44": "Farbe/Dekor",
    "45": "Kommission",
    "46": "Firma",
    "47": "Landingpage Design",
    "48": "Firmeninfo",
    "49": "Himmelsrichtung",
    "50": "Reklamationen",
    "51": "Gesamtabnahme",
    "52": "Abgeschlossen",
    "53": "verbundenes Fenster",
    "54": "Baustelle sauber",
    "55": "Erstelldatum",
    "56": "Endkunde",
    "57": "Kundennummer",
    "58": "Kundenposition",
    "59": "Datum",
    "60": "Datum",
    "61": "Händler",
    "62": "Firma löschen",
    "63": "Firma löschen",
    "64": "Benutzer löschen",
    "65": "Lieferdatum",
    "66": "Anlieferung ohne Schäden",
    "67": "Beschreibung",
    "68": "Bezeichnung Aufstellungsort",
    "69": "Zeigt _MENU_ Datensätze pro Seite an",
    "70": "Vertrieb",
    "71": "Dokumente",
    "72": "Osten",
    "73": "Monteur bearbeiten",
    "74": "Gesellschaft bearbeiten",
    "75": "Händler bearbeiten",
    "76": "Hausverwaltung bearbeiten",
    "77": "Benutzer bearbeiten",
    "78": "Elementbreite x Höhe",
    "79": "Element mit dieser ID existiert nicht",
    "80": "Elemente",
    "81": "E-Mail-Zugangsdaten gespeichert.",
    "82": "Farbe/Dekor eingeben",
    "83": "Komission eigeben",
    "84": "Unternehmensnamen eingeben",
    "85": "E-Mail eingeben",
    "86": "Erweiterung eingeben",
    "87": "Höhe eingeben",
    "88": "IP-Adresse eingeben",
    "89": "Vornamen eingeben",
    "90": "Nummer eingeben",
    "91": "Geben Sie die Anzahl der Positionen ein",
    "92": "Passwort eingeben",
    "93": "Telefonnummer eingeben",
    "94": "Telefonnummer eingeben",
    "95": "PLZ eingeben",
    "96": "Menge eingeben",
    "97": "Straße eingeben",
    "98": "Nachnamen eingeben",
    "99": "Geben Sie den Benutzernamen ein",
    "100": "USt-Nr. eingeben",
    "101": "Website eingeben",
    "102": "Breite eingeben",
    "103": "Fehler beim speichern der Daten",
    "104": "Fehler beim speichern der E-Mail-Zugangsdaten",
    "105": "Austausch Zubehör",
    "106": "Laufzeit bis",
    "107": "läuft ab",
    "108": "Erweiterung",
    "109": "Produzenten",
    "110": "Produzent",
    "111": "Suche",
    "112": "gefiltert aus _MAX_ Datensätzen insgesamt",
    "113": "Zuerst",
    "114": "Abbrechen",
    "115": "Beschlagsdaten",
    "116": "Fliegengitter",
    "117": "Fußabdruck",
    "118": "Freitext",
    "119": "Von App",
    "120": "Von Verwaltungsansicht",
    "121": "Glasreiniger",
    "122": "Glastausch",
    "123": "Glasaufbau",
    "124": "Glasbreite x Höhe in mm",
    "125": "Beschlagwartung",
    "126": "Beschlagtausch",
    "127": "Höhe",
    "128": "Reinigungsanleitung",
    "129": "Bild",
    "130": "Informationen zum Element",
    "131": "Informationen sind eingeschränkt",
    "132": "Positionen",
    "134": "Bezeichnung Einbauort",
    "135": "Barcode scannen",
    "136": "IP Adresse",
    "137": "ist erforderlich",
    "138": "Sprache",
    "139": "Zuletzt",
    "140": "Letzte Wartung",
    "141": "Letzte Reklamation",
    "142": "Letzter Service",
    "143": "Link zu Artikel",
    "144": "Wird geladen",
    "145": "Verortung der Elemente innerhalb des Bauvorhabens",
    "146": "Logout",
    "147": "Mail Zugangsdaten",
    "148": "Wartung",
    "149": "manuelle Änderung durch",
    "150": "Produziert",
    "151": "Mastercontroller Bauform",
    "152": "Master Controller Materialnummer",
    "153": "Master Controller Seriennummer",
    "154": "Produktionsdatum",
    "155": "Menü Sichtbarkeit",
    "156": "Barcode automatisch bestätigen",
    "157": "Landingpage",
    "158": "Neuer Testzeitraum",
    "159": ">",
    "160": "Keine Schäden am Element",
    "161": "Keine Schäden am Glas",
    "162": "Keine übereinstimmenden Aufzeichnungen gefunden",
    "163": "Keines der Elemente ist montiert",
    "164": "Norden",
    "165": "Nicht montiert",
    "166": "Nichts gefunden - sorry",
    "167": "Tagnummer",
    "168": "Anzahl Elemente",
    "169": "Übersicht",
    "170": "Anzahl der Positionen",
    "171": "am",
    "172": "Bestelldatum",
    "173": "Auftragsnummer",
    "174": "Fortschritt",
    "175": "Ausgang",
    "176": "Passwort",
    "177": "Telefon",
    "178": "Ort",
    "179": "Kunststoffreiniger",
    "180": "Pos. Dokumente",
    "181": "Positionsdetails",
    "182": "ID existiert nicht",
    "183": "Positionen",
    "184": "<",
    "185": "Anmeldeinformationen",
    "186": "Zugangsdaten drucken",
    "187": "Wird bearbeitet",
    "188": "Produzent",
    "189": "Profilsystem",
    "190": "Aufträge",
    "191": "Auftragsnummer",
    "192": "Hausverwaltung",
    "193": "Menge",
    "194": "Reklamation",
    "195": "Bitte Station auswählen",
    "196": "Recyclinganteil",
    "197": "Reparatur",
    "198": "Reparatur Zubehör",
    "199": "Reparatur Oberfläche",
    "200": "Via App gemeldet",
    "201": "Reklamierte Fensterpositionen",
    "202": "Testzeitraum zurücksetzen",
    "203": "Ergebnis",
    "204": "Rolle",
    "205": "Speichern",
    "206": "Erfolgreich gespeichert",
    "207": "Versiegeln",
    "208": "Suche",
    "209": "Sicherheitsstufe",
    "210": "Zugangsdaten an REHAU",
    "211": "Service",
    "212": "Service & Vertrieb",
    "213": "Verwalter",
    "214": "Einstellungen",
    "215": "Manuelle Gesamtabnahme des Auftrags durchführen?",
    "216": "Es werden _START_ bis _END_ von _TOTAL_ Datensätzen angezeigt",
    "217": "Es werden 0 bis 0 von 0 Datensätzen angezeigt",
    "218": "Rollladen",
    "219": "Login",
    "220": "Melden Sie sich an, um Ihre Sitzung zu starten",
    "221": "Smart Glass Informationen",
    "222": "Smart Window",
    "223": "Elemente wurden montiert",
    "224": "Elemente noch nicht gematched",
    "225": "Einige Position (3,en) müssen Montage",
    "226": "Süden",
    "227": "Statistik",
    "228": "Straße",
    "229": "Nachname",
    "230": "Nachhaltigkeit",
    "231": "Telefon",
    "232": "Testzeitraum erfolgreich zurückgesetzt",
    "233": "Testzeitraum(3,e)",
    "234": "Die Eigenschaften wurden beibehalten",
    "235": "Es gibt Reklamationen",
    "236": "Es gibt kein Unternehmen mit dieser ID",
    "237": "Es gibt keine Informationen zu diesem Element",
    "238": "Diese Firma wird bereits verwendet",
    "239": "Titel",
    "240": "bis",
    "241": "Gesamtabnahme",
    "242": "Gesamtanzahl",
    "243": "Unbekannt",
    "244": "Logo hochladen",
    "245": "Benutzerverwaltung",
    "246": "Benutzername",
    "247": "Benutzername bereits vergeben",
    "248": "Benutzer",
    "249": "U-Wert",
    "250": "U-Wert Rahmen",
    "251": "U-Wert Glas",
    "252": "W.ID vergeben",
    "253": "Warnung - Window.ID nicht beschädigen",
    "254": "Willkommen im Window.ID Dashboard",
    "255": "Westen",
    "256": "Breite",
    "257": "Breite x Höhe in mm",
    "258": "Fensterfläche von",
    "259": "Fensterfläche (Auftrag) in",
    "260": "Window Elektronic Materialnummer",
    "261": "Window Elektronic Seriennummer",
    "262": "Bezeichnung Einbauort",
    "263": "Profilsystem",
    "264": "Fenstertyp",
    "265": "Flügeltausch",
    "266": "Sind Sie sicher?",
    "267": "Filter löschen",
    "268": "Bearbeiten",
    "269": "CSV exportieren",
    "270": "Barcode-Leser",
    "271": "Glas-ID",
    "272": "Informationen",
    "273": "Verknüpfung",
    "274": "Verknüpfte Benutzer",
    "275": "E-Mail",
    "276": "E-Mail-Einstellungen",
    "277": "E-Mail-Testzeitraum",
    "278": "Titel des Serviceauftrags",
    "279": "Firma des Service",
    "280": "Service Telefonnummer",
    "281": "Service E-Mail",
    "282": "Status",
    "283": "Stationseinstellungen",
    "284": "Betreff",
    "285": "Telefon",
    "286": "Benutzer",
    "287": "Website",
    "288": "Window.ID",
    "289": "Profilsystem",
    "290": "Details zum Kunden",
    "291": "Name",
    "292": "Glasaufbau",
    "293": "Beschlagsdaten",
    "294": "Titel der Montage",
    "295": "Firma der Montage",
    "296": "Telefon Montage",
    "297": "E-Mail für die Montage",
    "298": "URL",
    "299": "Land",
    "300": "Bitte Benutzer für diese Firma hinzufügen",
    "301": "Zusätzliches Menü",
    "302": "Dashboard",
    "303": "App",
    "304": "Landingpage",
    "305": "Benutzerverwaltung",
    "306": "Bestätigen",
    "307": "Körper",
    "308": "Anhang",
    "309": "ID-Kennung",
    "310": "Position",
    "311": "Warnung",
    "312": "W.ID",
    "314": "Händler",
    "315": "senden",
    "316": "deaktiviere",
    "317": "Händler",
    "318": "Entschuldigung",
    "319": "Verwalter",
    "320": "Benutzer",
    "321": "Postleitzahl",
    "322": "Logo",
    "323": "Hintergrundbild",
    "324": "Kundennummer",
    "325": "CE Dokumente",
    "326": "Matchereinstellungen",
    "327": "Automatisch",
    "328": "Manuell",
    "329": "Durchsuche",
    "330": "Laden",
    "331": "Dokumente Auto Upload",
    "332": "Quellordner (Dokumente)",
    "333": "Bitte wählen Sie den Ordnerpfad für das automatische Hochladen der Dokumente.",
    "334": "Bitte wählen Sie eine ZIP-Datei mit einer XML und Positionsbildern",
    "335": "Station",
    "336": "Window.ID manuell scannen",
    "337": "Smart Glass Station",
    "338": "Übertragung",
    "339": "Angezeigte Positionen in der Timeline",
    "340": "Window.ID von",
    "341": "Window.ID bis",
    "342": "Window.ID Präfix",
    "343": "Window.ID Länge",
    "344": "Daten sind ungültig",
    "345": "API Schlüssel",
    "346": "Window.ID scannen",
    "347": "Stationsname",
    "348": "Datei nicht ausgewählt",
    "349": "Datei hochgeladen",
    "350": "Fehler beim Upload",
    "351": "Der manuelle Scan ist aktiviert",
    "352": "Window.ID scannen",
    "353": "ungültig",
    "354": "Barcode importiert",
    "356": "keine Daten",
    "357": "Verbindungsfehler",
    "358": "Historie",
    "359": "Window.ID wird bereits verwendet",
    "360": "Fehler beim matchen",
    "361": "manuell",
    "362": "von",
    "363": "zu",
    "364": "Die Länge des Präfix muss kleiner als die Länge der Window.ID sein",
    "365": "Der Window.ID Nummernkreis ist ungültig",
    "366": "Ungültige Zahlen",
    "367": "Wurde zuvor verwendet",
    "368": "Heute verwendet",
    "369": "Frei",
    "370": "Bis heute verwendet",
    "371": "Verwendung der QR-Code-Nummer für den aktuellen Stationsbereich",
    "372": "Übersetzungen",
    "373": "Übersetzung bearbeiten",
    "374": "Key",
    "375": "Quellsprache",
    "376": "Zielsprache",
    "377": "W.ID zuweisen",
    "378": "Positionen der Elemente im Bauvorhaben",
    "379": "Reparaturservice / Reparaturnachweis",
    "380": "Wartung & Historie",
    "381": "Montageprotokoll",
    "382": "Änderung",
    "383": "Glastausch",
    "384": "Flügeltausch",
    "386": "Freitext",
    "387": "Die techn. Eigenschaften wurden beibehalten",
    "388": "Neue Eigenschaften",
    "389": "Wartungsprotokoll",
    "390": "Beschlagwartung",
    "391": "Wartungstermin vereinbaren",
    "392": "Gesamtes Bauvorhaben",
    "393": "Fensterinformationen",
    "394": "Informationen",
    "395": "Farbe/Dekor",
    "396": "Elementbreite x Höhe in mm",
    "397": "Glasbreite x Höhe in mm",
    "398": "Beschlagdaten",
    "399": "Monteur",
    "400": "Datum/Uhrzeit Montage",
    "401": "Montageort (GPS)",
    "402": "Geräte ID ",
    "403": "senden",
    "404": "Filterposition",
    "405": "Keiner",
    "406": "Balkontüren",
    "407": "Küche",
    "408": "Bad",
    "409": "Zimmer",
    "410": "Dach",
    "411": "Montage",
    "412": "Position",
    "413": "Bild anfügen",
    "414": "Unterschrift",
    "415": "Zeichnung",
    "416": "Keine Schäden am Element",
    "417": "Keine Schäden am Glas",
    "418": "Kamera öffnen",
    "419": "Bild hochladen",
    "420": "Fenster montieren & zuweisen",
    "421": "Abnahmeprotokoll",
    "422": "senden",
    "423": "Gesamtes Bauvorhaben",
    "424": "Montageart",
    "425": "Explizit für Element",
    "426": "Auftrag abgeschlossen",
    "427": "Montageprotokolle einzelner Fenster",
    "428": "Problem",
    "429": "Hilfe",
    "430": "Nachricht",
    "431": "senden",
    "432": "Nächstes Element",
    "433": "Liste mit allen Elementen",
    "434": "Bauzeitenplan",
    "435": "via Auftragsnummer",
    "436": "via Scanner",
    "437": "Einzelanzeige",
    "438": "oder",
    "439": "Barcode Nummer",
    "440": "Informationen anzeigen",
    "441": "Reparatur/Reklamation",
    "442": "Kontaktperson anzeigen",
    "443": "Feedback",
    "444": "Weiteren QR Code scannen",
    "445": "Nächste Wartung",
    "446": "Fensterlieferant",
    "447": "Eigenschaften",
    "448": "Schalldämmwert",
    "449": "Uf",
    "450": "Erweiterungsmöglichkeiten",
    "451": "Kontakt",
    "452": "IHRE WINDOW ID",
    "453": "Was möchten Sie tun?",
    "454": "Eine regelmäßige Wartung der Fenster verlängert die Lebenszeit und den Komfort",
    "455": "Wartungstermin per @ anfragen",
    "456": "Wartungstermin per Telefon",
    "457": "an ihren Fenstermonteur",
    "458": "Hiermit bestätige ich die AGB´s und DSGVO Verordnung",
    "459": "Ihr Anliegen",
    "461": "Kontaktaufnahme via...",
    "462": "Glas defekt",
    "463": "Fenster schließt nicht richtig",
    "464": "Fenster klemmt",
    "465": "Fenster beschädigt",
    "466": "Sonstiges / Zubehör",
    "467": "Ihre Fenster und Türen wurden am",
    "468": "von dieser Firma montiert",
    "469": "Fensterproduzent",
    "470": "Bewerten Sie jetzt ihre Fenster und die Montage",
    "471": "Wie zufrieden sind sie mit dem Service der Firma?",
    "472": "Sind die gelieferten Produkte i.O.",
    "473": "Wie gefallen Ihnen ihre Fenster?",
    "474": "Gab es Probleme bei der Abwicklung?",
    "475": "Positives",
    "476": "Was würden Sie verbessern?",
    "477": "Anleitung Montage",
    "478": "Positionen der Fenster & Türen",
    "479": "Montageprotokoll öffnen",
    "480": "Liste aller Elemente des Auftrages",
    "481": "Elementeliste via Auftrags Nr/QR",
    "482": "Auftragsprotokoll senden",
    "483": "Fensterdetails",
    "484": "Einzelanzeige Details",
    "485": "Bauvorhaben Auftrags Nummer",
    "486": "Fenster",
    "487": "Fenster Position",
    "488": "Reparaturservice",
    "489": "Wartung Details",
    "490": "Endkunde",
    "491": "Kontaktformular",
    "492": "Finden Sie jetzt einen REHAU Handwerker in Ihrer Nähe",
    "493": "Kategorie",
    "494": "QR Scan",
    "495": "Kontaktperson",
    "496": "aktualisieren",
    "497": "Filter",
    "498": "Montage abgeschloßen",
    "499": "Montage abgeschloßen?",
    "500": "Anlieferung ohne Schäden",
    "501": "Zwischenabnahme speichern",
    "502": "Aufnahme",
    "503": "Keine gültige E-Mail-Adresse",
    "504": "APP installieren",
    "505": "Ort",
    "506": "Auftragsliste",
    "507": "Ausgewählten Auftrag laden?",
    "508": "Falsche Auftragsnummer",
    "509": "Einzelnes Fenster",
    "510": "Änderungen eintragen",
    "511": "Bericht per E-Mail senden",
    "512": "Bitte 4 Optionen wählen",
    "513": "Scannen sie jetzt den QR-Code um zu erfahren an welcher Position im Gebäude dieses Element montiert werden soll.",
    "514": "Daten speichern",
    "515": "Zeige",
    "516": "Ergebnisse",
    "517": "Seite",
    "518": "von",
    "519": "Datum von",
    "520": "Datum bis",
    "521": "offen",
    "522": "Größe",
    "523": "Montiert am",
    "524": "ok",
    "525": "Die Info",
    "526": "Auftrag geladen!",
    "527": "Mounter ID",
    "528": "Anmeldung",
    "529": "Hiermit bestätige ich die AGB´s und DSGVO Verordnung",
    "530": "Ausloggen",
    "531": "Scanner",
    "532": "Abnahmeprotokoll",
    "533": "Soll das Abnahmeprotokoll für alle Positionen dieses Auftrags an das Dashboard gesendet werden?",
    "534": "Reparaturprotokoll",
    "535": "option 5",
    "536": "option 6",
    "537": "option 7",
    "538": "option 8",
    "539": "option 9",
    "540": "option 10",
    "541": "gesendet",
    "542": "Das Senden der E-Mail ist fehlgeschlagen!",
    "543": "Beschlagwartung",
    "544": "Einstellen",
    "545": "Reinigen",
    "546": "Versiegeln",
    "547": "Austauch Zubehoer",
    "548": "Kommentar",
    "549": "Anlieferung aller Elemente an Baustelle erfolgt",
    "550": "Verortung der Elemente innerhalb des Bauvorhabens",
    "551": "Das Senden des Protokolls ist fehlgeschlagen!",
    "552": "Protokollinfo speichern",
    "553": "Info gespeichert",
    "554": "Datenspeicherung fehlgeschlagen",
    "555": "Smart Glass Informationen",
    "556": "Smart Glass Abnahme",
    "557": "Window Elektronik Materialnummer",
    "558": "Window Elektronik Seriennummer",
    "559": "QR-Code (DMC) vom Master Controller scannen oder manuell eintragen",
    "560": "Smart Window vollständig konfiguriert and funktionsfähig",
    "561": "Alle Sichtelemente ohne Beschädigungen/Fehlstellen",
    "562": "Wurde ein Schaltsystem am 0-10V (0 - 100%) angeschlossen",
    "563": "Einbauort Gebäude",
    "564": "RFID Start",
    "565": "RFID Stop",
    "566": "Derzeit ausgewähltes Element",
    "567": "Es können maximal 4 Attribute gleichzeitig ausgewählt werden",
    "568": "Kundenpos. :",
    "569": "Produktionsdatum",
    "570": "Window.ID ändern",
    "571": "Bisherige Window.ID",
    "572": "Neue Window.ID",
    "573": "Auftragsnummer existiert nicht oder neue Auftragsnummer wird bereits verwendet.",
    "574": "Barcode",
    "575": "Position ID",
    "576": "Typ",
    "577": "Element ID",
    "578": "Elementinformationen",
    "579": "Window.ID",
    "580": "Bitte unterschreiben Sie im vorgesehenen Feld",
    "581": "Fertig",
    "582": "Unterschrift",
    "583": "Sprache hinzufügen",
    "584": "Quellsprache",
    "585": "Zielsprache",
    "586": "Neue Sprache anlegen",
    "587": "Sprache hinzufügen",
    "588": "Update",
    "589": "Update läuft",
    "590": "Company deactivation",
    "591": "Händlerzugang",
    "592": "Dashboard deaktivieren?",
    "593": "Möchten sie diesem Händler den Zugang zu seinem Dashboard aktivieren?",
    "594": "Login verwalten",
    "595": "Händler aktivieren",
    "596": "Möchten Sie diesen Login deaktivieren?",
    "597": "Möchten Sie diesen Händler aktivieren?",
    "598": "ID",
    "599": "Alle",
    "600": "Import/Export",
    "601": "Bitte .csv Sprachdatei für den Import auswählen",
    "602": "Import",
    "603": "Export",
    "604": "Manueller Datenupload",
    "605": "Übersetzer",
    "606": "Barcodes",
    "607": "Bitte Speicherort für Barcodedaten wählen",
    "608": "Window.ID",
    "609": "Profilsystem",
    "610": "Kundenposition",
    "611": "Farbe",
    "612": "Größe Element",
    "613": "Glaßmaße",
    "614": "Glasaufbau",
    "615": "Beschlag",
    "616": "Matching Datum",
    "617": "Datum/Uhrzeit Montage",
    "618": "GPS-Daten Montageort",
    "619": "Position:",
    "620": "Montage:",
    "621": "Stockwerk:",
    "622": "Stück:",
    "623": "Stellen vor Barcode ignorieren",
    "624": "Stellen nach Barcode ignorieren",
    "625": "E-Mail - Händler einladen",
    "626": "Einladungslink senden",
    "627": "Ende Testzugang",
    "628": "Einladungslink erneut senden",
    "629": "Login existiert bereits",
    "630": "Händlerzugang ist aktiviert",
    "631": "Zur Landingpage",
    "632": "Details",
    "633": "Verknüpfe Zugänge",
    "634": "Lösche verknüpften Zugang",
    "635": "Zugang bereits verknüpft",
    "636": "Login ist leer",
    "637": "Login nicht gefunden",
    "638": "Gesamtabnahme senden?",
    "639": "Montage abgeschlossen",
    "640": "Option 5",
    "641": "Option 6",
    "642": "Option 7",
    "643": "Option 8",
    "644": "Option 9",
    "645": "Option 10",
    "646": "Monteur",
    "647": "Typ",
    "648": "Datum",
    "649": "Beschreibung",
    "650": "Beschlagwartung",
    "651": "Einstellen",
    "652": "Reinigen",
    "653": "Versiegeln",
    "654": "Beschlagtausch",
    "655": "Kommentar",
    "656": "Anschluss",
    "657": "Profilsystem",
    "658": "Stück Nr.",
    "659": "Auftragsnr.",
    "660": "Auftrag ID",
    "661": "Höhe",
    "662": "Breite",
    "663": "Beschlagdaten",
    "664": "Glasbreite x Höhe",
    "665": "Glasaufbau",
    "666": "Window.ID",
    "667": "Window Electric ID",
    "668": "Glass ID",
    "669": "Bedingung",
    "670": "Hinzufügen/entfernen",
    "671": "Und/oder",
    "672": "Gruppe",
    "673": "Feld",
    "674": "Bedingung",
    "675": "Kundennummer eingeben",
    "676": "Translator",
    "678": "Application version control",
    "679": "Der Updatevorgang kann einige Minuten dauern",
    "680": "Neueste Version wird angefordert",
    "681": "Neue Version verfügbar, Update starten?",
    "682": "Die Anwendung ist auf dem neuesten Stand, möchten Sie die alte Version wiederherstellen?",
    "683": "Alte Version wiederherstellen",
    "684": "Stationsname einfügen",
    "685": "Füllzeichen für Auftragsnummer",
    "686": "Bitte geben Sie die Auftragsnummer als Füllzeichen ein",
    "687": "Bestellnummer maximale Länge",
    "688": "Bitte geben Sie die maximale Länge der Bestellnummer ein",
    "689": "MDB Ablagepfad",
    "690": "Speicherort der MDB´s",
    "691": "XML Ablagepfad",
    "692": "Speicherort der XML´s",
    "693": "Stellen vor Window.ID ignorieren",
    "694": "Stellen nach Window.ID ignorieren",
    "695": "Bezeichnung",
    "696": "Laenge",
    "697": "Zusatzposition",
    "698": "Smart window ohne Schäden",
    "699": "Smart window angeschlossen",
    "700": "Ausrichtung des Fensters",
    "701": "Position Elementnummer",
    "702": "Smart window konfiguriert",
    "703": "Element ohne Beschädigung",
    "704": "Materialnummer",
    "705": "Seriennummer",
    "706": "Kein Bild vorhanden",
    "707": "Baustelle sauber",
    "708": "Daten per drag&drop hier ablegen",
    "709": "Dokument hinzufügen",
    "710": "Dokumententyp",
    "711": "Allen Positionen hinzufügen",
    "712": "Gelöscht",
    "713": "Versionshinweise",
    "714": "Höhe in mm",
    "715": "Breite in mm",
    "716": "There is no panel for this position",
    "717": "Item",
    "718": "Bulk Station",
    "719": "Bulk Bereich (Menge der ID´s)",
    "720": "Window.ID (letzte)",
    "721": "Glas wurde ausgetauscht",
    "722": "Zubehör wurde repariert",
    "723": "Flügel wurde ausgetauscht",
    "724": "Oberfläche beschädigt",
    "725": "Zubehör wurde repariert",
    "726": "Reparatur abgeschloßen",
    "727": "Passwort bestätigen",
    "728": "Falsches Passwort",
    "729": "Passwörter stimmen nicht überein",
    "730": "Passwort darf nicht leer sein",
    "731": "Ort",
    "732": "Zeitleiste",
    "733": "Uw (W/m²K)",
    "734": "Glas Besonderheiten",
    "735": "Keine Position",
    "736": "Fortschritt Produktion",
    "737": "Produktion abgeschlossen",
    "738": "Fortschritt Montage",
    "739": "Montage abgeschlossen",
    "740": "Station gelöscht",
    "741": "Wollen Sie diese Station wirklich löschen?",
    "742": "Neue Station hinzufügen",
    "743": "Auftrag löschen",
    "744": "Löschung der Bestellung",
    "745": "Sind Sie sicher, dass Sie den Auftrag löschen wollen?",
    "746": "Benutzer hinzugefügt",
    "747": "Benutzer bearbeitet",
    "748": "Passwort vergessen?",
    "749": "Das Feld Passwort ist erforderlich",
    "750": "Das Feld Benutzername ist erforderlich",
    "751": "Diese Benutzerrolle ist nicht für die Anmeldung im Dashboard berechtigt",
    "752": "Benutzer ist nicht aktiv",
    "753": "Benutzerfirma ist nicht aktiv oder Testperiode ist vorbei",
    "754": "Benutzername oder Passwort ist falsch",
    "755": "PLZ",
    "756": "Verwenden Sie das Scrollrad zum Vergrößern und Verkleinern des Bildes",
    "757": "Passwort zurücksetzen",
    "758": "E-Mail zum Zurücksetzen des Passworts wurde gesendet",
    "759": "Passwort wurde zurückgesetzt",
    "760": "E-Mail - Passwort zurücksetzen",
    "761": "Element Nr",
    "762": "Auftragsnummer Start",
    "763": "Stelle an der die Auftragsnummer beginnt ",
    "764": "Länge Auftragsnummer",
    "765": "Bitte geben Sie die Länge der Auftragsnummer ein",
    "766": "Auftragsnummer-Bereich Start",
    "767": "Stelle an der der Auftragsnummer-Bereich beginnt ",
    "768": "Länge des Auftragsnummer-Bereichs",
    "769": "Bitte geben Sie die Länge des Auftragsnummer-Bereich ein",
    "770": "Please insert order piece from",
    "772": "Länge Auftragsstückzahl",
    "773": "Bitte  geben Sie die Länge der Auftragsstückzahl eingeben",
    "774": "Positionsnummer Start",
    "775": "Stelle an der die Positionsnummer beginnt",
    "776": "Länge Positionsnummer ",
    "777": "Bitte geben Sie die Länge der Positionsnummer ein",
    "778": "Positionsstückzahl Start",
    "779": "Positionsstückzahl Start",
    "780": "Länge Positionsstückzahl",
    "781": "Bitte  geben Sie die Länge der Positionsstückzahl eingeben",
    "782": "Sicherheitsstufe",
    "783": "Barcode-Füllzeichen",
    "784": "Bitte Barcode-Füllzeichen einfügen",
    "785": "Zurück zu Auftragsübersicht",
    "786": "(nur lesend)",
    "787": "eingeschränkter Zugang",
    "788": "Zugang nur lesend",
    "789": "Eigene Landingpage",
    "790": "Bitte Verbindung eintragen",
    "791": "Datenbank Verbindung",
    "792": "STD Datenbank",
    "793": "BWG Datenbank",
    "794": "Datenbank Verbindung",
    "795": "Chat",
    "796": "Nachricht ",
    "797": "CE-Dokument",
    "798": "Auftragsdokumente",
    "799": "Letzte Reparatur",
    "800": "Abnahme abgeschlossen",
    "801": "Update verfügbar, bitte APP neu starten",
    "802": "Externer Link",
    "803": "Präfix Externer Link",
    "804": "Projektreferenz",
    "805": "Aufgabenintervall",
    "806": "Dashboard Nutzer",
    "807": "App Nutzer",
    "808": "Letzter Upload",
    "809": "Minuten",
    "810": "Version des Matchers",
    "811": "Version des Importeurs",
    "812": "Abgemeldeter Nutzer",
    "813": "Nutzer erfolgreich abgemeldet",
    "814": "Barcode geändert",
    "815": "Aktualisierte Informationen",
    "816": "Route",
    "817": "APP installieren",
    "818": "Installieren Sie die Window.ID APP durch Scannen des QR-Codes",
    "819": "gematched",
    "820": "nicht gematched",
    "821": "Wartungsplaner",
    "822": "Anzahl der Tage",
    "823": "Einstellungen Wartungsplan",
    "824": "Tage",
    "825": "Nächste Wartung",
    "826": "Bild ändern",
    "827": "Filter löschen",
    "828": "Auftrag hinzufügen",
    "829": "Neuer Auftrag",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Window.ID suchen",
    "853": "Window.ID suchen",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera"
};

export default de;