const lv = {
    "1": "Jā",
    "2": "Nē",
    "3": "30-dienu testa versija",
    "4": "Programmā saņemta informācija par reklamāciju",
    "5": "Pieņemt",
    "6": "Saņemšanas datums",
    "7": "Aktivitāte",
    "8": "Aktīvs",
    "9": "Aktivitātes",
    "10": "Pievienot",
    "11": "Pievienot montāžnieku",
    "12": "Pievienot uzņēmumu",
    "13": "Pievienot dīleri",
    "14": "Pievienot lietotāju",
    "15": "Pievienot apsaimniekotāju",
    "16": "Pievienot lietotāju",
    "17": "Lauks brīvai ievadei",
    "18": "Papildus lauku nosaukumu maiņa",
    "19": "Papildinfo",
    "20": "Papildus informācija",
    "21": "Papildus produkti",
    "22": "Regulēšana",
    "23": "Administrators",
    "24": "Administratora skats",
    "25": "Visi elementi piegādati būvlaukumā?",
    "26": "Visas pozīcijas iemontētas",
    "27": "Tiesības aizsargātas",
    "28": "Vai tiešām vēlaties firmu dzēst?",
    "29": "Vai tiešām vēlaties dzēst lietotāju?",
    "30": "Iemontēts",
    "31": "Montāžnieks",
    "32": "Montāžnieki",
    "33": "Montāža",
    "34": "Montāža pabeigta",
    "35": "Montāžas datums",
    "36": "Projekti",
    "37": "Kopšanas instrukcija",
    "38": "Sertifikāts detalizēti",
    "39": "Sertifikāts(i)",
    "40": "Tīrīšana",
    "41": "Filtra tīrīšana",
    "42": "Aizvērt",
    "43": "Krāsa/dekors",
    "44": "Krāsa/dekors",
    "45": "Komisija",
    "46": "Uzņēmums",
    "47": "Uzņēmuma detaļas",
    "48": "Informācija par kompāniju",
    "49": "Kompasa virziens",
    "50": "Reklamācija",
    "51": "Noslēgt projektu",
    "52": "Noslēgts",
    "53": "Savienotie logi",
    "54": "Būvobjekts sakopts",
    "55": "Izveides datums",
    "56": "Beigu klients",
    "57": "Klienta numurs",
    "58": "Klienta pozīcijas",
    "59": "Datums",
    "60": "Datumu intervāls",
    "61": "Dīleris",
    "62": "Dzēst uzņēmumu",
    "63": "Dzēst ražotāju",
    "64": "Dzēst lietotāju",
    "65": "Piegādes datums",
    "66": "Piegādāts bez bojājumiem",
    "67": "Apraksts",
    "68": "Uzstādīšanas vietas apzīmējums",
    "69": "Parādīt_Izvēlnes_ierakstus pa lapā",
    "70": "Pārdošana",
    "71": "Dokumenti",
    "72": "Austrumi",
    "73": "Mainīt montāžnieka datus",
    "74": "Veikt izmaiņas uzņēmumā",
    "75": "Mainīt ražotāja datus",
    "76": "Mainīt apsaimniekotāja datus",
    "77": "Veikt izmaiņas lietotajā",
    "78": "Elementa platums x augstums",
    "79": "Elements ar šādu ID nav atrasts",
    "80": "Elements",
    "81": "E-pasta dati saglabāti",
    "82": "Norādiet krāsu/dekoru",
    "83": "Ievadiet komisiju",
    "84": "Norādiet uzņēmuma nosaukumu",
    "85": "Norādiet e-pastu",
    "86": "Ievadiet paplašinajumu",
    "87": "Ievadiet augstumu",
    "88": "ievadiet IP-adresi",
    "89": "Ievadiet vārdu",
    "90": "Ievadiet numuru",
    "91": "Ievadiet pozīciju skaitu",
    "92": "Ievadiet paroli",
    "93": "Ievadiet telefonu",
    "94": "Norādiet telefona numuru",
    "95": "Norādiet pilsētu",
    "96": "Ievadiet daudzumu",
    "97": "Norādiet ielu",
    "98": "Ievadiet vārdu",
    "99": "Ievadiet lietotāju",
    "100": "Ievadiet PVN numuru",
    "101": "Norādiet WEB lapu",
    "102": "Ievadiet platumu",
    "103": "Kļūda saglabājot datus uz servera ",
    "104": "Kļūda saglabājot e-pasta pieejas datus",
    "105": "Nomainiet piederumus",
    "106": "beidzies",
    "107": "beidzas",
    "108": "Paplašinājums",
    "109": "Ražotāji",
    "110": "Ražotne",
    "111": "Filtrs",
    "112": "atlasīts no _MAX_ ierakstiem",
    "113": "Pirmais",
    "114": "Atcelt",
    "115": "Furnitūras dati",
    "116": "Moskītu siets",
    "117": "Nospiedums",
    "118": "Komentārs",
    "119": "no App",
    "120": "no pārvaldes skata",
    "121": "Stikla tīrītājs",
    "122": "Stikla nomaiņa",
    "123": "Stikla struktūra",
    "124": "Stikla platums x augstums mm",
    "125": "Furnitūras apkope",
    "126": "Furnitūras nomaiņa",
    "127": "Augstums",
    "128": "Norādījumi tīrīšanai",
    "129": "Attēls",
    "130": "Informācija par elementu",
    "131": "Informācija ir ierobežota",
    "132": "Ievade",
    "134": "Iebūves vieta",
    "135": "ievadīt svītru kodu",
    "136": "IP-adrese",
    "137": "ir nepieciešams",
    "138": "Valoda",
    "139": "Pēdējais",
    "140": "Pēdējā apkope",
    "141": "Pēdējā reklamācija",
    "142": "Pēdējais serviss",
    "143": "Saite uz artikulu",
    "144": "tiek ielādēts",
    "145": "Visi elementi kā specificēti?",
    "146": "Izeja",
    "147": "E-pasta pieejas informācija",
    "148": "Kopšana ",
    "149": "Manuālas izmaiņas veiktas",
    "150": "Izgatavots",
    "151": "Mastercontroller forma",
    "152": "Mastercontroller numurs",
    "153": "Mastercontroller sērijas numurs",
    "154": "Reģistrācijas datums",
    "155": "Izvēlnes redzamā daļa",
    "156": "Autom. svītru kods pieņ.",
    "157": "Vairāk info par REHAU profilu ilgtspēju",
    "158": "Jauns pārbaudes laiks",
    "159": "Nākamais",
    "160": "Elements nav bojāts",
    "161": "Stiklojums nav bojāts",
    "162": "Piekrītošs ieraksts nav atrasti",
    "163": "Neviens no elementiem nav iemontēts",
    "164": "Ziemeļi",
    "165": "Nav montēts",
    "166": "Neko netrada - piedodiet",
    "167": "Nummurs",
    "168": "Elementu skaits",
    "169": "Projekta pārskats",
    "170": "Pozīciju skaits",
    "171": "uz",
    "172": "Pasūtījuma datums",
    "173": "Pasūtījuma numurs",
    "174": "Pasūtījums procesā",
    "175": "Izeja",
    "176": "Parole",
    "177": "Telefons",
    "178": "Pilsēta",
    "179": "PVC tīrītājs",
    "180": "Dokuments poz.",
    "181": "Pozīcijas detaļas",
    "182": "Pozīcija ar šādu ID nav atrasta",
    "183": "Pozīcijas",
    "184": "Iepriekšējais",
    "185": "Pieteikšanās informācija",
    "186": "Pieejas datus izdrukāt",
    "187": "Procesā",
    "188": "Ražotājs",
    "189": "Profilu sistēma",
    "190": "Projekts",
    "191": "Projekta numurs",
    "192": "Īpašuma pārvaldītājs",
    "193": "Daudzums",
    "194": "Reklamācija",
    "195": "Lūdzu izvēlēties staciju",
    "196": "Recycling daļa",
    "197": "Remonts",
    "198": "Piederumu remonts",
    "199": "Virsmas remonts",
    "200": "No App ziņots",
    "201": "no pārvaldes skata ziņots",
    "202": "Atsākt pārbaudes laiku",
    "203": "Rezultāts",
    "204": "Loma",
    "205": "Saglabāt",
    "206": "Veiksmīgi saglabāts",
    "207": "Nomainīt blīvējumus",
    "208": "Meklē",
    "209": "Drošības līmenis",
    "210": "Sūtīt datus uz REHAU",
    "211": "Serviss",
    "212": "Pārdošana un serviss",
    "213": "Pārvaldnieks",
    "214": "Iestatījumi",
    "215": "Veikt manuālu pasūtījuma pieņemšanu?",
    "216": "Parāda _START_ līdz _END_ no _TOTAL_ ierakstiem",
    "217": "Parāda 0 no 0 līdz 0 ierakstiem",
    "218": "Žalūzijas",
    "219": "Ieiet",
    "220": "Ieiet lai sāktu darbu",
    "221": "Informācija par viedo glass",
    "222": "Viedais Window",
    "223": "Daži elementi iemontēti",
    "224": "Daži elementi neatbilst",
    "225": "Dažas pozīcijas jāiemontē",
    "226": "Dienvidi",
    "227": "Statistika",
    "228": "Iela",
    "229": "Uzvārds",
    "230": "Ilgtspēja",
    "231": "Telefons",
    "232": "Pārbaudes laiks atsākts",
    "233": "Pārbaudes laiks ",
    "234": "Īpašības tiks saglabātas",
    "235": "Ir reklamācijas",
    "236": "Uzņēmuma ar šādu ID nav",
    "237": "Par šo elementu nav informācijas",
    "238": "Šāds uzņēmums jau eksistē",
    "239": "Nosaukums",
    "240": "līdz",
    "241": "Pieņemšana kopumā",
    "242": "Kopējais skaits",
    "243": "Nezināms",
    "244": "Logo augšupielādēt",
    "245": "Lietotāju administrēšana",
    "246": "Lietotāja vārds",
    "247": "Lietotāja vārds jau izmantots",
    "248": "Lietotāji",
    "249": "U-vērtība",
    "250": "U-vērtība profiliem",
    "251": "U-vērtība stiklojumam",
    "252": "W.ID piešķirts",
    "253": "Brīdinājums nesabojāt WindowsID",
    "254": "Laipni lūdzam",
    "255": "Rietumi",
    "256": "Platums",
    "257": "Platums x Augstums mm",
    "258": "Loga laukums no",
    "259": "Loga laukums",
    "260": "Loga elektroniskais numurs",
    "261": "Loga elektroniskais sērijas numurs",
    "262": "Loga montāžas vietas apzīmējums",
    "263": "Profilu sistēma",
    "264": "Loga tips",
    "265": "Vērtnes maiņa",
    "266": "Esiet pārliecināti?",
    "267": "Filtru dzēst",
    "268": "Mainīt",
    "269": "CSV eksportēt",
    "270": "Svītru koda lasītājs",
    "271": "Stikla -ID",
    "272": "Informācija",
    "273": "Saite  ",
    "274": "Saistīti lietotāji",
    "275": "E-pasts",
    "276": "E-pasta iestatījumi",
    "277": "E-pasta pārbaudes periods",
    "278": "Servisa nosaukums",
    "279": "Servisa kompānija",
    "280": "Servisa telefons",
    "281": "Servisa e-pasts",
    "282": "Statuss",
    "283": "Stacijas iestatījumi",
    "284": "Tēma",
    "285": "Telefons",
    "286": "Lietotājs",
    "287": "Web lapa",
    "288": "Windows ID",
    "289": "Logu sistēma",
    "290": "Klienta dati",
    "291": "Vārds",
    "292": "Stikla uzbūve",
    "293": "Furnitūras dati",
    "294": "Montāžnieka nosaukums",
    "295": "Montāžas uzņēmums",
    "296": "Montāžnieka telefons",
    "297": "E-pasts montāžas uzņēmumam",
    "298": "URL",
    "299": "Valsts",
    "300": "Pievienojiet lietotāju šim uzņēmumam",
    "301": "Papildus izvēlne",
    "302": "Pārskats",
    "303": "App",
    "304": "Sākumlapas iestatījumi",
    "305": "Lietotāju administrēšana",
    "306": "Apstiprināt",
    "307": "Ķermenis",
    "308": "Pielikums",
    "309": "ID",
    "310": "Pozīcija",
    "311": "Brīdinājums  ",
    "312": "W.ID  ",
    "314": "Dīleris",
    "315": "Aktivēt",
    "316": "Deaktivēt",
    "317": "Uzņēmums",
    "318": "Piedodiet",
    "319": "Administrators",
    "320": "Lietotājs",
    "321": "Pasta indekss",
    "322": "Logo",
    "323": "Fona attēls",
    "324": "PVN nr.",
    "325": "CE Documents",
    "326": "Programmas iestatījumi",
    "327": "Automātiski",
    "328": "Manuāli",
    "329": "Pārlūkot",
    "330": "Augšupielādēt",
    "331": "Dokumenta auto augšuplāde",
    "332": "Izejas mape",
    "333": "Izvēlies mapi augšupielādei",
    "334": "Izvēlies .zip failu saturošu PPS XML failu",
    "335": "Stacija",
    "336": "Maunāli skanēt",
    "337": "Viedais glass Stacija",
    "338": "Informācijas pārnese",
    "339": "Max skaits sadaļu pāskatā",
    "340": "QR-kods no ",
    "341": "QR-kods uz",
    "342": "QR-kods prefix",
    "343": "QR-kods garums",
    "344": "Svītru koda dati nederīgi",
    "345": "Ai atslēga",
    "346": "ievadīt QR-kodu",
    "347": "Stacijas nosaukums",
    "348": "Fails nav izvēlēts",
    "349": "Fails augšupielādēts",
    "350": "Faila augsupielādē kļūda",
    "351": "Manuāla skanēšana iedarbināta",
    "352": "Ievadiet QR kodu",
    "353": "Svītru kods nederīgs",
    "354": "Svītru kods ir importēts",
    "356": "Nav datu",
    "357": "Savienojums nesanāca",
    "358": "Vēsture",
    "359": "Svītru kods jau izmantots",
    "360": "Kļūda piešķirot svītru kodu",
    "361": "Manuāli",
    "362": "No ",
    "363": "Līdz",
    "364": "QR-koda prefix garumam jābūt īsākam kā QR-koda garumam",
    "365": "Svītru koda diapazons nederīgs",
    "366": "Nederīgi numuri",
    "367": "Jau izmantots",
    "368": "Izmanots šodien",
    "369": "Brīvs",
    "370": "Izmantots līdz šodienai",
    "371": "QR-koda numura izmantošana šīs stacijas diapazonam",
    "372": "Tulkojumi",
    "373": "Rediģēt tulkojumu",
    "374": "Atslēga",
    "375": "Anglu",
    "376": "Vertība",
    "377": "ID piešķirt",
    "378": "Logu un durvju pozīcijas būvprojektā",
    "379": "Labošana / apliecinājums labošanai",
    "380": "Apkope un apkopes vēsture",
    "381": "Montāžas protokols (akts)",
    "382": "Modificēšana",
    "383": "Nomainīt stiklu",
    "384": "Nomainīt vērtni",
    "386": "Teksta ievade",
    "387": "Izvēle tiks saglabāta",
    "388": "Janas iespējas",
    "389": "Apkopes log",
    "390": "Matēts stikls",
    "391": "Noteikt apkopes termiņu",
    "392": "Uz pilnu sarakstu BV",
    "393": "Loga info",
    "394": "Montāžas detaļas",
    "395": "Krāsa",
    "396": "Elementa platums x augstums mm",
    "397": "Stikla platums x augstums mm",
    "398": "Furnitūras info",
    "399": "Montētaja nosaukums",
    "400": "Montāžas datums un laiks",
    "401": "Montāžas vieta (GPS)",
    "402": "IerīcesID JustSmart",
    "403": "sūtīt",
    "404": "Filtrēt pozīcijas",
    "405": "Neviens  ",
    "406": "Balkona durvis",
    "407": "Virtuve",
    "408": "Vannas istaba",
    "409": "Istaba",
    "410": "Jumts",
    "411": "Montāža",
    "412": "Grīda",
    "413": "Pievienot attēlu",
    "414": "Paraksts",
    "415": "Nodzēs zīmējumu",
    "416": "Logs bez bojājumiem",
    "417": "Stikls bez bojājumiem",
    "418": "Atvērt kameru",
    "419": "Augšupielādēt esošo attēlu",
    "420": "Instalēt & piešķirt logu",
    "421": "Pieņemšanas atskaite",
    "422": "Sūtīt",
    "423": "Viss būvprojekts",
    "424": "Montāžas lenta",
    "425": "Tikai elementam",
    "426": "Nobeigt darbu kopumā",
    "427": "Atsevišķu logu montāžas protokols",
    "428": "Ir problēmas",
    "429": "Pieprasīt palīdzību",
    "430": "Ziņa",
    "431": "Iesniegt",
    "432": "Nākamais elements",
    "433": "Visu elementu saraksts",
    "434": "Būvniecības grafiks",
    "435": "caur pasūtījuma numuru",
    "436": "caur QR koda skaneri",
    "437": "parādīt atsevišķi",
    "438": "vai",
    "439": "Svītru koda numurs",
    "440": "Parādīt informāciju",
    "441": "Labošana / reklamācija",
    "442": "Parādīt kontaktpersonu",
    "443": "Atsauksme",
    "444": "Skanēt citu QR-kodu",
    "445": "Nākamā apkope",
    "446": "Logu piegādātājs",
    "447": "Piedāvājums",
    "448": "Skaņas izolācijas vērtība",
    "449": "Uf",
    "450": "Retrofit risinājumi / papildinājumu iespējas",
    "451": "Kontakts",
    "452": "Jūsu Window ID",
    "453": "Ko vēlaties darīt ?",
    "454": "Regulāra logu apkope pagarina kalpošanas laiku un komfortu",
    "455": "Pieprasiet apkopi, izmantojot saziņas veidlapu",
    "456": "Tehniskās apkopes laiku pieteikt pa tālr.:",
    "457": "savam logu meistaram",
    "458": "Ar šo es apstiprinu noteikumus un nosacījumus, kā arī VDAR regulu",
    "459": "Jūsu rūpes",
    "461": "Sazinieties, izmantojot...",
    "462": "Saplīsis stikls",
    "463": "Logs neaizveras pareizi",
    "464": "Logs ir iestrēdzis",
    "465": "Logs bojāts",
    "466": "Citi aksesuāri",
    "467": "Jūsu logi un durvis bija",
    "468": "montējis šis uzņēmums",
    "469": "Logu izgatavotājs",
    "470": "Novērtējiet savus logus un amatnieku uzņēmumu tūlīt",
    "471": "Cik apmierināts esat ar uzņēmuma pakalpojumu",
    "472": "Vai piegādātās preces ir kārtībā?",
    "473": "Kā jums patīk jūsu logi?",
    "474": "Bija problēmas ar apstrādi",
    "475": "Pozitīvi",
    "476": "Ko jūs uzlabotu?",
    "477": "Montāžas instrukcijas",
    "478": "Logu un durvju novietojums",
    "479": "Atveriet montāžas atskaiti",
    "480": "Pilns elementu saraksts, pasūtījuma numurs",
    "481": "Elementu saraksts, izmantojot pasūtījuma numuru / QR",
    "482": "Nosūtīt projekta žurnālu",
    "483": "Logu detaļas",
    "484": "Atsevišķas detaļas",
    "485": "Būvprojekta numurs",
    "486": "Logs",
    "487": "Logu pozīcija",
    "488": "Remonta serviss",
    "489": "Apkopes detaļas",
    "490": "Klients",
    "491": "Saziņas veidlapa",
    "492": "Atrodiet REHAU partneri tuvumā",
    "493": "Kategorija",
    "494": "QR skenēšana",
    "495": "Kontaktpersona",
    "496": "Atjaunināt",
    "497": "filtri",
    "498": "Montāža pabeigta",
    "499": "Montāža pabeigta?",
    "500": "Piegāde bez bojājumiem",
    "501": "Saglabāt kā PDF",
    "502": "Uzņemt bildi",
    "503": "Nav derīga e-pasta adrese",
    "504": "Instalējiet lietotni",
    "505": "Vieta",
    "506": "Projektu saraksts",
    "507": "Vai ielādēt izvēlēto pasūtījumu?",
    "508": "Nepareizs pasūtījuma numurs/svītrkods",
    "509": "Atsevišķs logs",
    "510": "Ievadiet izmaiņas",
    "511": "Nosūtiet REHAU ziņojumu pa e-pastu",
    "512": "Lūdzu, izvēlieties 4 iespējas",
    "513": "Tagad skenējiet loga QR kodu, lai uzzinātu, kurā ēkas vietā šis elements ir jāuzstāda.",
    "514": "Saglabāt failu",
    "515": "Rādīt",
    "516": "rezultātus",
    "517": "lappuse",
    "518": "no ",
    "519": "Datums no",
    "520": "Datums līdz",
    "521": "atvērts",
    "522": "Izmērs",
    "523": "Uzstādīts uz",
    "524": "Paveikts",
    "525": "Informācija",
    "526": "Jauns pasūtījums ielādēts",
    "527": "Montētāja ID",
    "528": "Pieslēgties",
    "529": "Ar šo es apstiprinu vispārīgos noteikumus un nosacījumus un VDAR regulu",
    "530": "Izlogoties",
    "531": "skeneris",
    "532": "Nosūtiet protokolu",
    "533": "Nosūtīt protokolu administrācijai?",
    "534": "Remonta žurnāls",
    "535": "5. variants",
    "536": "6. variants",
    "537": "7. variants",
    "538": "8. variants",
    "539": "9. variants",
    "540": "10. variants",
    "541": "Epasts nosūtīts.",
    "542": "E-pasta nosūtīšana neizdevās.",
    "543": "Eļļas uzsmidzināšana",
    "544": "Pielāgots",
    "545": "Iztīrīts",
    "546": "Noblīvēts",
    "547": "Mainīti piederumus",
    "548": "komentēt",
    "549": "Visi elementi tiek piegādāti būvlaukumā",
    "550": "Elementu izvietojums būvprojekta ietvaros",
    "551": "Protokola nosūtīšana neizdevās!",
    "552": "Saglabājiet protokola informāciju",
    "553": "Informācija saglabāta.",
    "554": "Neizdevās saglabāt datus.",
    "555": "Informācija par viedo logu",
    "556": "Viedo logu pieņemšana",
    "557": "Logu elektronikas materiāla numurs",
    "558": "Logu elektronikas sērijas numurs",
    "559": "Skenējiet QR kodu (DMC) no galvenā kontrollera vai ievadiet to manuāli",
    "560": "Viedais Window pilnībā konfigurēts un funkcionāls",
    "561": "Visi redzamie elementi bez bojājumiem/defektiem",
    "562": "Vai komutācijas sistēma ir pievienota 0-10V (0-100%)?",
    "563": "Uzstādīšanas vietas ēka",
    "564": "Sāciet skenēšanu",
    "565": "Pārtraukt skenēšanu",
    "566": "Pašlaik atlasītais pozīcijas elements",
    "567": "Vienlaikus var atlasīt ne vairāk kā 4 atribūtus.",
    "568": "Klienta poz",
    "569": "Ražošanas datums",
    "570": "Window.ID maiņa",
    "571": "Iepriekšējais Window.ID",
    "572": "Jauns Window.ID",
    "573": "Pasūtījuma numurs nepastāv vai jauns pasūtījuma numurs jau tiek izmantots.",
    "574": "Svītrkods",
    "575": "Pozīcijas ID",
    "576": "Tips",
    "577": "Elementa ID",
    "578": "Elementa informācija",
    "579": "Window.ID",
    "580": "Lūdzu, parakstieties norādītajā vietā",
    "581": "Pabeigts",
    "582": "Parakstīties",
    "583": "Jauna valoda",
    "584": "Oriģinālvaloda",
    "585": "Valodas nosaukums",
    "586": "Izveidojiet jaunu valodu",
    "587": "Pievienojiet valodu",
    "588": "Atjaunināt",
    "589": "notiek atjaunināšana",
    "590": "Uzņēmuma deaktivizēšana",
    "591": "Uzņēmuma aktivizēšana",
    "592": "Vai tiešām vēlaties deaktivizēt šo uzņēmumu?",
    "593": "Vai tiešām vēlaties aktivizēt šo uzņēmumu?",
    "594": "Lietotāja deaktivizēšana",
    "595": "Lietotāja aktivizēšana",
    "596": "Vai tiešām vēlaties deaktivizēt šo lietotāju?",
    "597": "Vai tiešām vēlaties aktivizēt šo lietotāju?",
    "598": "ID pozīcija",
    "599": "Visi",
    "600": "Imports/eksports",
    "601": "Lūdzu, izvēlieties .csv valodas failu importēšanai",
    "602": "Importēt",
    "603": "Eksportēt",
    "604": "Fails",
    "605": "Tulkotājs",
    "606": "Svītrkoda faili",
    "607": "Lūdzu, izvēlieties svītrkoda failu mapes ceļu.",
    "608": "Window_ID",
    "609": "Profilu sistēma",
    "610": "Klienta poz",
    "611": "Krāsa",
    "612": "Elementa izmērs",
    "613": "Stikla izmērs",
    "614": "Stiklojuma uzbūve",
    "615": "Furnitūras informācija",
    "616": "ID piešķiršanas datums",
    "617": "Montāžas datums/laiks",
    "618": "GPS montāžas vieta",
    "619": "Nr",
    "620": "Montāža:",
    "621": "Stāvs:",
    "622": "Poz.:",
    "623": "Ignorēt pirmos simbolu(s)",
    "624": "Ignorēt beigu simbolu(s)",
    "625": "Epasts-lietotāja aktivizēšana",
    "626": "Aktivēt kompānijas",
    "627": "Testa pieejas beigas",
    "628": "Vēlreiz aktivēšanas epastu",
    "629": "Dīleris/Ražotne jau eksistē",
    "630": "Lietotājs ir aktivēts",
    "631": "Ejiet uz Log-In lapu",
    "632": "Lietotaja detaļas",
    "633": "Pievienot saistīto lietotāju",
    "634": "Dzēst lietotāja saiti",
    "635": "Lietotājs jau savienots",
    "636": "Lietotāja vārds vai parole tukša",
    "637": "Lietotājs nav atrasts",
    "638": "Sūtīt protokolu menedžmentam",
    "639": "Salikšanas nobeiguma jautājums",
    "640": "Opcija 5",
    "641": "Opcija 6",
    "642": "Opcija 7",
    "643": "Opcija 8",
    "644": "Opcija 9",
    "645": "Opcija 10",
    "646": "Savienotājs",
    "647": "Tips",
    "648": "Datums",
    "649": "Apraksts",
    "650": "Furnitūras kopšana",
    "651": "Komplekts",
    "652": "Tīrīšana",
    "653": "Blīvējums",
    "654": "Piederumu nomaiņa",
    "655": "Komentārs",
    "656": "Savienojums",
    "657": "Profilu sistēma",
    "658": "Skaits",
    "659": "Pasūtījuma nr.",
    "660": "Pasūtījuma ID",
    "661": "Augstums",
    "662": "Platums",
    "663": "Furnitūra",
    "664": "Stiklojuma pl. x au.",
    "665": "Stiklojuma uzbūve",
    "666": "Window.ID",
    "667": "Window Electric ID",
    "668": "Stikla ID",
    "669": "Nosacījumi",
    "670": "Pievienot/noņemt",
    "671": "un/vai",
    "672": "Grupa",
    "673": "Kolonna",
    "674": "Kondīcija",
    "675": "Ievadiet klienta numuru",
    "676": "Tulkotājs",
    "678": "Programmas versijas kontrole",
    "679": "Atjauninajuma pabeigšanai varbūt vajadzīga vēl kāda minūte",
    "680": "Gaidām jauno versiju",
    "681": "Ir jauna programmas versija, vēlaties atjauninajumu?",
    "682": "Programma ir aktuāla, vēlaties atgriezt veco versiju?",
    "683": "Atjaunot veco versiju",
    "684": "Ievadiet stacijas vārdu",
    "685": "Pasūtījuma numura simbolu pild.",
    "686": "Lūdzu ievadiet pasūtījuma numura simbolu pild.",
    "687": "Pasūtījuma numura max garums",
    "688": "Lūdzu ievadiet pasūtījuma numura max garumu",
    "689": "MDF mapes ceļš",
    "690": "Lūdzu ievadiet .mdb datu ceļu (importētājs to lieto, kad skanē .mdb datus)",
    "691": "XML mapes ceļš",
    "692": "Lūdzu ievadiet .xml file ceļu (importētājs to lieto, kad skanē .xml datus)",
    "693": "Ignorē sākuma QR-Code simbolu(s)",
    "694": "Ignorē beigu QR-Code simbolu(s)",
    "695": "Nolūks",
    "696": "Garums",
    "697": "Papildus pozīcijas",
    "698": "Viedais logs bez bojājumiem",
    "699": "Viedais logs pievienots",
    "700": "Loga orientācija",
    "701": "Pozīcijas elementa numurs",
    "702": "Viedais logs konfigurēts",
    "703": "Elements bez bojājumiem",
    "704": "Materiāla numurs",
    "705": "Seriālais numurs",
    "706": "Attēls nav pieejams",
    "707": "Montāžas vieta tīra",
    "708": "Paņem un ievelc attēla/pdf datus te",
    "709": "Datu augšupielāde",
    "710": "Datu tips",
    "711": "Pasūtījums (visas pozīcijas)",
    "712": "Dzēsts",
    "713": "Piezīmes pievienotas",
    "714": "Augstums (mm)",
    "715": "Platums (mm)",
    "716": "Šai pozīcijai nav pildiņa",
    "717": "Vienība",
    "718": "Sadales stacija",
    "719": "Sadales zona",
    "720": "Window ID beidzamais",
    "721": "Stikls ir nomainīts",
    "722": "Piederumi ir salaboti",
    "723": "Vertne ir nomainīta",
    "724": "Virsma ir salabota",
    "725": "Piederumi ir salaboti",
    "726": "Labošana ir pabeigta",
    "727": "Apstiprini paroli",
    "728": "Nepareiza parole",
    "729": "Paroles nesakrīt",
    "730": "Parole nevar būt tukša",
    "731": "lokācija",
    "732": "Laika jsola",
    "733": "Uw (W/m²K)",
    "734": "Speciāls stikls",
    "735": "Pozīcijas nr.",
    "736": "Izgatavošanas progress",
    "737": "Izgatavošana pabeigta",
    "738": "Montāžas progress",
    "739": "Montāža pabeigta",
    "740": "Stacija dzēsta",
    "741": "Tiešām vēlaties dzēst staciju?",
    "742": "Pievienot jaunu staciju",
    "743": "Dzēst pasūtījumu",
    "744": "Pasūtījuma dzēšana",
    "745": "Vai esat pārliecināts, ka vēlaties dzēst pasūtījumu?",
    "746": "Lietotājs ir pievienojis",
    "747": "Lietotājs rediģēja",
    "748": "Aizmirsāt paroli?",
    "749": "Lauks Parole ir obligāts",
    "750": "Lauks Lietotāja vārds ir obligāts",
    "751": "Šī lietotāja loma nav autorizēta pieejai panelim",
    "752": "Lietotājs nav aktīvs",
    "753": "Lietotāja uzņēmums nav aktīvs vai testa periods ir beidzies",
    "754": "Lietotājvārds vai parole ir nepareiza",
    "755": "Pasta indekss",
    "756": "Izmanto rulklēšanu attēla tuvināšanai",
    "757": "Atjauno paroli",
    "758": "Epasts paroles atjaunošanai ir nosūtīts",
    "759": "Parole ir atjaunota",
    "760": "Epasts - paroles atjaunošana",
    "761": "Elementa Nr",
    "762": "Pasūtījuma numurs no",
    "763": "Ievadiet pasūtījuma numuru no",
    "764": "Pasūtījuma numura garums",
    "765": "Ievadiet pasūtījuma numura garumu",
    "766": "Pasūtījumu diapazons no",
    "767": "Ievadiet pasūtījuma diapazonu no",
    "768": "Pasūtījumu diapazona garums",
    "769": "Ievadiet pasūtījumu diapazona garumu",
    "770": "Please insert order piece from",
    "772": "Pasūtīt vienības garumu",
    "773": "Ievadiet pasūtījuma vienības garumu",
    "774": "Pozīcijas numurs no",
    "775": "Ievadiet pozīcijas numuru no",
    "776": "Pozīcijas numura garums",
    "777": "Ievadiet pozīcijas numura garumu",
    "778": "Pozīcijas gabals no",
    "779": "Ievadiet pozīcijas gabalu no",
    "780": "Pozīcijas gabala garums",
    "781": "Ievadiet pozīcijas gabala garumu",
    "782": "Drošības līmenis",
    "783": "Svītrkoda aizpildījuma raksturs",
    "784": "Lūdzu, ievietojiet svītrkoda aizpildījuma rakstzīmi",
    "785": "Atpakaļ uz projektiem",
    "786": "(tikai lasīt)",
    "787": "Ierobežota piekļuve",
    "788": "tikai lasīšanas piekļuve",
    "789": "sava izkraušanas lapa",
    "790": "Lūdzu, ievietojiet savienojuma virkni",
    "791": "DB savienojuma virkne",
    "792": "DB STD savienojuma virkne",
    "793": "DB BWG savienojuma virkne",
    "794": "Datu bāzes savienojums",
    "795": "Čats",
    "796": "Ziņojumi",
    "797": "CE dokuments",
    "798": "Pasūtījuma dokuments",
    "799": "Pēdējais remonts",
    "800": "Pieņemšana pabeigta",
    "801": "Ir pieejams jauns atjauninājums, lūdzu, restartējiet lietojumprogrammu",
    "802": "Ārējā saite",
    "803": "Ārējās saites prefikss",
    "804": "Project reference",
    "805": "Task interval",
    "806": "Dashboard users",
    "807": "App users",
    "808": "Last upload",
    "809": "minutes",
    "810": "Matcher version",
    "811": "Importer version",
    "812": "Logout user",
    "813": "User logged out successfully",
    "814": "Barcode changed",
    "815": "Updated information",
    "816": "Directions",
    "817": "Install APP",
    "818": "Install the Window.ID APP by scanning the QR code",
    "819": "matched",
    "820": "not matched",
    "821": "Planner",
    "822": "Number of days",
    "823": "Settings for the maintenance reminder.",
    "824": "days",
    "825": "Next maintenance",
    "826": "Edit image",
    "827": "No filter",
    "828": "Add order",
    "829": "New order",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera"
};

export default lv;