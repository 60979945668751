const fr = {
    "1": "Oui",
    "2": "Non",
    "3": "Version d'essai de 30 jours",
    "4": "Une réclamation a été envoyée via l'application",
    "5": "Pose",
    "6": "Date de réception des travaux",
    "7": "Action",
    "8": "Actif",
    "9": "Activités",
    "10": "Ajouter",
    "11": "Ajouter un poseur",
    "12": "Ajouter une entreprise",
    "13": "Ajouter un revendeur",
    "14": "Ajouter un fabricant",
    "15": "Ajouter gestion immobilière",
    "16": "Ajouter un utilisateur",
    "17": "Liens supplémentaires",
    "18": "Modifier le nom du champ supplémentaire",
    "19": "Information supplémentaire",
    "20": "Autres informations",
    "21": "Produits supplémentaires",
    "22": "Réglage",
    "23": "Admin",
    "24": "Tableau de bord",
    "25": "Tous les éléments sont livrés sur le chantier",
    "26": "Tous les éléments sont posés",
    "27": "Tous droits réservés.",
    "28": "Êtes-vous sûr de vouloir supprimer ce fabricant?",
    "29": "Etes-vous sûr de vouloir supprimer cet utilisateur ?",
    "30": "Posé",
    "31": "Revendeur/poseur",
    "32": "Poseurs",
    "33": "Assemblé",
    "34": "Installation terminée",
    "35": "Date de l'installation",
    "36": "Projets",
    "37": "Instructions d'entretien",
    "38": "Détails du certificat",
    "39": "Certificat(s)",
    "40": "Nettoyage",
    "41": "Supprimer ",
    "42": "Fermer",
    "43": "Couleur",
    "44": "Couleur Int./Ext.",
    "45": "Commission",
    "46": "Société",
    "47": "Détails de la société",
    "48": "Informations sur l'entreprise",
    "49": "Orientation",
    "50": "Réclamation",
    "51": "Projet terminé",
    "52": "Terminé",
    "53": "Fenêtre connectée",
    "54": "Chantier propre",
    "55": "Date de création",
    "56": "Client",
    "57": "Numéro de client",
    "58": "Position",
    "59": "Date",
    "60": "Date",
    "61": "Revendeur/poseur",
    "62": "Supprimer l'entreprise",
    "63": "Supprimer le fabricant",
    "64": "Supprimer l'utilisateur",
    "65": "Date de livraison",
    "66": "Livraison sans dégâts",
    "67": "Description",
    "68": "Designation du lieu de l'installation",
    "69": "Afficher _MENU_ enregistrements par page",
    "70": "Distribution",
    "71": "Documents",
    "72": "Est",
    "73": "Modifier le poseur",
    "74": "Modifier la société",
    "75": "Modifier le fabricant",
    "76": "Modifier gestion immobilière",
    "77": "Modifier l'utilisateur",
    "78": "Largeur x hauteur de l'élément en mm",
    "79": "L'élément de cet ID n'existe pas",
    "80": "Éléments",
    "81": "Données d'email sauvegardées.",
    "82": "Saisir la couleur",
    "83": "Saisir la commission",
    "84": "Saisir le nom de l'entreprise",
    "85": "Saisir l'email",
    "86": "Saisir une extension",
    "87": "Saisir la hauteur",
    "88": "Saisir l'adresse IP",
    "89": "Saisir le prénom",
    "90": "Saisir le numéro",
    "91": "Saisir le nombre de positions",
    "92": "Saisir le mot de passe",
    "93": "Saisir le numéro de téléphone",
    "94": "Saisir le numéro de téléphone",
    "95": "Saisir le code postal",
    "96": "Saisir la quantité",
    "97": "Saisir la rue",
    "98": "Saisir le nom de famille",
    "99": "Saisir le nom d'utilisateur",
    "100": "Saisir le numéro de TVA",
    "101": "Saisir le site internet",
    "102": "Saisir la largeur",
    "103": "Erreur lors de la sauvegarde des données sur le serveur.",
    "104": "Erreur lors de l'enregistrement des données de l'e-mail.",
    "105": "Remplacement d'accessoires",
    "106": "expiré le",
    "107": "expire à",
    "108": "Extension",
    "109": "Fabricant",
    "110": "Usine",
    "111": "Filtre",
    "112": "filtré à partir de _MAX_ enregistrements au total",
    "113": "Première",
    "114": "Annuler",
    "115": "Données d'ajustage",
    "116": "Moustiquaires",
    "117": "Empreinte",
    "118": "Texte libre",
    "119": "Depuis l'application",
    "120": "Depuis la vue d'administration",
    "121": "Nettoyant pour vitres",
    "122": "Remplacement du remplissage",
    "123": "Structure du verre",
    "124": "Largeur x hauteur du verre en mm",
    "125": "Entretien du matériel",
    "126": "Remplacement du matériel",
    "127": "Hauteur",
    "128": "Comment nettoyer",
    "129": "Image",
    "130": "Informations sur l'élément",
    "131": "L'information est limitée",
    "132": "Documents internes",
    "134": "Lieu d'installation",
    "135": "saisir le code-barres",
    "136": "Adresse IP",
    "137": "est obligatoire",
    "138": "Langue",
    "139": "Dernier",
    "140": "Dernière intervention",
    "141": "Dernière réclamation",
    "142": "Dernier service",
    "143": "Lien vers l'article",
    "144": "En cours de chargement",
    "145": "Emplacement des éléments dans le projet de construction",
    "146": "Déconnexion",
    "147": "Accès à la messagerie",
    "148": "Entretien",
    "149": "changement manuel par",
    "150": "Fabriqué",
    "151": "Design du contrôleur principal",
    "152": "Numéro d'article du contrôleur principal",
    "153": "Numéro de série du contrôleur principal",
    "154": "Date de liaison avec l'étiquette",
    "155": "Visibilité du menu",
    "156": "Confirmation automatique du code-barres",
    "157": "Documents externes",
    "158": "Nouvelle période d'essai",
    "159": "Suivant",
    "160": "Aucun signe de détérioration sur l'élément",
    "161": "Aucun signe de détérioration sur le verre",
    "162": "Aucun enregistrement correspondant trouvé",
    "163": "Aucun élément n est posé",
    "164": "Nord",
    "165": "Non installé",
    "166": "Aucun résultat",
    "167": "",
    "168": "Nombre d'éléments",
    "169": "Nombre d'éléments par période",
    "170": "Nombre de positions",
    "171": "sur",
    "172": "Date de la commande",
    "173": "Numéro de commande",
    "174": "Etat d'avancement ",
    "175": "Sortie",
    "176": "Mot de passe",
    "177": "Téléphone",
    "178": "Ville",
    "179": "Nettoyant plastique",
    "180": "Documents ",
    "181": "Détails des positions",
    "182": "La position avec cet ID n'existe pas",
    "183": "Positions",
    "184": "Précédent",
    "185": "Données d'identification de l'utilisateur principal",
    "186": "Imprimer les informations de connexion",
    "187": "En cours de traitement",
    "188": "Fabricant",
    "189": "Système de profilé",
    "190": "Projet",
    "191": "Numéro de commande",
    "192": "Gestion Immobilière",
    "193": "Quantité",
    "194": "Réclamation",
    "195": "Veuillez sélectionner la station",
    "196": "Contenu recyclé",
    "197": "SAV",
    "198": "Réglage d'accessoires",
    "199": "Retouche/nettoyage surface",
    "200": "Déclaré via app",
    "201": "Signalé via la vue d'administration",
    "202": "Réinitialisation de la période d'essai",
    "203": "Résultat",
    "204": "Rôle",
    "205": "Sauvegarder",
    "206": "Sauvegardé avec succès.",
    "207": "Contrôle d'étanchéité",
    "208": "Recherche",
    "209": "Niveau de sécurité",
    "210": "Envoyer les données à REHAU",
    "211": "Service",
    "212": "Service et ventes",
    "213": "Gestionnaire",
    "214": "Paramètres",
    "215": "Effectuer manuellement une réception globale de la commande?",
    "216": "Affichage de _START_ à _END_ éléments sur _TOTAL_",
    "217": "Affichage de 0 à 0 sur 0 éléments",
    "218": "Volets",
    "219": "S'identifier",
    "220": "Connectez-vous pour commencer votre session",
    "221": "Information vitrage Smart",
    "222": "Produit Smart",
    "223": "Certains éléments sont installés",
    "224": "Certains Window.ID ne sont pas liés",
    "225": "Certaines positions doivent être assemblées",
    "226": "Sud",
    "227": "Statistiques",
    "228": "Rue",
    "229": "Nom de famille",
    "230": "Développement durable",
    "231": "Téléphone",
    "232": "La période d'essai a été réinitialisée avec succès",
    "233": "période(s) de test",
    "234": "Les caractéristiques ont été conservées",
    "235": "Il y a des réclamations",
    "236": "Il n'y a pas de société avec cet identifiant",
    "237": "Il n'y a pas d'information sur cet élément",
    "238": "Cette entreprise existe déjà",
    "239": "Titre",
    "240": "à",
    "241": "Tout accepter",
    "242": "Nombre total",
    "243": "Inconnu",
    "244": "Télécharger le logo",
    "245": "Administration des utilisateurs",
    "246": "Nom d'utilisateur",
    "247": "Nom d'utilisateur déjà utilisé.",
    "248": "Utilisateurs",
    "249": "Valeur U",
    "250": "Valeur U du châssis",
    "251": "Valeur U du vitrage",
    "252": "W.ID attribué",
    "253": "Avertissement pour ne pas endommager Window.ID",
    "254": "Bienvenue sur le tableau de bord de Window.ID",
    "255": "Ouest",
    "256": "Largeur",
    "257": "Largeur x hauteur en mm",
    "258": "Surface de fenêtre de",
    "259": "Surface de fenêtre en",
    "260": "Numéro d'article de la fenêtre électronique",
    "261": "Numéro de série de l'électronique de la fenêtre",
    "262": "Lieu d'installation de la fenêtre",
    "263": "Système de fenêtre",
    "264": "Type de fenêtre",
    "265": "Remplacement du vantail",
    "266": "Vous êtes sûr(2,e) ?",
    "267": "Supprimer la signature",
    "268": "Modifier",
    "269": "Exporter CSV",
    "270": "Lecteur de code-barres",
    "271": "ID du verre",
    "272": "Informations",
    "273": "Lien",
    "274": "Utilisateur lié",
    "275": "E-mail",
    "276": "Paramètres de messagerie",
    "277": "Période d'essai de l'e-mail",
    "278": "Intitulé de l'ordre d'intervention",
    "279": "Société intervenante",
    "280": "Numéro de téléphone de l'intervenant",
    "281": "E-mail de l'intervenant",
    "282": "Statut",
    "283": "Paramètres de la station",
    "284": "Sujet",
    "285": "Téléphone",
    "286": "Utilisateur",
    "287": "Site web",
    "288": "Window ID",
    "289": "Système de fenêtre",
    "290": "Détails du client",
    "291": "Nom",
    "292": "Structure du verre",
    "293": "Détails du matériel",
    "294": "Intitulé de la pose",
    "295": "Société de pose",
    "296": "Téléphone poseur",
    "297": "E-mail poseur",
    "298": "URL",
    "299": "Pays",
    "300": "Veuillez ajouter des utilisateurs pour cette entreprise",
    "301": "Menu supplémentaire",
    "302": "Fabricant",
    "303": "App",
    "304": "Présentation de la page du client final",
    "305": "Gestion des utilisateurs",
    "306": "Confirmer",
    "307": "Corps",
    "308": "Annexe",
    "309": "Identifiant",
    "310": "Position",
    "311": "Avertissement",
    "312": "W.ID",
    "314": "Revendeur",
    "315": "Activer",
    "316": "Désactiver",
    "317": "Entreprise",
    "318": "Désolé",
    "319": "Administrateur",
    "320": "Utilisateur",
    "321": "Code postal",
    "322": "Logo",
    "323": "Image de fond",
    "324": "Numéro de TVA",
    "325": "NF Documents",
    "326": "Paramètres de l'application",
    "327": "Automatique",
    "328": "Manuel",
    "329": "Parcourir",
    "330": "Télécharger",
    "331": "Chargement automatique des documents",
    "332": "Dossier source",
    "333": "Veuillez choisir le chemin du dossier pour le téléchargement automatique des documents.",
    "334": "Veuillez sélectionner le fichier contenant les données Window.ID",
    "335": "Station",
    "336": "Scanner Window.ID manuellement",
    "337": "Station vitrage Smart",
    "338": "Transférer les informations",
    "339": "Nombre de positions dans la chronologie",
    "340": "QR-Code de",
    "341": "QR-Code vers",
    "342": "Préfixe du QR-Code",
    "343": "Longueur du QR-Code",
    "344": "Les données du code-barres ne sont pas valides.",
    "345": "Api clé",
    "346": "entrer qrcode",
    "347": "Nom de la Station",
    "348": "Fichier non sélectionné",
    "349": "Fichier téléchargé",
    "350": "Erreur de téléchargement de fichier",
    "351": "Le scan manuel est activé, le QR-Code ne sera pas vérifié !",
    "352": "Merci de renseinger le QR-code",
    "353": "Le code-barres n'est pas valide",
    "354": "Ce code-barres sera importé",
    "356": "Aucune donnée",
    "357": "Echec de la connexion",
    "358": "Historique",
    "359": "Le code-barres est en cours d'utilisation.",
    "360": "Erreur lors de la mise en correspondance des codes barres",
    "361": "Manuel",
    "362": "De",
    "363": "À",
    "364": "La longueur du préfixe du QR-Code doit être inférieure à la longueur du QR-Code",
    "365": "La plage de codes-barres n'est pas valide",
    "366": "Numéros non valides",
    "367": "Utilisé précédemment",
    "368": "Utilisé aujourd'hui",
    "369": "Gratuit",
    "370": "Utilisé jusqu'à aujourd'hui",
    "371": "Utilisation des numéros de QR-Code pour la gamme de stations actuelle",
    "372": "Traductions",
    "373": "Modifier la traduction",
    "374": "Clé",
    "375": "Anglaise",
    "376": "Évaluer",
    "377": "ID à attribuer",
    "378": "Positions des fenêtres et des portes dans le projet de construction",
    "379": "Service de réparation / preuve de réparation",
    "380": "Entretien et historique",
    "381": "Protocole de pose",
    "382": "Rapport de réparation",
    "383": "Remplacement du verre",
    "384": "Remplacement des vantaux",
    "386": "Commentaires",
    "387": "Les propriétés de la menuiserie ont été conservées",
    "388": "Nouvelles fonctionnalités",
    "389": "Rapport d'intervention",
    "390": "Entretien de la quincaillerie",
    "391": "Fixer un rendez-vous d'entretien",
    "392": "Vers la liste complète ",
    "393": "Info fenêtre",
    "394": "Info pose",
    "395": "Couleur",
    "396": "Largeur x hauteur de l'élément en mm",
    "397": "Largeur x hauteur du verre en mm",
    "398": "Informations sur la pose",
    "399": "Nom du poseur",
    "400": "Date / heure de la pose",
    "401": "Site d'installation (GPS)",
    "402": "ID de l'appareil JustSmart",
    "403": "Envoyer par email",
    "404": "Position du filtre",
    "405": "Aucun",
    "406": "Portes de balcon",
    "407": "Cuisine",
    "408": "Salle de bain",
    "409": "Chambre",
    "410": "Toit",
    "411": "Assemblée",
    "412": "Étage",
    "413": "Joindre une photo",
    "414": "Signature",
    "415": "effacer le dessin",
    "416": "Elément sans dommage",
    "417": "Verre non endommagé",
    "418": "Ouvrir la caméra",
    "419": "Télécharger une image",
    "420": "Poser & assigner des fenêtres",
    "421": "Rapport de réception des travaux",
    "422": "Envoyer",
    "423": "Projet de construction complet",
    "424": "Type de pose",
    "425": "Explicite pour l'élément",
    "426": "Travail entièrement terminé",
    "427": "Protocoles de pose des fenêtres individuelles",
    "428": "Un problème existe",
    "429": "Demander de l'aide",
    "430": "Message",
    "431": "Soumettre",
    "432": "Élément suivant",
    "433": "Liste avec tous les éléments",
    "434": "Calendrier de construction",
    "435": "via le numéro de commande",
    "436": "via le scanner de QR-Code",
    "437": "afficher un seul",
    "438": "ou",
    "439": "Numéro de code-barres",
    "440": "Afficher les informations",
    "441": "Réparation/plainte",
    "442": "Afficher le contact",
    "443": "Commentaires",
    "444": "Scanner un autre QR-Code",
    "445": "Prochain entretien",
    "446": "Fabricant de fenêtres",
    "447": "Propriétés",
    "448": "Valeur d'isolation acoustique",
    "449": "Uf",
    "450": "Solutions de rénovation / possibilités d'extension",
    "451": "Contact",
    "452": "Votre Window.ID",
    "453": "Que voulez-vous faire ?",
    "454": "L'entretien régulier des fenêtres prolonge leur durée de vie et votre confort.",
    "455": "Demander un rendez-vous d'entretien via le formulaire de contact",
    "456": "Demander un rendez-vous d'entretien par téléphone",
    "457": "à leur artisan de fenêtres",
    "458": "Je confirme par la présente les conditions générales et le règlement RGPD",
    "459": "Votre demande",
    "461": "Contact via ...",
    "462": "Bris de glace",
    "463": "La fenêtre ne ferme pas correctement",
    "464": "La fenêtre est coincée",
    "465": "Fenêtre endommagée",
    "466": "Autres accessoires",
    "467": "Vos fenêtres et portes ont été",
    "468": "assemblées par cette entreprise",
    "469": "Fabricant de fenêtres",
    "470": "Notez maintenant vos fenêtres ainsi que leur pose",
    "471": "Dans quelle mesure êtes-vous satisfait du service de l'entreprise ?",
    "472": "Les produits livrés sont-ils corrects ?",
    "473": "Comment trouvez-vous vos fenêtres ?",
    "474": "Y a-t-il eu des problèmes de traitement ?",
    "475": "Positions des fenêtres et des portes",
    "476": "Qu'est-ce que vous voudriez améliorer ?",
    "477": "Instructions de pose",
    "478": "Positions des fenêtres et des portes",
    "479": "Ouvrir le rapport de pose",
    "480": "Liste complète des éléments par numéro de commande",
    "481": "Liste des éléments via le numéro de commande / QR",
    "482": "Envoyer le journal des tâches",
    "483": "Détails de la fenêtre",
    "484": "Détails de l'élément",
    "485": "Numéro du projet de construction",
    "486": "Fenêtre",
    "487": "Position de la fenêtre",
    "488": "Service de réparation",
    "489": "Détails de l'entretien",
    "490": "Client",
    "491": "Formulaire de contact",
    "492": "Trouvez maintenant un artisan REHAU près de chez vous",
    "493": "Catégorie",
    "494": "Scan QR-Code",
    "495": "Personne à contacter",
    "496": "mettre à jour",
    "497": "Filtres",
    "498": "Pose terminée",
    "499": "Pose terminée ?",
    "500": "Livraison sans dommage",
    "501": "Sauvegarder et envoyer au fabricant",
    "502": "Prendre une photo",
    "503": "Adresse e-mail non valide",
    "504": "Installer l'application",
    "505": "Placer",
    "506": "Liste des projets",
    "507": "Charger la commande sélectionnée ?",
    "508": "Numéro de commande/code-barres erroné(e)",
    "509": "Fenêtre unique",
    "510": "Saisir les 382s",
    "511": "Envoyer le rapport Rehau par e-mail",
    "512": "Veuillez sélectionner 4 options",
    "513": "Scannez maintenant le code QR pour savoir à quel endroit du bâtiment cet élément doit être installé.",
    "514": "Enregistrer les données",
    "515": "Afficher",
    "516": "Résultats",
    "517": "page",
    "518": "De",
    "519": "Date de",
    "520": "Date à",
    "521": "Commandes ouvertes",
    "522": "Taille",
    "523": "Monté sur",
    "524": "Succès",
    "525": "Info",
    "526": "Nouvelle commande chargée",
    "527": "ID assembleur",
    "528": "Connexion",
    "529": "Je confirme les conditions générales et le règlement RGPD.",
    "530": "Déconnexion",
    "531": "scanner",
    "532": "Envoyer le rapport",
    "533": "Envoyer le rapport à l'administration ?",
    "534": "Réparer le protocole",
    "535": "option 5",
    "536": "option 6",
    "537": "option 7",
    "538": "option 8",
    "539": "option 9",
    "540": "option 10",
    "541": "Email envoyé",
    "542": "L'envoi de l'email a échoué !",
    "543": "Lubrifiez les ferrures",
    "544": "Ajusté",
    "545": "Nettoyé",
    "546": "Etanche",
    "547": "Accessoires échangés",
    "548": "Commentaire",
    "549": "Tous les éléments sont livrés sur le chantier",
    "550": "Emplacement de pose respecté",
    "551": "L'envoi du protocole a échoué !",
    "552": "Sauvegarder les informations du protocole",
    "553": "Données sauvegardées.",
    "554": "Échec de la sauvegarde des données.",
    "555": "Informations produit Smart",
    "556": "Validation produit Smart",
    "557": "Numéro d'article de l'électronique de la fenêtre",
    "558": "Numéro de série de l'électronique de la fenêtre",
    "559": "Scanner le code QR (DMC) du contrôleur maître ou le saisir manuellement.",
    "560": "Produit Smart entièrement configurée et fonctionnelle",
    "561": "Tous les éléments visibles ne présentent aucun défaut",
    "562": "Un système de commutation a-t-il été connecté à 0-10V (0-100%) ?",
    "563": "Lieu d'installation bâtiment",
    "564": "Début du scan",
    "565": "Arrêt du scan",
    "566": "Élément  actuellement sélectionné",
    "567": "Il est possible de sélectionner 4 attributs en même temps au maximum.",
    "568": "Pos. client",
    "569": "Date de production",
    "570": "Changer la Window.ID",
    "571": "Précédent Window.ID",
    "572": "Nouveau Window.ID",
    "573": "Le numéro Auftrag n'existe pas ou un nouveau numéro Auftrag est déjà utilisé.",
    "574": "Code-barres",
    "575": "Position de l'ID",
    "576": "Type",
    "577": "ID de l'élément",
    "578": "Informations sur l'élément",
    "579": "Window.ID",
    "580": "Veuillez signer dans l'espace prévu",
    "581": "Terminé",
    "582": "Signer",
    "583": "Nouvelle langue",
    "584": "Langue d'origine",
    "585": "Nom de la langue",
    "586": "Créer une nouvelle langue",
    "587": "Ajouter une langue",
    "588": "Mise à jour",
    "589": "mise à jour en cours",
    "590": "Désactivation de l'entreprise",
    "591": "Activation de l'entreprise",
    "592": "Êtes-vous sûr de vouloir désactiver cette entreprise ?",
    "593": "Êtes-vous sûr de vouloir activer cette entreprise ?",
    "594": "Désactivation de l'utilisateur",
    "595": "Activation de l'utilisateur",
    "596": "Êtes-vous sûr de vouloir désactiver cet utilisateur ?",
    "597": "Êtes-vous sûr de vouloir activer cet utilisateur ?",
    "598": "Position de l'ID",
    "599": "Tout",
    "600": "Importer/Exporter",
    "601": "Veuillez choisir un fichier .csv pour l'import",
    "602": "Import",
    "603": "Export",
    "604": "Fichier",
    "605": "Traducteur",
    "606": "Fichiers code-barres",
    "607": "Veuillez choisir le chemin du dossier pour les fichiers code-barres",
    "608": "Window.ID",
    "609": "Système de fenêtre",
    "610": "Pos. client",
    "611": "Couleur",
    "612": "Largeur x hauteur du élément",
    "613": "Largeur x hauteur du vitrage",
    "614": "Remplissage",
    "615": "Ferrure",
    "616": "Date de liaison avec l'étiquette",
    "617": "Date/Heure",
    "618": "GPS Coordonnées LinkMaps",
    "619": "Nr",
    "620": "Pose",
    "621": "Etage",
    "622": "Pos",
    "623": "Nombre de caractères à ignorer au début du code-barres",
    "624": "Nombre de caractères à ignorer à la fin du code-barres",
    "625": "Email - User activation",
    "626": "Activer une société",
    "627": "Période d'essai expirée",
    "628": "Renvoyer l'email d'activation",
    "629": "Poseur/fabricant déjà existant",
    "630": "Utilisateur activé",
    "631": "Aller à la page de connexion",
    "632": "Détails de l'utilisateur",
    "633": "Ajouter un utilisateur lié",
    "634": "Supprimer le lien avec l'utilisateur",
    "635": "L'utilisateur est déjà lié",
    "636": "Le nom d'utilisateur ou le mot de passe est vide",
    "637": "L'utilisateur n'a pas été trouvé",
    "638": "Envoyer le rapport",
    "639": "Confirmez la pose sans dommage",
    "640": "Option 5",
    "641": "Option 6",
    "642": "Option 7",
    "643": "Option 8",
    "644": "Option 9",
    "645": "Option 10",
    "646": "Poseur",
    "647": "Type",
    "648": "Date",
    "649": "Description ",
    "650": "Maintenance du matériel",
    "651": "Set",
    "652": "Nettoyer",
    "653": "Joint ",
    "654": "Remplacement des accessoires",
    "655": "Commentaire",
    "656": "Connecteur",
    "657": "Système de fenêtre",
    "658": "Pièce",
    "659": "Numéro de projet",
    "660": "ID commande",
    "661": "Hauteur",
    "662": "Largeur",
    "663": "Données d'ajustage",
    "664": "Largeur x hauteur du vitrage",
    "665": "Structure du verre",
    "666": "Window.ID",
    "667": "Window Electric ID",
    "668": "ID du vitrage",
    "669": "Conditions",
    "670": "Ajouter/Supprimer",
    "671": "Et/Ou",
    "672": "Groupe",
    "673": "Colonne",
    "674": "Condition",
    "675": "Saisir le numéro de client",
    "676": "Traducteur",
    "678": "Contrôle de la version de l'application",
    "679": "La mise à jour peut prendre quelques minutes",
    "680": "En attente d'une nouvelle version",
    "681": "Il y a une nouvelle version de l'application, êtes-vous sûr de vouloir la mettre à jour ?",
    "682": "L'application est à jour, voulez-vous restaurer l'ancienne version ?",
    "683": "Restaurer l'ancienne version",
    "684": "Insérer le nom de la station",
    "685": "Remplissage du numéro de commande",
    "686": "Veuillez insérer le numéro de commande",
    "687": "Longueur maximale du numéro de commande",
    "688": "Veuillez insérer la longueur maximale du numéro de commande",
    "689": "Chemin du dossier MDB",
    "690": "Veuillez insérer le chemin du fichier .mdb (utilisé par l'importateur lors de la recherche de fichiers .mdb)",
    "691": "Chemin d'accès au dossier XML",
    "692": "Veuillez insérer le chemin du fichier .xml (utilisé par l'importateur lors de l'analyse des fichiers .xml)",
    "693": "Ignorer le(s) caractère(s) au début du code-barres",
    "694": "Ignorer le(s) caractère(s) de fin du code-barres",
    "695": "Désignation",
    "696": "Longueur",
    "697": "Position supplémentaire",
    "698": "Produit Smart sans dommages",
    "699": "Produit Smart connecté",
    "700": "Orientation de la fenêtre",
    "701": "Numéro de l'élément de position",
    "702": "Produit Smart configuré",
    "703": "Élément sans dommage",
    "704": "Numéro d'article",
    "705": "Numéro de série",
    "706": "Pas d'image disponible",
    "707": "Chantier propre",
    "708": "Glisser-déposer les fichiers image/pdf ici",
    "709": "Téléchargement de fichiers",
    "710": "Type de fichier",
    "711": "Commande (toutes les positions)",
    "712": "Supprimé",
    "713": "Notes de publication",
    "714": "Hauteur (mm)",
    "715": "Largeur (mm)",
    "716": "Il n'y a pas de panneau pour cette position",
    "717": "Poste",
    "718": "Bulk station",
    "719": "Bulk range",
    "720": "Window.ID (dernière)",
    "721": "Le verre a été remplacé",
    "722": "Les accessoires ont été réparés",
    "723": "L'ouvrant a été remplacé",
    "724": "La surface a été réparée",
    "725": "Les accessoires ont été réparés",
    "726": "La réparation est terminée",
    "727": "Confirmer le mot de passe",
    "728": "Mot de passe incorrect",
    "729": "Les mots de passe ne correspondent pas",
    "730": "Le mot de passe ne peut pas être vide",
    "731": "Emplacement",
    "732": "Historique",
    "733": "Valeur U du châssis",
    "734": "Verre spécial",
    "735": "Aucune position",
    "736": "Avancement de la fabrication",
    "737": "Date de fin de fabrication",
    "738": "Avancement de la pose",
    "739": "Date de fin de pose",
    "740": "Station supprimée",
    "741": "Êtes-vous sûr de vouloir supprimer la station? ",
    "742": "Ajouter une station",
    "743": "Supprimer la commande",
    "744": "Supprimer la commande",
    "745": "Ëtes-vous sûr de vouloir la commande?",
    "746": "Utilisateur ajouté",
    "747": "Utilisateur modifié",
    "748": "Mot de passe oublié ?",
    "749": "Le champ Mot de passe est obligatoire",
    "750": "Le champ Nom d'utilisateur est obligatoire",
    "751": "Ce rôle d'utilisateur n'est pas autorisé à se connecter au tableau de bord",
    "752": "L'utilisateur n'est pas actif",
    "753": "L'entreprise de l'utilisateur n'est pas active ou la période de test est terminée",
    "754": "Le nom d'utilisateur ou le mot de passe est incorrect",
    "755": "Code postal",
    "756": "Utiliser la molette de la souris pour agrandir ou réduire l'image",
    "757": "Réinitialiser le mot de passe",
    "758": "L'e-mail pour réinitialiser le mot de passe a été envoyé",
    "759": "Le mot de passe a été réinitialisé",
    "760": "E-Mail - Réinitialisation du mot de passe",
    "761": "Numéro de l'élément",
    "762": "Numéro de commande à partir de",
    "763": "Veuillez saisir la référence du premier numéro de commande",
    "764": "Longueur du numéro de commande",
    "765": "Veuillez indiquer la longueur du numéro de commande",
    "766": "Plage de numéro de commande  à partir de",
    "767": "Position à laquelle commence la plage de numéros de commande ",
    "768": "Longueur de la plage de commande",
    "769": "Veuillez saisir la longueur de la plage de numéros de commande",
    "770": "Please insert order piece from",
    "772": "Longueur du nombre de pièces de commande",
    "773": "Veuillez indiquer la longueur de la pièce à commander",
    "774": "Numéro de position à partir de",
    "775": "Veuillez saisir la référence du premier numéro de position ",
    "776": "Longueur du numéro de position",
    "777": "Veuillez indiquer la longueur du numéro de position",
    "778": "Longueur du numéro de l'élément à partir de",
    "779": "Veuillez saisir la référence du premier élément",
    "780": "Longueur du numéro de l'élément",
    "781": "Veuillez indiquer la longueur de l'élément",
    "782": "Niveau de sécurité",
    "783": "Caractère de remplissage du code-barres",
    "784": "Veuillez insérer le caractère de remplissage du code-barres",
    "785": "Retour aux projets",
    "786": "(lecture seule)",
    "787": "accès limité",
    "788": "Accès en lecture seule",
    "789": "Landingpage dédiée",
    "790": "Veuillez insérer la chaîne de connexion",
    "791": "Chaîne de connexion DB",
    "792": "Chaîne de connexion DB STD",
    "793": "Chaîne de connexion DB BWG",
    "794": "Connexion à la base de données",
    "795": "Chat",
    "796": "Messages",
    "797": "Document CE",
    "798": "Document de commande",
    "799": "Dernière réparation",
    "800": "Réception effectuée",
    "801": "Nouvelle mise à jour disponible, merci de redémarrer l'application",
    "802": "Lien externe",
    "803": "Préfixe de lien externe",
    "804": "Référence de projet",
    "805": "Intervalle entre les tâches",
    "806": "Utilisateurs du tableau de bord",
    "807": "Utilisateurs de l'application",
    "808": "Dernier import",
    "809": "minutes",
    "810": "Version du connecteur (\"Matcher\")",
    "811": "Version de l'importateur",
    "812": "Déconnexion de l'utilisateur",
    "813": "L'utilisateur s'est déconnecté avec succès",
    "814": "Code-barres modifié",
    "815": "Informations mises à jour",
    "816": "Instructions",
    "817": "Installer l'application",
    "818": "Installez l'application Window.ID en scannant le code QR.",
    "819": "liée",
    "820": "non liée",
    "821": "Planificateur",
    "822": "Nombre de jours",
    "823": "Paramètres pour le rappel de maintenance.",
    "824": "jours",
    "825": "Prochain entretien",
    "826": "Modifier l'image",
    "827": "Sans filtre",
    "828": "Ajouter un projet",
    "829": "Nouveau projet",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera"
};

export default fr;