const bg = {
    "1": "Да",
    "2": "Не",
    "3": "30-дневна тестова версия",
    "4": "Регистрирана е рекламация през App",
    "5": "Приемане",
    "6": "Дата на приемане",
    "7": "Действие ",
    "8": "Активен",
    "9": "Дейности",
    "10": "Добавяне",
    "11": "Добавяне на монтажник",
    "12": "Добавяне на фирма",
    "13": "Добавяне на дилър/монтажник",
    "14": "Добавяне на производител",
    "15": "Добавяне на управление на собствеността",
    "16": "Добавяне на потребител",
    "17": "Допълнително поле",
    "18": "Редактиране на име на допълнително поле",
    "19": "Коментари",
    "20": "Допълнителна информация",
    "21": "Допълнителни продукти",
    "22": "Смяна на обкова",
    "23": "Администратор",
    "24": "Администраторски изглед",
    "25": "Всички елементи ли са доставени на обекта?",
    "26": "Всички позиции са монтитрани",
    "27": "Всички права запазени",
    "28": "Наистина ли искате да изтриете тази фирма?",
    "29": "Наистина ли искате да изтриете този потребител?",
    "30": "Монтиран",
    "31": "Монтажник",
    "32": "Монтажници",
    "33": "Монтаж",
    "34": "Монтажът е завършен",
    "35": "Дата на монтаж",
    "36": "Проекти",
    "37": "Указания за поддръжка",
    "38": "Данни за сертификата",
    "39": "Сертификат(и)",
    "40": "Смяна на крило",
    "41": "Изчисти",
    "42": "Затвори",
    "43": "Цвят",
    "44": "Цвят/декор",
    "45": "Комисионна",
    "46": "Фирма",
    "47": "Данни на фирмата",
    "48": "Информация за фирмата",
    "49": "Посока",
    "50": "Рекламации",
    "51": "Приключване на проекта",
    "52": "Изпълнен",
    "53": "Connected Window",
    "54": "Обектът е почистен",
    "55": "Дата на създаване",
    "56": "Краен клиент",
    "57": "Клиенстки номер",
    "58": "Референция на елемент",
    "59": "Дата",
    "60": "Период",
    "61": "Дилър/Монтажник",
    "62": "Изтриване на фирма",
    "63": "Изтриване на производител",
    "64": "Изтриване на потребител",
    "65": "Дата на доставка",
    "66": "Доставка без повреди",
    "67": "Описание",
    "68": "Определено място за монтаж",
    "69": "Показване на _MENU_ записи на страница",
    "70": "Разпределение ",
    "71": "Документи",
    "72": "Изток",
    "73": "Редактиране на монтажник",
    "74": "Редактирайте фирма",
    "75": "Редактирайте производител",
    "76": "Редактирайте управление на собствеността",
    "77": "Редактирайте потребител",
    "78": "Ширина х Височина на елемента",
    "79": "Елемент с това ID не съществува",
    "80": "Елементи",
    "81": "Данните на e-mail са запазени",
    "82": "Въвеждане на цвят/декор",
    "83": "Въведете комисионна",
    "84": "Въведете име на фирмата",
    "85": "Въведете e-mail",
    "86": "Въведете разширение",
    "87": "Въведете височина",
    "88": "Въведете IP-адрес",
    "89": "Въведете име  ",
    "90": "Въведете номер",
    "91": "Въведете брой позиции",
    "92": "Въведете парола",
    "93": "Въведете телефон ",
    "94": "Въведете тел. номер",
    "95": "Въведете град",
    "96": "Въведете количество",
    "97": "Въведете улица",
    "98": "Въведете фамилия",
    "99": "Въведете потребителско име",
    "100": "Въведете ID на акаунта",
    "101": "Въвведете уебсайт",
    "102": "Въведете ширина",
    "103": "Грешка при запаметяване данните на сървъра.",
    "104": "Грешка при запазване на e-mail данните",
    "105": "Аксесоари за ремонт",
    "106": "изтекъл на ",
    "107": "изтича на",
    "108": "Разширение",
    "109": "Производители",
    "110": "Фабрика",
    "111": "Филтър",
    "112": "филтриран от общо _MAX_ записа",
    "113": "Първо",
    "114": "Анулиране",
    "115": "Данни за обкова",
    "116": "Комарници",
    "117": "Отпечатък",
    "118": "Коментари",
    "119": "От приложението",
    "120": "От изглед на администратор",
    "121": "Препарат за почистване на стъкло",
    "122": "Смяна на стъклопакет",
    "123": "Структура на стъклопакета",
    "124": "Ширина х Височина на стъклопакета",
    "125": "Поддръжка на обкова",
    "126": "Смяна на обкова",
    "127": "Височина",
    "128": "Указания за почистване",
    "129": "Изображение",
    "130": "Информация за елемента",
    "131": "Ограничена информация",
    "132": "Документи за позицията",
    "134": "Мястото на монтаж",
    "135": "Въвеждане на баркод",
    "136": "IP адрес",
    "137": "изисква се",
    "138": "Eзик",
    "139": "Последно",
    "140": "Последна поддръжка",
    "141": "Последна рекламация",
    "142": "Последно обслужване",
    "143": "Линк към артикул",
    "144": "Зареждане",
    "145": "Всички елемети съгласно спецификация?",
    "146": "Изход",
    "147": "Данни за досъп до e-mail",
    "148": "Поддръжка",
    "149": "Променено от",
    "150": "Произведено",
    "151": "Master Controller design",
    "152": "Master Controller mateiral number",
    "153": "Master Controller serial number",
    "154": "Дата на производство",
    "155": "Видимост на менюто",
    "156": "Автоматъчно потвърждение на баркода",
    "157": "Документи на целевата страница",
    "158": "Нов тестови период",
    "159": "Следващ",
    "160": "Няма повреди по елемента",
    "161": "Няма повреди по стъклопакета",
    "162": "Не са намерени съвпадащи записи",
    "163": "Никой от елементите не е монтиран",
    "164": "Север",
    "165": "Неинсталирано",
    "166": "Нищо не е намерено - съжаляваме",
    "167": "Брой",
    "168": "Брой елементи",
    "169": "Резюме на проекта",
    "170": "Брой на позициите",
    "171": "на",
    "172": "Дата на поръчка",
    "173": "Номер на поръчка",
    "174": "Прогрес на поръчката",
    "175": "Изход",
    "176": "Парола",
    "177": "Телефон",
    "178": "Град",
    "179": "Препарат за почистване на PVC",
    "180": "Позиция в документа",
    "181": "Данни за позициционирането на елемента",
    "182": "Позиция с това ID не съществува",
    "183": "Позиции",
    "184": "Предишно",
    "185": "Идентификационни данни на потребителя",
    "186": "Печат на данните за влизане",
    "187": "Обработка",
    "188": "Производител",
    "189": "Профилна система",
    "190": "Проект",
    "191": "Номер на проекта",
    "192": "Управление на собствеността",
    "193": "Количество",
    "194": "Рекламация",
    "195": "Моля изберете място",
    "196": "Процент рециклиран материал в профилите",
    "197": "Ремонт",
    "198": "Ремонт на аксесоари",
    "199": "Ремонт на повърхност",
    "200": "Докладвано през приложение",
    "201": "Докладвано от контролен режим",
    "202": "Нулиране на тестовия период",
    "203": "Резултат",
    "204": "Роля",
    "205": "Запазване",
    "206": "Упешно запаметяване",
    "207": "Почистване на повърхността",
    "208": "Търсене",
    "209": "Ниво на сигурност",
    "210": "Изпращане на данни към REHAU",
    "211": "Сервиз",
    "212": "Сервиз & продажби",
    "213": "Доставчик на сервиз",
    "214": "Настройки",
    "215": "Да се извърши ли цялостно примемане или поддръжка?",
    "216": "Показване на _START_ до _END_ от _TOTAL_ записи",
    "217": "Показване на 0 до 0 от 0 записа",
    "218": "Щори",
    "219": "Вход",
    "220": "Влезте, за да започнете Вашата сесия",
    "221": "Информация за Smart Glass",
    "222": "Smart Window",
    "223": "Някои елементи са монтирани",
    "224": "Някои елементи не съвпадат",
    "225": "Някоя позиция /и/ трябва да се монтират",
    "226": "Юг",
    "227": "Статистика",
    "228": "Улица",
    "229": "Фамилия ",
    "230": "Устойчивост и Енергия",
    "231": "Телефон",
    "232": "Успешен ресет на тестовия период",
    "233": "Тестови период(и)",
    "234": "Ремонтът е завършен",
    "235": "Има налични рекламации",
    "236": "Няма фирма с това ID",
    "237": "Няма информация за този елемент",
    "238": "Тази фирма вече съществува",
    "239": "Обръщение",
    "240": "до",
    "241": "Пълно приемане",
    "242": "Общо",
    "243": "Неизвестен",
    "244": "Качване на лого",
    "245": "Администриране на потребители",
    "246": "Потребителско име",
    "247": "Потребителското име вече е заето",
    "248": "Потребители",
    "249": "U-стойност",
    "250": "U-стойност на рамката",
    "251": "U-стойност на стъклото",
    "252": "Даден е W.ID",
    "253": "Предупреждение срещу повреждане на Window ID",
    "254": "Добре дошли в панела Window.ID",
    "255": "Запад",
    "256": "Ширина",
    "257": "Ширина х Височина в мм",
    "258": "Площ на прозорците от ",
    "259": "Площ на прозорците в",
    "260": "Window Electronic номер на материала",
    "261": "Window Electronic сериен номер",
    "262": "Място на монтаж на прозорците",
    "263": "Профилна система",
    "264": "Тип прозорец",
    "265": "Смяна на крилото",
    "266": "Сигурни ли сте?",
    "267": "Изчистете филтъра",
    "268": "Редактиране",
    "269": "Експортирай в CSV",
    "270": "Баркод четец",
    "271": "Идентификатор на стъклото",
    "272": "Информация",
    "273": "Връзка",
    "274": "Свързан потребител",
    "275": "Email",
    "276": "Настройки на електронната поща",
    "277": "Тестов период на e-mail",
    "278": "Наименование на сервиза",
    "279": "Сервиз",
    "280": "Сервизен телефон",
    "281": "Сервизен e-mail",
    "282": "Статус",
    "283": "Настройки на позицията",
    "284": "Основание",
    "285": "Телефон",
    "286": "Потребител",
    "287": "Уебсайт",
    "288": "Идентификатор на прозореца",
    "289": "Прозоречна система",
    "290": "Данни на клиента",
    "291": "Име",
    "292": "Структура на стъклопакета",
    "293": "Данни за обкова",
    "294": "Име на монтажника",
    "295": "Фирма инсталатор",
    "296": "Телефон на инсталатор",
    "297": "E-mail на инсталатор",
    "298": "URL",
    "299": "Държава",
    "300": "Моля, добавете потребител за тази компания",
    "301": "Допълнително меню",
    "302": "Панел",
    "303": "Приложение",
    "304": "Дизайн на целевата страница",
    "305": "Управление на потребителите",
    "306": "Потвърдете",
    "307": "Тяло",
    "308": "Приложение",
    "309": "Идентификатор ID",
    "310": "Позиция",
    "311": "Предупреждение",
    "312": "W.ID",
    "314": "Дилъри",
    "315": "Активиране",
    "316": "Деактивиране",
    "317": "Компания",
    "318": "Съжаляваме",
    "319": "Администратор",
    "320": "Потребител",
    "321": "Пощенски код",
    "322": "Лого",
    "323": "Тапет",
    "324": "Account ID",
    "325": "CE Documents",
    "326": "Настройки на приложението",
    "327": "Автоматично ",
    "328": "Ръчно",
    "329": "Търсене",
    "330": "Качване",
    "331": "Автоматично качване на документи",
    "332": "Source folder",
    "333": "Моля изерете пътека на папката за автоматично качване на документи.",
    "334": "Моля, изберете .zip файл, съдържащ файл PPS XML ",
    "335": "Позиция",
    "336": "Ръчно сканиране",
    "337": "Smart Glass Station",
    "338": "Прехвърляне на информация",
    "339": "Максимален брой ленти в таймлайна",
    "340": "QR код от",
    "341": "QR код до",
    "342": "QR код префикс",
    "343": "QR код дължина",
    "344": "Данните на баркода са невалидни",
    "345": "Api ключ",
    "346": "enter qrcode",
    "347": "Station name",
    "348": "Не са избранни данни за зареждане",
    "349": "Файлът е качен",
    "350": "Грешка при качване на файла",
    "351": "Активирано е ръчно сканиране",
    "352": "Моля въведете QR код",
    "353": "Баркодът не е валиден",
    "354": "Баркодъте импортиран",
    "356": "Няма данни",
    "357": "Неуспешно свързване",
    "358": "История",
    "359": "Баркодът се използва",
    "360": "Грешка при съпоставяне на баркод",
    "361": "Ръчно",
    "362": "От  ",
    "363": "До",
    "364": "Дължината на префикса на QR кода трябва да е по-къс от дължината на самия код",
    "365": "Диапазона на баркода е невалиден",
    "366": "Невалидни номера",
    "367": "Вече използван",
    "368": "Използван днес",
    "369": "Свободно",
    "370": "Използван до днес",
    "371": "Използване на номера на QR кода за текущия обхват на позицията",
    "372": "Преводи",
    "373": "Редактиране на превод",
    "374": "Ключ",
    "375": "Английски",
    "376": "Стойност",
    "377": "Възложи W.ID",
    "378": "Позиции на прозорците и вратите в проекта",
    "379": "Услуга ремонт /Доказателство за ремонт",
    "380": "Поддръжка и история",
    "381": "Протокол от монтажа",
    "382": "Промени",
    "383": "Смяна на стъклопакета",
    "384": "Смяна на крилото",
    "386": "Коментари",
    "387": "Ремонтът е завършен",
    "388": "Нови характеристики",
    "389": "Протокол от поддръжка",
    "390": "Смазване на обкова",
    "391": "Ангажиране на посещение за поддръжка",
    "392": "Списък на целия проект",
    "393": "Информация за прозорците",
    "394": "Информация за монтажа",
    "395": "Цвят/Декор",
    "396": "Ширина х Височина на елемента в мм.",
    "397": "Ширина х Височина на стъклопакета в мм.",
    "398": "Данни за монтажа",
    "399": "Име на монтажника",
    "400": "Дата/час на монтажа",
    "401": "Място на монтажа (GPS)",
    "402": "ID на уреда JustSmart",
    "403": "изпращане",
    "404": "Филтър позиция",
    "405": "Липсва",
    "406": "Балконски врати",
    "407": "Кухня",
    "408": "Баня",
    "409": "Стая",
    "410": "Покрив",
    "411": "Монтаж",
    "412": "Позиция",
    "413": "Прикачане на снимка",
    "414": "Подпис",
    "415": "Чертеж",
    "416": "Без повреди по прозореца",
    "417": "Без повреди по стъклото",
    "418": "Отворена камера",
    "419": "Качване",
    "420": "Монтиране и възлагане на прозорци",
    "421": "Приемен протокол",
    "422": "Изпращане",
    "423": "Целия обект",
    "424": "Тип монтаж",
    "425": "Само за елемента",
    "426": "Работата е завършена",
    "427": "Протокол за монтаж на отделни елементи",
    "428": "Наличие на проблем",
    "429": "Потърсете помощ",
    "430": "Съобщение",
    "431": "Изпращане",
    "432": "Следващ елемент",
    "433": "Списък с всички елементи",
    "434": "График на обекта",
    "435": "чрез номер на поръчка",
    "436": "чрез сканиране на QR код",
    "437": "Отделно показване",
    "438": "или",
    "439": "Номер на баркод",
    "440": "Показване на информация",
    "441": "Поправка/Рекламации",
    "442": "Показване на лице за контакт",
    "443": "Обратна връзка",
    "444": "Сканиране на други QR кодове",
    "445": "Следваща поддръжка",
    "446": "Доставчик на прозорци",
    "447": "Характеристики",
    "448": "Стойност на шумоизолация",
    "449": "Uf",
    "450": "Решения за модификация",
    "451": "Контакт",
    "452": "Вашият Window ID",
    "453": "Какво искате да направите?",
    "454": "Редовната поддръжка на прозорците/вратите удължава периода на експлоатация и комфорт",
    "455": "Запитване за посещение за поддръжка чрез формата за контакт",
    "456": "Запитване за посещение за поддръжка по телефона",
    "457": "към Вашия монтажник на прозорци",
    "458": "Потвърждавам общите условия и условията за защита на личните данни",
    "459": "Ваше запитване",
    "461": "Контакт чрез...",
    "462": "Счупено стъкло",
    "463": "Прозореца не се затваря добре",
    "464": "Прозореца е неподвижен",
    "465": "Прозореца е повреден",
    "466": "Други аксесоари",
    "467": "Вашите прозорци и врати бяха",
    "468": "монтирани от фирма",
    "469": "Производител на прозорци",
    "470": "Оценете Вашите прозорци и монтажа",
    "471": "Колко удовлетворени сте от услугата на фирмата?",
    "472": "Доволни ли сте от доставените продукти ?",
    "473": "Харесват ли ви Вашите нови прозорци ?",
    "474": "Имаше ли проблеми по време на процеса?",
    "475": "От какво сте доволни?",
    "476": "Какво бихте подобрили ?",
    "477": "Указания за монтаж",
    "478": "Позиции на прозорци и врати",
    "479": "Отваряне на протокола от монтажа",
    "480": "Пълен списък с елементите от проекта",
    "481": "Списък на елементите чрез № на поръчка / QR",
    "482": "Изпращане на протокла на поръчката",
    "483": "Детайли на прозореца",
    "484": "Детайли   ",
    "485": "Номер на проекта",
    "486": "Прозорец",
    "487": "Позиция на прозорец",
    "488": "Ремонтна услуга",
    "489": "Данни за поддръжката",
    "490": "Клиент  ",
    "491": "Форма за контакт",
    "492": "Намерете REHAU инсталатор близо до Вас",
    "493": "Категория",
    "494": "Сканиране на QR ",
    "495": "Лице за контакти",
    "496": "актуализиране",
    "497": "филтри ",
    "498": "Монтажът е завършен",
    "499": "Завършен ли е монтажа ?",
    "500": "Доставен без повреди",
    "501": "Запазване като PDF",
    "502": "Направи снимка",
    "503": "Невалиден email",
    "504": "Инсталиране на App",
    "505": "Място",
    "506": "Списък с проекти",
    "507": "Да се зареди ли избраната поръчка ?",
    "508": "Грешен номер на поръчка/баркод",
    "509": "Единичен прозорец",
    "510": "Въвеждане промени",
    "511": "Изпращане на REHAU доклад по email",
    "512": "Моля, изберте 4 опции",
    "513": "Сканирайте QR кода, за да разберете къде трябва да бъде монтиран елемента.",
    "514": "Запази файла",
    "515": "Покажи",
    "516": "Резултати",
    "517": "Страница",
    "518": "от",
    "519": "Дата от",
    "520": "Дата до",
    "521": "Отваряне",
    "522": "Големина",
    "523": "Монтиран на",
    "524": "Успех",
    "525": "Информация ",
    "526": "Зареждане на нова поръчка",
    "527": "ID на монтажника",
    "528": "Вход",
    "529": "Потвърждавам общите условия и условията за защита на личните данни",
    "530": "Изход",
    "531": "Скенер",
    "532": "Изпрати доклада",
    "533": "Да се изпрати ли приемателния протокл за всички елементи от тази поръчка до панела за управление?",
    "534": "Дневник за ремонт",
    "535": "Опция 5",
    "536": "Опция 6",
    "537": "Опция 7",
    "538": "Опция 8",
    "539": "Опция 9",
    "540": "Опция 10",
    "541": "Email е изпратен",
    "542": "Грешка при изпращането на email!",
    "543": "Смазване на обкова",
    "544": "Регулиран",
    "545": "Почистен",
    "546": "Уплътнен",
    "547": "Сменен обков",
    "548": "Коментар",
    "549": "Всички елементи са доставени на обекта",
    "550": "Разположение на елементите в строителния проект",
    "551": "Грешка при изпращането на доклада!",
    "552": "Запомни доклада",
    "553": "Информацията е запазена",
    "554": "Грешка при запазване на данните.",
    "555": "Информация за Smart window",
    "556": "Приемане на Smart window",
    "557": "Window electronics material number",
    "558": "Window electronics serial number",
    "559": "Сканиране QR код (DMC) от главен контролер или ръчно вкарване",
    "560": "Smart Window е напълно конфигуриран и функциониращ",
    "561": "Всички видими елементи без повреди / дефекти",
    "562": "Системата за превключване свързана ли е с 0-10V (0-100%) ?",
    "563": "Място на инсталацията",
    "564": "Начало на сканиране",
    "565": "Край на сканиране",
    "566": "Избран елемент",
    "567": "Могат да бъдат избрани максимум до 4 елемента едновременно",
    "568": "Позиция на клиента",
    "569": "Дата на производство",
    "570": "Промени Window.ID ",
    "571": "Предишен Window.ID",
    "572": "Hов Window.ID",
    "573": "Номерът на поръчката не съществува или вече се използва нов номер.",
    "574": "Window.ID",
    "575": "ID позиция",
    "576": "Тип",
    "577": "ID елемент",
    "578": "Информация за елемент ",
    "579": "Window.ID",
    "580": "Моля, впишете се в предоставеното място",
    "581": "Завършен   ",
    "582": "Знак",
    "583": "Нов език",
    "584": "Оригинален език",
    "585": "Наименование на езика",
    "586": "Създайте нов език",
    "587": "Добавете език",
    "588": "актуализиране",
    "589": "актуализация в ход",
    "590": "Деактивиране на фирма",
    "591": "Активиране на фирма",
    "592": "Сигурни ли сте, че искате да деактивирате тази фирма?",
    "593": "Сигурни ли сте, че искате да активирате тази фирма?",
    "594": "Деактивиране на потребител",
    "595": "Активиране на потребител",
    "596": "Сигурни ли сте, че искате да деактивирате този потребител?",
    "597": "Сигурни ли сте, че искате да активирате този потребител?",
    "598": "ID позиция",
    "599": "Всички",
    "600": "Импорт/Експорт",
    "601": "Моля, изберете .csv езиков файл за импортиране",
    "602": "Импорт ",
    "603": "Експорт ",
    "604": "Файл",
    "605": "Преводач",
    "606": "Файлове на баркода",
    "607": "Моля, изберете пътека на папка за файлове с баркод.",
    "608": "Window_ID",
    "609": "Профилна система",
    "610": "Позиция на клиента",
    "611": "Цвят",
    "612": "Размер на елемента",
    "613": "Размер на стъклопакета",
    "614": "Структура на стъклопакета",
    "615": "Информация за обкова",
    "616": "Съвпадаща дата",
    "617": "Дата/време на монтаж",
    "618": "GPS_координати на мястото за монтаж",
    "619": "Номер",
    "620": "Монтаж:",
    "621": "Етаж:",
    "622": "Пощенски код",
    "623": "Игнорирай началния знак/знаци",
    "624": "Игнорирай последния знак/знаци",
    "625": "Email - активиране на потребител",
    "626": "Активирайте фирми",
    "627": "Изтекъл опит",
    "628": "Изпратете отново email за активация",
    "629": "Дилърът/Производителят вече съществуват",
    "630": "Потребителят е активиран",
    "631": "Отидете на страницата за вход",
    "632": "Данни на потребителя",
    "633": "Add linked user",
    "634": "Изтриване на потребителска връзка",
    "635": "Потребителят вече е свързан",
    "636": "Няма потребителско име или парола",
    "637": "Потребителят не е открит",
    "638": "Изпратете протокол",
    "639": "Въпрос за завършен монтаж",
    "640": "Опция 5",
    "641": "Опция 6",
    "642": "Опция 7",
    "643": "Опция 8",
    "644": "Опция 9",
    "645": "Опция 10",
    "646": "Монтажник",
    "647": "Тип",
    "648": "Дата",
    "649": "Описание",
    "650": "Поддръжка на обкова",
    "651": "Комплект",
    "652": "Почистване  ",
    "653": "Уплътнение",
    "654": "Резервни аксесоари",
    "655": "Коментар",
    "656": "Connector",
    "657": "Профилна система",
    "658": "брой ",
    "659": "Номер на проекта",
    "660": "ID на поръчка",
    "661": "Височина",
    "662": "Широчина",
    "663": "Информация за обкова",
    "664": "Дебелина и височина на стъклопакета",
    "665": "стъклена структура",
    "666": "Window.ID",
    "667": "Window Electric ID",
    "668": "ID на стъклопакета",
    "669": "Условия ",
    "670": "Добави/Отстрани",
    "671": "И/Или",
    "672": "Група",
    "673": "Колона",
    "674": "Условия ",
    "675": "Въведете клиентски номер",
    "676": "Преводач",
    "678": "Контрол на версията на приложението",
    "679": "Актуализацията може да отнеме няколко минути",
    "680": "Очаква се нова версия",
    "681": "Това е нова версия на приложението. Сигурни ли сте, че искате актуализация?",
    "682": "Приложението е актуално, искате ли да възстановите старата версия?",
    "683": "Възстанови старата версия",
    "684": "Въведете име ",
    "685": "Попълване на номера на поръчката",
    "686": "Моля, въведете номер на поръчката",
    "687": "Максимална дължина на номер на поръчката",
    "688": "Моля, въведете максимална дължина на номера на поръчката",
    "689": "Път на MDB папка",
    "690": "Моля, въведете пътека на .mdb файл (използван от импортера при сканиране за  .mdb файлове)",
    "691": "Пътека на XML папка",
    "692": "Моля, въведете пътека на .xml файл (използван от импортера при сканиране за .xml файлове)",
    "693": "Игнорирай началния знак/ци на QR-кода",
    "694": "Игнорирай крайния знак/ци на QR-кода",
    "695": "Обозначаване",
    "696": "Дължина ",
    "697": "Допълнителна позиция",
    "698": "Smart window без повреда",
    "699": "Smart window свързан",
    "700": "Ориентация на прозореца",
    "701": "Номер на елемента на позицията",
    "702": "Smart window конфигуриран",
    "703": "Елемента е без повреда",
    "704": "Номер на материала",
    "705": "Сериен номер",
    "706": "Няма налично изображение",
    "707": "Почистване на мястото на монтажа",
    "708": "Плъзнете и поставете файловете image/pdf тук",
    "709": "Качване на файл",
    "710": "Тип файл",
    "711": "Поръчка (всички позиции)",
    "712": "Изтрито",
    "713": "Бележки",
    "714": "Височина (мм)",
    "715": "Широчина (мм)",
    "716": "Няма панел за тази позиция",
    "717": "Артикул",
    "718": "Bulk станция",
    "719": "Bulk обхват",
    "720": "Window ID (последен)",
    "721": "Стъклопакетът е сменен",
    "722": "Аксесоарите са сменени",
    "723": "Крилото е сменено",
    "724": "Повърхността е поправена",
    "725": "Аксесоарите са поправени",
    "726": "Поправката е завършена",
    "727": "Потвърдете паролата",
    "728": "Невалидна парола",
    "729": "Паролите не съвпадат",
    "730": "Паролата не може да бъде празна",
    "731": "Място",
    "732": "Хронология",
    "733": "U-стойност (W/m²K)",
    "734": "Специално стъкло",
    "735": "There is no field for this position",
    "736": "Matched elements",
    "737": "Last matched element",
    "738": "Mounted elements",
    "739": "Last mounted element",
    "740": "Station deleted",
    "741": "Are you sure you want to delete station",
    "742": "Add new station",
    "743": "Delete order",
    "744": "Order deletion",
    "745": "Are you sure you want to delete order?",
    "746": "User added",
    "747": "User edited",
    "748": "Forgot password?",
    "749": "The Password field is required",
    "750": "The User name field is required",
    "751": "This user role is not authorized for dashboard login",
    "752": "User is not active",
    "753": "User company is not active or test period is over",
    "754": "Username or password is incorrect",
    "755": "PLZ",
    "756": "Use scroll wheel to zoom picture in and out",
    "757": "Reset password",
    "758": "Email for password reset has been sent",
    "759": "Password has been reset",
    "760": "Email - Reset password",
    "761": "Element Nr",
    "762": "Order number from",
    "763": "Please insert order number from",
    "764": "Order number length",
    "765": "Please insert order number length",
    "766": "Order range from",
    "767": "Please insert order range from",
    "768": "Order range length",
    "769": "Please insert order range length",
    "770": "Please insert order piece from",
    "772": "Order piece length",
    "773": "Please insert order piece length",
    "774": "Position number from",
    "775": "Please insert position number from",
    "776": "Position number length",
    "777": "Please insert position number length",
    "778": "Position piece from",
    "779": "Please insert position piece from",
    "780": "Position piece length",
    "781": "Please insert position piece length",
    "782": "Security level",
    "783": "Barcode filler character",
    "784": "Please insert barcode filler character",
    "785": "back to projects",
    "786": "(read only)",
    "787": "limited access",
    "788": "read only access",
    "789": "own landingpage",
    "790": "Please insert connection string",
    "791": "DB Connection string",
    "792": "DB STD Connection string",
    "793": "DB  BWG Connection string",
    "794": "Database connection",
    "795": "Chat",
    "796": "Messagess",
    "797": "CE Document",
    "798": "Order Document",
    "799": "Last repair",
    "800": "Acceptance completed",
    "801": "New update vailable, please restart application",
    "802": "External link",
    "803": "External link prefix",
    "804": "Project reference",
    "805": "Task interval",
    "806": "Dashboard users",
    "807": "App users",
    "808": "Last upload",
    "809": "minutes",
    "810": "Matcher version",
    "811": "Importer version",
    "812": "Logout user",
    "813": "User logged out successfully",
    "814": "Barcode changed",
    "815": "Updated information",
    "816": "Directions",
    "817": "Install APP",
    "818": "Install the Window.ID APP by scanning the QR code",
    "819": "matched",
    "820": "not matched",
    "821": "Planner",
    "822": "Number of days",
    "823": "Settings for the maintenance reminder.",
    "824": "days",
    "825": "Next maintenance",
    "826": "Edit image",
    "827": "No filter",
    "828": "Add order",
    "829": "New order",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera"
}

export default bg;

