const  pl = {
    "1": "Tak",
    "2": "Nie",
    "3": "30-dniowa wersja testowa",
    "4": "Reklamacja została zgłoszona za pośrednictwem aplikacji",
    "5": "Odbiór",
    "6": "Data odbioru",
    "7": "Działanie",
    "8": "Aktywny",
    "9": "Działania",
    "10": "Dodaj",
    "11": "Dodaj montażystę",
    "12": "Dodaj firmę",
    "13": "Dodaj dealera",
    "14": "Dodaj producenta",
    "15": "Dodaj zarządzanie nieruchomościami",
    "16": "Dodaj użytkownika",
    "17": "Wolne pole",
    "18": "Edycja nazwy pola dodatkowego",
    "19": "Dodatkowe informacje",
    "20": "Dodatkowe informacje",
    "21": "Produkty dodatkowe",
    "22": "Regulacja",
    "23": "Administrator",
    "24": "Widok administracyjny",
    "25": "Wszystkie elementy dostarczane są na plac budowy",
    "26": "Wszystkie pozycje są zamontowane",
    "27": "Wszelkie prawa zastrzeżone",
    "28": "Czy na pewno chcesz usunąć tą firmę?",
    "29": "Czy na pewno chcesz usunąć tego użytkownika?",
    "30": "Zamontowane",
    "31": "Montażysta",
    "32": "Montażyści",
    "33": "Montaż",
    "34": "Montaż zakończony",
    "35": "Data montażu",
    "36": "Projekty",
    "37": "Wskazówki pielęgnacyjne",
    "38": "Szczegóły dotyczące certyfikatu",
    "39": "Certyfikat(-y)",
    "40": "Czyszczenie",
    "41": "Usuń filtr",
    "42": "Zamknij",
    "43": "Kolor/okleina",
    "44": "Kolor/okleina",
    "45": "Zlecenie",
    "46": "Firma",
    "47": "Szczegóły dotyczące firmy",
    "48": "Informacje o firmie",
    "49": "Strony świata",
    "50": "Reklamacje",
    "51": "Zakończ zamówienie",
    "52": "Zakończono",
    "53": "Podłączone okno",
    "54": "Plac budowy uprzątnięty",
    "55": "Data utworzenia",
    "56": "Klient końcowy",
    "57": "Numer klienta",
    "58": "Pozycja klienta",
    "59": "Data",
    "60": "Data",
    "61": "Dealerzy",
    "62": "Usuń firmę",
    "63": "Usuń fabrykę",
    "64": "Usuń użytkownika",
    "65": "Termin dostawy",
    "66": "Dostawa bez uszkodzeń",
    "67": "Opis",
    "68": "Oznaczenie miejsca montażu",
    "69": "Wyświetlaj rekordy _MENU_ na stronie",
    "70": "Dystrybucja",
    "71": "Dokumenty",
    "72": "Wschód",
    "73": "Edytuj montażystę",
    "74": "Edytuj firmę",
    "75": "Edytuj producenta",
    "76": "Edycja zarządzania nieruchomościami",
    "77": "Edytuj użytkownika",
    "78": "Szerokość elementu x wysokość w mm",
    "79": "Element o tym ID nie istnieje",
    "80": "Elementy",
    "81": "Dane dostępu do poczty elektronicznej zostały zapisane",
    "82": "Wprowadź kolor/okleinę",
    "83": "Podaj zlecenie",
    "84": "Wpisz nazwę",
    "85": "Wpisz adres e-mail",
    "86": "Wprowadź rozszerzenie",
    "87": "Podaj wysokość",
    "88": "Wprowadź adres IP",
    "89": "Wpisz nazwisko",
    "90": "Wpisz numer",
    "91": "Wprowadź liczbę pozycji",
    "92": "Wprowadź hasło",
    "93": "Wprowadź numer telefonu",
    "94": "Wprowadź numer telefonu",
    "95": "Wpisz kod pocztowy",
    "96": "Wprowadź ilość",
    "97": "Wpisz ulicę",
    "98": "Wpisz imiona",
    "99": "Wprowadź nazwę użytkownika",
    "100": "Wpisz numer NIP",
    "101": "Wpisz stronę internetową",
    "102": "Wprowadź szerokość",
    "103": "Błąd podczas zapisywania danych na serwerze",
    "104": "Błąd podczas zapisywania danych dostępu do poczty elektronicznej",
    "105": "Akcesoria do wymiany",
    "106": "wygasł dnia",
    "107": "wygasa od",
    "108": "Rozszerzenie",
    "109": "Producenci",
    "110": "Producent",
    "111": "Filtr",
    "112": "odfiltrowane z _MAX_ wszystkich rekordów",
    "113": "Pierwszy",
    "114": "Anuluj",
    "115": "Dane okuć",
    "116": "Moskitiera",
    "117": "Ślad węglowy",
    "118": "Tekst dowolny",
    "119": "Z aplikacji",
    "120": "Z widoku zarządzania",
    "121": "Środek do czyszczenia szyb",
    "122": "Wymiana oszklenia",
    "123": "Szyba",
    "124": "Szerokość x wysokość szkła w mm",
    "125": "Konserwacja okuć",
    "126": "Wymiana okuć",
    "127": "Wysokość",
    "128": "Instrukcja czyszczenia",
    "129": "Zdjęcie",
    "130": "Informacje o elemencie",
    "131": "Informacje są ograniczone",
    "132": "Wejście",
    "134": "Lokalizacja miejsca montażu",
    "135": "Wprowadź lub zeskanuj kod kreskowy",
    "136": "Adres IP",
    "137": "jest wymagana",
    "138": "Język",
    "139": "Ostatni",
    "140": "Ostatnia konserwacja",
    "141": "Ostatnia reklamacja",
    "142": "Ostatni serwis",
    "143": "Link do artykułu",
    "144": "Ładowanie",
    "145": "Lokalizacja elementów w projekcie budowlanym",
    "146": "Wyloguj się",
    "147": "Dane dostępu do poczty",
    "148": "Konserwacja",
    "149": "Zmiana ręczna przez",
    "150": "Produkowane",
    "151": "Typ głównego urządzenia sterującego",
    "152": "Numer materiału głównego urządzenia sterującego",
    "153": "Numer seryjny głównego urządzenia sterującego",
    "154": "Data produkcji",
    "155": "Widoczność menu",
    "156": "Automatyczne potwierdzanie kodów kreskowych",
    "157": "Więcej informacji o zrównoważonym rozwoju w REHAU",
    "158": "Nowy okres testowy",
    "159": "Następny",
    "160": "Brak uszkodzeń elementu",
    "161": "Brak uszkodzeń oszklenia",
    "162": "Nie znaleziono pasujących rekordów",
    "163": "Żaden z elementów nie jest zamontowany",
    "164": "Północ",
    "165": "Nie zamontowany",
    "166": "Nic nie znaleziono - przepraszamy",
    "167": "Numer",
    "168": "Liczba elementów",
    "169": "Ilość elementów - kwartalnie",
    "170": "Liczba pozycji",
    "171": "na",
    "172": "Data zamówienia",
    "173": "Numer zamówienia",
    "174": "Postęp zamówienia",
    "175": "Wyjście",
    "176": "Hasło",
    "177": "Telefon",
    "178": "Lokalizacja",
    "179": "Środek do czyszczenia tworzyw sztucznych",
    "180": "Pozycja dokumenty",
    "181": "Szczegóły dotyczące pozycji",
    "182": "Pozycja o tym ID nie istnieje",
    "183": "Pozycje",
    "184": "Poprzedni",
    "185": "Informacje dotyczące rejestracji",
    "186": "Drukuj dane dostępu",
    "187": "Przetwarzanie",
    "188": "Producent",
    "189": "System profili",
    "190": "Projekt",
    "191": "Numer projektu",
    "192": "Zarządzanie nieruchomościami",
    "193": "Ilość",
    "194": "Reklamacja",
    "195": "Wybór stacji",
    "196": "Udział materiału z recyklingu",
    "197": "Naprawa",
    "198": "Naprawa akcesoriów dodatkowych",
    "199": "Naprawa powierzchni",
    "200": "Zgłoszony z aplikacji",
    "201": "Zgłoszony z widoku zarządzania",
    "202": "Wyzeruj okres testowy",
    "203": "Wynik",
    "204": "Rola",
    "205": "Zapisz",
    "206": "Pomyślnie zapisane",
    "207": "Uszczelka",
    "208": "Szukaj",
    "209": "Poziom bezpieczeństwa",
    "210": "Dane dostępowe do REHAU",
    "211": "Serwis",
    "212": "Serwis i dystrybucja",
    "213": "Serwisant",
    "214": "Ustawienia",
    "215": "Czy należy wprowadzić ręczny odbiór całościowy?",
    "216": "Wyświetlam _START_ do _END_ z _TOTAL_ rekordów",
    "217": "Pokazuje 0 do 0 z 0 rekordów",
    "218": "Rolety",
    "219": "Login",
    "220": "Zaloguj się, aby rozpocząć sesję",
    "221": "Informacje o Smart Glass",
    "222": "Smart Window",
    "223": "Niektóre elementy są zamontowane",
    "224": "Niektóre elementy nie są dopasowane",
    "225": "Niektóre pozycje wymagają montażu",
    "226": "Południe",
    "227": "Statystyki",
    "228": "Ulica",
    "229": "Imię",
    "230": "Zrównoważony rozwój",
    "231": "Telefon",
    "232": "Okres testowy pomyślnie zresetowany",
    "233": "Okres testowy",
    "234": "Właściwości zostały zachowane",
    "235": "Istnieją reklamacje",
    "236": "Nie ma firmy o tym identyfikatorze",
    "237": "Nie ma informacji na temat tego elementu",
    "238": "Ta firma już istnieje",
    "239": "Tytuł",
    "240": "do",
    "241": "Całkowita akceptacja",
    "242": "Łączna",
    "243": "Nieznany",
    "244": "Prześlij logo",
    "245": "Zarządzanie użytkownikami",
    "246": "Nazwa użytkownika",
    "247": "Nazwa użytkownika już przypisana",
    "248": "Użytkownik",
    "249": "Współczynnik U",
    "250": "Współczynnik U ramy",
    "251": "Współczynnik U oszklenia",
    "252": "Przypisano W.ID",
    "253": "Ostrzeżenie - nie uszkodzić Window.ID",
    "254": "Witamy na pulpicie nawigacyjnym Window.ID",
    "255": "Zachód",
    "256": "Szerokość",
    "257": "Szerokość x wysokość w mm",
    "258": "Powierzchnia okien od",
    "259": "Powierzchnia okien w",
    "260": "Numer materiału elektroniki okiennej",
    "261": "Numer seryjny elektroniki okiennej",
    "262": "Oznaczenie miejsca instalacji",
    "263": "System profili",
    "264": "Typ okna",
    "265": "Wymiana skrzydeł",
    "266": "Czy jesteś pewien?",
    "267": "Wyczyść filtr",
    "268": "Edytuj",
    "269": "Eksportuj CSV",
    "270": "Czytnik kodów paskowych",
    "271": "Identyfikator szkła",
    "272": "Informacje",
    "273": "Link",
    "274": "Podłączeni użytkownicy",
    "275": "E-mail",
    "276": "Ustawienia poczty elektronicznej",
    "277": "Okres testowy poczty elektronicznej",
    "278": "Nazwa usługi",
    "279": "Firma serwisowa",
    "280": "Telefon do serwisu",
    "281": "E-mail serwisu",
    "282": "Status",
    "283": "Ustawienia stanowiska",
    "284": "Dotyczy",
    "285": "Tel.",
    "286": "Użytkownik",
    "287": "Strona internetowa",
    "288": "Identyfikator okna",
    "289": "System okien",
    "290": "Dane klienta",
    "291": "Nazwa",
    "292": "Szyba",
    "293": "Szczegóły dotyczące okuć",
    "294": "Nazwa montażu",
    "295": "Firma montująca",
    "296": "Telefon kontaktowy montaż",
    "297": "E-mail kontaktowy montaż",
    "298": "URL",
    "299": "Kraj",
    "300": "Proszę dodać użytkownika dla tej firmy",
    "301": "Dodatkowe menu",
    "302": "Panel sterowania",
    "303": "Aplikacja",
    "304": "Projektowanie stron docelowych",
    "305": "Zarządzanie użytkownikami",
    "306": "Potwierdź",
    "307": "Korpus",
    "308": "Załącznik",
    "309": "Id",
    "310": "Pozycja",
    "311": "Ostrzeżenie",
    "312": "W.ID",
    "314": "Sprzedawcy",
    "315": "Aktywuj",
    "316": "Dezaktywuj",
    "317": "Firma",
    "318": "Przepraszamy",
    "319": "Administrator",
    "320": "użytkownik",
    "321": "Kod pocztowy",
    "322": "Logo",
    "323": "Tapeta",
    "324": "Numer NIP",
    "325": "Dokumenty CE",
    "326": "Ustawienia aplikacji",
    "327": "Automatyczny",
    "328": "Podręcznik",
    "329": "Przeglądaj",
    "330": "Prześlij",
    "331": "Automatyczne przesyłanie dokumentów",
    "332": "Folder źródłowy (dokumenty)",
    "333": "Wybierz ścieżkę folderu do automatycznego przesyłania dokumentów.",
    "334": "Proszę wybrać swoje utworzone dane Window.ID",
    "335": "Wybrane stanowisko",
    "336": "Ręczne skanowanie Window.ID",
    "337": "Stanowisko Smart Glass",
    "338": "Informacje dotyczące transferu",
    "339": "Liczba pozycji na osi czasu",
    "340": "Window.ID od",
    "341": "Window.ID do",
    "342": "Window.ID - prefiks",
    "343": "Window.ID - długość",
    "344": "Niepoprawny kod kreskowy",
    "345": "Api klucz",
    "346": "Skanuj Window.ID",
    "347": "Nazwa stacji",
    "348": "Nie wybrano pliku",
    "349": "Przesyłanie zakończone sukcesem",
    "350": "Błąd przesyłania",
    "351": "Skanowanie ręczne jest aktywne, zakres numerów nie jest sprawdzany!",
    "352": "Skanuj Window.ID",
    "353": "Kod jest niepoprawny",
    "354": "Importuj kod kreskowy",
    "356": "Brak danych",
    "357": "Błąd połączenia",
    "358": "Oś czasu",
    "359": "Window.ID jest już w użyciu",
    "360": "Błąd podczas dopasowywania",
    "361": "ręcznie",
    "362": "z",
    "363": "do",
    "364": "Długość prefiksu musi być krótsza niż długość Window.ID",
    "365": "Nieprawidłowy zakres liczb",
    "366": "Nieprawidłowy",
    "367": "Już wykorzystany",
    "368": "Dzisiaj użyty",
    "369": "Darmowy",
    "370": "Używany do dziś",
    "371": "Zużycie znaczników Window.ID w obrębie wybranego stanowiska",
    "372": "Tłumaczenia",
    "373": "Edytuj tłumaczenie",
    "374": "Klucz",
    "375": "Język źródłowy",
    "376": "Język docelowy",
    "377": "Przypisac W.ID",
    "378": "Ilość pozycji w całej realizacji",
    "379": "Serwis naprawczy / świadectwo naprawy",
    "380": "Konserwacja i historia",
    "381": "Protokół montażu",
    "382": "Zmiana",
    "383": "Wymiana oszklenia",
    "384": "Wymiana skrzydeł",
    "386": "Tekst dowolny",
    "387": "Właściwości zostały zachowane",
    "388": "Nowe właściwości",
    "389": "Dziennik konserwacji",
    "390": "Konserwacja okuć",
    "391": "Umówić się na wizytę konserwacyjną",
    "392": "Cały projekt budowlany",
    "393": "Informacje o oknach",
    "394": "Informacje",
    "395": "Kolor/okleina",
    "396": "Szerokość x wysokość elementu w mm",
    "397": "Szerokość x wysokość oszklenia w mm",
    "398": "Dane dotyczące okuć",
    "399": "Montażysta",
    "400": "Data/godzina montażu",
    "401": "Miejsce montażu (GPS)",
    "402": "Identyfikator urzadzenia",
    "403": "wyślij",
    "404": "Filtrowanie pozycji",
    "405": "Brak",
    "406": "Drzwi balkonowe",
    "407": "Kuchnia",
    "408": "Łazienka",
    "409": "Pokój",
    "410": "Dach",
    "411": "Montaż",
    "412": "Piętro",
    "413": "Dołącz zdjęcie",
    "414": "Podpis",
    "415": "Rysunek",
    "416": "Brak uszkodzeń elementu",
    "417": "Brak uszkodzeń oszklenia",
    "418": "Otwórz kamerę",
    "419": "Prześlij obraz",
    "420": "Montowanie i przypisywanie okien",
    "421": "Protokół zdawczo-odbiorczy",
    "422": "wyślij",
    "423": "Cały projekt budowlany",
    "424": "Typ montażu",
    "425": "Jednoznacznie dla elementu",
    "426": "Zamówienie zrealizowane",
    "427": "Protokoły montażu dla poszczególnych okien",
    "428": "Problem",
    "429": "Pomoc",
    "430": "Wiadomość",
    "431": "wyślij",
    "432": "Następny element",
    "433": "Lista z wszystkimi elementami",
    "434": "Harmonogram budowy",
    "435": "poprzez numer zamówienia",
    "436": "za pomocą skanera kodów",
    "437": "Indywidualne wyswietlanie",
    "438": "lub",
    "439": "Numer kodu kreskowego",
    "440": "Pokaz informacje",
    "441": "Naprawa/reklamacja",
    "442": "Pokaż osobę kontaktową",
    "443": "Informacje zwrotne",
    "444": "Zeskanuj kolejny kod QR",
    "445": "Następna konserwacja",
    "446": "Dostawca okien",
    "447": "Właściwości",
    "448": "Wartość izolacji akustycznej",
    "449": "Uf",
    "450": "Możliwości rozbudowy",
    "451": "Kontakt",
    "452": "ID TWOJEGO OKNA",
    "453": "Co chciał(-a)byś zrobić?",
    "454": "Regularna konserwacja okien przedłuża ich żywotność oraz komfort użytkowania.",
    "455": "Umów się na wizytę serwisową przez e-mail",
    "456": "Telefoniczne umawianie wizyt konserwacyjnych",
    "457": "do Twojego montażysty okien",
    "458": "Niniejszym potwierdzam ogólne warunki użytkowania oraz postanowienia RODO.",
    "459": "Dotyczy",
    "461": "Kontakt przez...",
    "462": "Uszkodzone oszklenie",
    "463": "Okno nie zamyka się prawidłowo",
    "464": "Okno się zacięło",
    "465": "Uszkodzone okno",
    "466": "Różne / akcesoria",
    "467": "Twoje okna i drzwi były zamontowane dnia",
    "468": "przez firmę",
    "469": "Producent okien",
    "470": "Oceń swoje okna i montaż",
    "471": "W jakim stopniu jesteś zadowolony/-lona z usług firmy?",
    "472": "Czy dostarczone produkty są w porządku?",
    "473": "Jak Ci sie podobaja Twoje okna?",
    "474": "Czy były jakieś problemy z realizacją?",
    "475": "Pozytywne",
    "476": "Co byś poprawił?",
    "477": "Instrukcja montażu",
    "478": "Ilość pozycji okien i drzwi",
    "479": "Otwórz protokół montażu",
    "480": "Lista wszystkich elementów zlecenia",
    "481": "Lista elementów według numeru zamówienia/QR",
    "482": "Wyślij rejestr zadań",
    "483": "Szczegóły dot. okna",
    "484": "Szczególy pojedynczego elementu",
    "485": "Numer projektu budowlanego",
    "486": "Okno",
    "487": "Pozycja okna",
    "488": "Serwis naprawczy",
    "489": "Szczegóły dotyczące konserwacji",
    "490": "Klient końcowy",
    "491": "Formularz kontaktowy",
    "492": "Znajdź teraz najbliższego partnera REHAU",
    "493": "Kategoria",
    "494": "Skan QR",
    "495": "Kontakt",
    "496": "aktualizuj",
    "497": "Filtr",
    "498": "Montaż zakończony",
    "499": "Czy montaż zakończony?",
    "500": "Dostawa bez uszkodzeń",
    "501": "Zapisz wstępny odbiór",
    "502": "Zapisywanie",
    "503": "Brak prawidłowego adresu e-mail",
    "504": "Zainstaluj aplikację",
    "505": "Lokalizacja",
    "506": "Wykaz zamówień",
    "507": "Załadować wybrane zlecenie?",
    "508": "Błędny numer zamówienia",
    "509": "Pojedyncze okno",
    "510": "Wprowadź zmiany",
    "511": "Wyślij raport przez e-mail",
    "512": "Proszę wybrać 4 opcje",
    "513": "Zeskanuj kod QR, aby dowiedzieć się, w którym miejscu budynku należy zamontować ten element.",
    "514": "Zapisz dane",
    "515": "Pokaż",
    "516": "Wyniki",
    "517": "Strona",
    "518": "z",
    "519": "Data od",
    "520": "Data do",
    "521": "otwarte",
    "522": "Rozmiar",
    "523": "Zamontowane na",
    "524": "ok",
    "525": "Informacja",
    "526": "Nowe zamówienie załadowane",
    "527": "Identyfikator montera",
    "528": "Logowanie",
    "529": "Niniejszym potwierdzam ogólne warunki użytkowania oraz postanowienia RODO.",
    "530": "Wyloguj się",
    "531": "Skaner",
    "532": "Protokół odbioru",
    "533": "Czy przesłać protokół odbioru wszystkich pozycji tego zlecenia?",
    "534": "Protokół naprawy",
    "535": "opcja 5",
    "536": "opcja 6",
    "537": "opcja 7",
    "538": "opcja 8",
    "539": "opcja 9",
    "540": "opcja 10",
    "541": "E-mail wysłany",
    "542": "Wysyłanie wiadomości e-mail nie powiodło się!",
    "543": "Smarowanie okuć",
    "544": "Regulacja",
    "545": "Czyszczenie",
    "546": "Uszczelnianie",
    "547": "Wymiana akcesoriów",
    "548": "Komentarz",
    "549": "Dostawa wszystkich elementów na plac budowy",
    "550": "Lokalizacja elementów w projekcie budowlanym",
    "551": "Wysyłanie protokołu nie powiodło się!",
    "552": "Zapisz informacje w dzienniku",
    "553": "Informacje zapisane",
    "554": "Nie udało się zapisać danych",
    "555": "Informacje o Smart Glass",
    "556": "Odbiór Smart Glass",
    "557": "Numer materiału elektroniki okiennej",
    "558": "Numer seryjny elektroniki okiennej",
    "559": "Zeskanuj kod QR (DMC) z kontrolera głównego lub wprowadź go ręcznie",
    "560": "Smart Window w pełni skonfigurowane i działające",
    "561": "Wszystkie widoczne elementy bez uszkodzeń/usterek",
    "562": "Czy system przełączania został podłączony do 0-10V (0 - 100%)?",
    "563": "Miejsce instalacji w budynku",
    "564": "Rozpocznij skanowanie",
    "565": "Zatrzymaj skanowanie",
    "566": "Aktualnie wybrany element",
    "567": "Równocześnie można wybrać maksymalnie 4 atrybuty.",
    "568": "Pozycja klienta ",
    "569": "Data produkcji",
    "570": "Zmiana Window.ID",
    "571": "Poprzedni Window.ID",
    "572": "Nowy Window.ID",
    "573": "Numer zamówienia nie istnieje lub nowy numer zamówienia jest już używany.",
    "574": "Kod kreskowy",
    "575": "ID pozycji",
    "576": "Rodzaj",
    "577": "ID elementu",
    "578": "Informacja o elemencie",
    "579": "Window.ID",
    "580": "Prosimy o złożenie podpisu w przeznaczonym do tego miejscu.",
    "581": "Gotowe",
    "582": "Podpis",
    "583": "Dodaj jezyk",
    "584": "Język źródłowy",
    "585": "Język docelowy",
    "586": "Utwórz nowy język",
    "587": "Dodaj język",
    "588": "Aktualizuj",
    "589": "Trwa aktualizacja",
    "590": "Dezaktywacja firmy",
    "591": "Aktywacja firmy",
    "592": "Czy na chcesz dezaktywować dostęp?",
    "593": "Czy na pewno chcesz dezaktywować dostęp dla tego użytkownika?",
    "594": "Zarządzanie dostępem",
    "595": "Dezaktywacja użytkownika",
    "596": "Czy na pewno chcesz dezaktywować tego użytkownika",
    "597": "Czy na pewno chcesz aktywować tego użytkownika",
    "598": "ID pozycji",
    "599": "wszystkie",
    "600": "Import/Export",
    "601": "Wybierz plik językowy .csv do importu",
    "602": "Import",
    "603": "Export",
    "604": "Dane",
    "605": "Translator",
    "606": "Kody kreskowe",
    "607": "Wybierz folder dla danych kodów kreskowych.",
    "608": "Window_ID",
    "609": "System profili",
    "610": "Pozycja klienta",
    "611": "kolor",
    "612": "Wielkość elementu",
    "613": "Wymiar oszklenia",
    "614": "Budowa oszklenia",
    "615": "Okucie",
    "616": "Data dopasowania",
    "617": "Data/godzina montażu",
    "618": "Miejsce montażu (dane GPS)",
    "619": "nr",
    "620": "Montaż",
    "621": "Piętro",
    "622": "poz.",
    "623": "Ignorowanie cyfr przed kodem kreskowym",
    "624": "Ignorowanie cyfr po kodzie kreskowym",
    "625": "E-mail - zaproszenie dealerów",
    "626": "Wyślij link z zaproszeniem",
    "627": "Koniec dostępu do testu",
    "628": "Wyślij ponownie link do zaproszenia",
    "629": "Login już istnieje",
    "630": "Użytkownik został aktywowany",
    "631": "Przejdź do strony logowania",
    "632": "Szczegóły użytkownika",
    "633": "Połączone dostępy",
    "634": "Usuń połączony dostęp",
    "635": "Dostęp jest już połączony",
    "636": "Nazwa użytkownika lub hasło są puste",
    "637": "Nie znaleziono użytkownika",
    "638": "Wyślij protokół odbioru",
    "639": "Montaż całkowicie zakończony",
    "640": "opcja 5",
    "641": "opcja 6",
    "642": "opcja 7",
    "643": "opcja 8",
    "644": "opcja 9",
    "645": "opcja 10",
    "646": "Montażysta",
    "647": "Typ",
    "648": "Data",
    "649": "Opis",
    "650": "Konserwacja okucia",
    "651": "Ustawianie",
    "652": "Czyszczenie",
    "653": "Uszczelnianie",
    "654": "Wymiana akcesoriów",
    "655": "Komentarz",
    "656": "Złącze",
    "657": "System profili",
    "658": "Numer",
    "659": "Numer zlecenia",
    "660": "ID zlecenia",
    "661": "wysokość",
    "662": "szerokość",
    "663": "Dane okucia",
    "664": "Szerokość x wysokość oszklenia",
    "665": "Budowa oszklenia",
    "666": "Window.ID",
    "667": "ID elektroniki okiennej",
    "668": "ID oszklenia",
    "669": "Warunek",
    "670": "dodaj/usuń",
    "671": "i/lub",
    "672": "Grupa",
    "673": "Kolumna",
    "674": "Warunek",
    "675": "Nadaj numer klienta",
    "676": "Translator",
    "678": "Kontrola wersji aplikacji",
    "679": "Aktualizacja może potrwać kilka minut",
    "680": "Oczekiwanie na nową wersję",
    "681": "Pojawiła się nowa wersja aplikacji, czy na pewno chcesz ją zaktualizować?",
    "682": "Aplikacja jest aktualna, czy chcesz przywrócić starą wersję?",
    "683": "Przywróć starą wersję",
    "684": "Wstaw nazwę stacji",
    "685": "Wypełniacz znaków numeru zamówienia",
    "686": "Wprowadź numer zamówienia wypełniacz znaków",
    "687": "Maksymalna długość numeru zamówienia",
    "688": "Wprowadź maksymalną długość numeru zamówienia",
    "689": "Ścieżka folderu MDB",
    "690": "Ścieżka do pliku .mdb (używana przez importera podczas skanowania w poszukiwaniu plików .mdb)",
    "691": "Ścieżka folderu XML",
    "692": "Należy wstawić ścieżkę pliku .xml (używaną przez importera podczas skanowania w poszukiwaniu plików .xml)",
    "693": "Ignorowanie początkowych znaków QR-Code",
    "694": "Ignorowanie końcowych znaków kodu QR",
    "695": "Oznaczenie",
    "696": "Długość",
    "697": "Dodatkowa pozycja",
    "698": "Inteligentne okno bez uszkodzeń",
    "699": "Podłączone inteligentne okno",
    "700": "Orientacja okna",
    "701": "Numer elementu pozycji",
    "702": "Skonfigurowane okno inteligentne",
    "703": "Element bez uszkodzeń",
    "704": "Numer materiału",
    "705": "Numer seryjny",
    "706": "Brak dostępnego obrazu",
    "707": "Czysty plac budowy",
    "708": "Przeciągnij i upuść pliki graficzne/pdf tutaj",
    "709": "Przesyłanie plików",
    "710": "Typ pliku",
    "711": "Zamówienie (wszystkie pozycje)",
    "712": "Usunięty",
    "713": "Uwagi dotyczące wydania",
    "714": "Wysokość (mm)",
    "715": "Szerokość (mm)",
    "716": "Dla tej pozycji nie ma panelu",
    "717": "Pozycja",
    "718": "Stacja zbiorcza",
    "719": "Zakres masowy",
    "720": "Window.ID (ostatnie)",
    "721": "Szyba została wymieniona",
    "722": "Naprawiono akcesoria",
    "723": "Skrzydło zostało wymienione",
    "724": "Powierzchnia została naprawiona",
    "725": "Akcesoria zostały naprawione",
    "726": "Naprawa została zakończona",
    "727": "Potwierdź hasło",
    "728": "Błędne hasło",
    "729": "Hasła nie są zgodne",
    "730": "Hasło nie może być puste",
    "731": "Lokalizacja",
    "732": "Oś czasu",
    "733": "Współczynnik U (W/m²K)",
    "734": "Oszklenie specjalne",
    "735": "Brak pozycji",
    "736": "Postęp produkcji",
    "737": "Produkcja zakończona",
    "738": "Postęp instalacji",
    "739": "Instalacja zakończona",
    "740": "Stacja usunięta",
    "741": "Czy na pewno chcesz usunąć tę stację?",
    "742": "Dodaj nową stację",
    "743": "Usuwanie zamówienia",
    "744": "Usuwanie zamówienia",
    "745": "Czy na pewno chcesz usunąć zamówienie?",
    "746": "Dodane przez użytkownika",
    "747": "Użytkownik edytowany",
    "748": "Nie pamiętasz hasła?",
    "749": "Pole Hasło jest wymagane",
    "750": "Pole Nazwa użytkownika jest wymagane",
    "751": "Ta rola użytkownika nie jest autoryzowana do logowania na pulpicie nawigacyjnym",
    "752": "Użytkownik nie jest aktywny",
    "753": "Firma użytkownika nie jest aktywna lub okres testowy dobiegł końca",
    "754": "Nazwa użytkownika lub hasło są nieprawidłowe",
    "755": "Kod pocztowy",
    "756": "Użyj kółka przewijania, aby powiększyć lub pomniejszyć obraz",
    "757": "Reset hasła",
    "758": "Wysłano wiadomość e-mail z prośbą o zresetowanie hasła",
    "759": "Hasło zostało zresetowane",
    "760": "E-mail - Reset hasła",
    "761": "Nr elementu",
    "762": "Numer zamówienia od",
    "763": "Wprowadź numer zamówienia od",
    "764": "Długość numeru zamówienia",
    "765": "Wpisz długość numeru zamówienia",
    "766": "Zakres zamówienia od",
    "767": "Wprowadź zakres zamówienia od",
    "768": "Długość zakresu zamówienia",
    "769": "Długość zakresu zamówienia",
    "770": "Please insert order piece from",
    "772": "Długość zamawianej sztuki",
    "773": "Długość zamawianej sztuki",
    "774": "Numer pozycji od",
    "775": "Wprowadź numer pozycji od",
    "776": "Długość numeru pozycji",
    "777": "Wprowadź długość numeru pozycji",
    "778": "Pozycja od",
    "779": "Wprowadź pozycję od",
    "780": "Długość elementu pozycji",
    "781": "Wprowadź długość elementu pozycji",
    "782": "Poziom bezpieczeństwa",
    "783": "Znak wypełnienia kodu kreskowego",
    "784": "Wprowadź znak wypełnienia kodu kreskowego",
    "785": "Powrót do projektów",
    "786": "(tylko do odczytu)",
    "787": "ograniczony dostęp",
    "788": "dostęp tylko do odczytu",
    "789": "własna strona docelowa",
    "790": "Wstaw ciąg połączenia",
    "791": "Ciąg połączenia DB",
    "792": "Ciąg połączenia DB STD",
    "793": "Ciąg połączenia DB BWG",
    "794": "Połączenie z bazą danych",
    "795": "Czat",
    "796": "Wiadomości",
    "797": "Dokument CE",
    "798": "Dokument zamówienia",
    "799": "Ostatnia naprawa",
    "800": "Odbiór zakończony",
    "801": "Dostępna jest nowa aktualizacja, prosimy o ponowne uruchomienie aplikacji",
    "802": "Łącze zewnętrzne",
    "803": "Prefiks łącza zewnętrznego",
    "804": "Odniesienie do projektu",
    "805": "Interwał zadań",
    "806": "Użytkownicy pulpitu nawigacyjnego",
    "807": "Użytkownicy aplikacji",
    "808": "Ostatnio przesłane dane",
    "809": "minuty",
    "810": "Wersja Matchera",
    "811": "Wersja importera",
    "812": "Wylogowany użytkownik",
    "813": "Użytkownik został pomyślnie wylogowany",
    "814": "Zmieniono kod kreskowy",
    "815": "Zaktualizowano informacje",
    "816": "Wskazówki",
    "817": "Instalacja aplikacji",
    "818": "Zainstaluj aplikację Window.ID, skanując kod QR",
    "819": "dopasowany",
    "820": "niedopasowany",
    "821": "Planista",
    "822": "Liczba dni",
    "823": "Ustawienia przypomnienia o konserwacji.",
    "824": "dni",
    "825": "Next maintenance",
    "826": "Edit image",
    "827": "No filter",
    "828": "Add order",
    "829": "New order",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera"
};

export default pl;